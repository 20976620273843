import React from "react";
import { styled, keyframes } from "@stitches/react";
import { violet, mauve, blackA } from "@radix-ui/colors";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import { showResultsAtom } from "../../atoms/index";
import { sarPolygonAtom } from "../../atoms/index";
import { opticalPolygonAtom } from "../../atoms/index";
import axios from "axios";
import toast from "react-hot-toast";

import {
  AvatarIcon,
  DotFilledIcon,
  CheckIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { BiUserCircle } from "react-icons/bi";

const slideUpAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideRightAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(-2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const slideDownAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateY(-2px)" },
  "100%": { opacity: 1, transform: "translateY(0)" },
});

const slideLeftAndFade = keyframes({
  "0%": { opacity: 0, transform: "translateX(2px)" },
  "100%": { opacity: 1, transform: "translateX(0)" },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: 220,
  backgroundColor: "white",
  borderRadius: 6,
  padding: 5,
  boxShadow:
    "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
  "@media (prefers-reduced-motion: no-preference)": {
    animationDuration: "400ms",
    animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
    willChange: "transform, opacity",
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const itemStyles = {
  all: "unset",
  fontSize: 13,
  lineHeight: 1,
  color: violet.violet11,
  borderRadius: 3,
  display: "flex",
  alignItems: "center",
  height: 25,
  padding: "0 5px",
  position: "relative",
  paddingLeft: 25,
  userSelect: "none",

  "&[data-disabled]": {
    color: mauve.mauve8,
    pointerEvents: "none",
  },

  "&:focus": {
    backgroundColor: violet.violet9,
    color: violet.violet1,
  },
};

const StyledItem = styled(DropdownMenuPrimitive.Item, { ...itemStyles });
const StyledCheckboxItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  ...itemStyles,
});
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, {
  ...itemStyles,
});
const StyledTriggerItem = styled(DropdownMenuPrimitive.TriggerItem, {
  '&[data-state="open"]': {
    backgroundColor: violet.violet4,
    color: violet.violet11,
  },
  ...itemStyles,
});

const StyledLabel = styled(DropdownMenuPrimitive.Label, {
  paddingLeft: 25,
  fontSize: 12,
  lineHeight: "25px",
  color: mauve.mauve11,
});

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: violet.violet6,
  margin: 5,
});

const StyledItemIndicator = styled(DropdownMenuPrimitive.ItemIndicator, {
  position: "absolute",
  left: 0,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = StyledContent;
export const DropdownMenuItem = StyledItem;
export const DropdownMenuCheckboxItem = StyledCheckboxItem;
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;
export const DropdownMenuRadioItem = StyledRadioItem;
export const DropdownMenuItemIndicator = StyledItemIndicator;
export const DropdownMenuTriggerItem = StyledTriggerItem;
export const DropdownMenuLabel = StyledLabel;
export const DropdownMenuSeparator = StyledSeparator;

// Your app...
const Box = styled("div", {});

const RightSlot = styled("div", {
  marginLeft: "auto",
  paddingLeft: 20,
  color: mauve.mauve11,
  ":focus > &": { color: "white" },
  "[data-disabled] &": { color: mauve.mauve8 },
});

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 35,
  width: 35,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: violet.violet11,
  backgroundColor: "white",
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
  "&:hover": { backgroundColor: violet.violet3 },
  "&:focus": { boxShadow: `0 0 0 2px black` },
});

const signOut = async (
  setUser,
  setIsSessionValid,
  setSelected,
  setShowResult,
  setDetails,
  setSar,
  setOptical,
  navigate
) => {
  const signingOut = toast.loading("Signing out...");

  try {
    const req = await axios.get(
      `${process.env.REACT_APP_AUTH_SERVER}/auth/v1/sign-out`,
      {
        withCredentials: true,
      }
    );
    if (req?.data?.status === "success") {
      console.log(req, "req signout");
      toast.success("Signed out successfully", {
        id: signingOut,
      });
      setIsSessionValid(false);
      setUser(null);
      navigate("/sign-in");
    } else {
      throw new Error("Error signing out");
    }
  } catch (error) {
    console.log(error);
    toast.error("Error signing out", {
      id: signingOut,
    });
  }
  setSelected(false);
  setShowResult(false);
  setDetails(false);
  setSar(false);
  setOptical(false);
};

export const DropdownMenuDemo = () => {
  const [cookies, removeCookie] = useCookies();
  let navigate = useNavigate();
  const [isSessionValid, setIsSessionValid] = useState();
  const [user, setUser] = useAtom(userAtom);
  const [mode, setMode] = React.useState("dark");
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [showResult, setShowResult] = useAtom(showResultsAtom);
  const [selected, setSelected] = useAtom(areaSectedAtom);
  const [sar, setSar] = useAtom(sarPolygonAtom);
  const [optical, setOptical] = useAtom(opticalPolygonAtom);

  return (
    <Box>
      <DropdownMenu sideOffset={5}>
        <DropdownMenuTrigger asChild>
          <IconButton aria-label="Customise options">
            <BiUserCircle className="text-xl cursor-pointer" />
          </IconButton>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="mt-2">
          <DropdownMenuItem onSelect={() => navigate("/orders")}>
            Orders
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => navigate("/api-key")}>
            API key
          </DropdownMenuItem>
          <DropdownMenuItem disabled>
            Add Credits <RightSlot>⇧+⌘+N</RightSlot>
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => navigate("/admin")}>
            Admin Panel
          </DropdownMenuItem>
          {/* <DropdownMenuItem>
            Settings <RightSlot>⌘+N</RightSlot>
          </DropdownMenuItem> */}
          {/* <DropdownMenuSeparator />
          <DropdownMenuLabel>Mode</DropdownMenuLabel>
          <DropdownMenuRadioGroup
            value={mode}
            onValueChange={() => setMode(mode === "dark" ? "light" : "dark")}
          >
            <DropdownMenuRadioItem value="light">
              <DropdownMenuItemIndicator>
                <DotFilledIcon />
              </DropdownMenuItemIndicator>
              Light
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="dark">
              <DropdownMenuItemIndicator>
                <DotFilledIcon />
              </DropdownMenuItemIndicator>
              Dark
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup> */}
          <DropdownMenuSeparator />
          <a href={`mailto:support@galaxeye.space`}>
            <DropdownMenuItem>Contact Us</DropdownMenuItem>
          </a>
          <DropdownMenuItem
            onClick={() =>
              signOut(
                setUser,
                setIsSessionValid,

                setSelected,
                setShowResult,
                setDetails,
                setSar,
                setOptical,
                navigate
              )
            }
          >
            Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
};

export default DropdownMenuDemo;

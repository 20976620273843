import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AvatarLib from "../../components/avatar";
import DropdownMenuDemo from "../userMenu";
import { useAtom } from "jotai";
import { useMap } from "react-leaflet";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { DotIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { GoPackage } from "react-icons/go";
import { userAtom } from "../../atoms/index";
import { creditsAtom } from "../../atoms/index";
import { useNavigate } from "react-router-dom";
import { clearLayersAtom } from "../../atoms/index";
import { opticalPolygonAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import { showResultsAtom } from "../../atoms/index";
import { sarPolygonAtom } from "../../atoms/index";
import { nameSidebarAtom } from "../../atoms/index";
import { currentSlideAtom } from "../../atoms/index";
import { areaAtom } from "../../atoms/index";

const Nav = () => {
  const breadcrumbs = useBreadcrumbs();
  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);
  const [credits, setCredits] = useAtom(creditsAtom);
  const [isTrue, setIsTrue] = useAtom(clearLayersAtom);
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [showResult, setShowResult] = useAtom(showResultsAtom);
  const [sar, setSar] = useAtom(sarPolygonAtom);
  const [optical, setOptical] = useAtom(opticalPolygonAtom);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);
  const [selected, setSelected] = useAtom(nameSidebarAtom);
  const [area, setArea] = useAtom(areaAtom);
  const [navigateTo, setNavigateTo] = useState(false);

  function navigateToOrders(
    setDetails,
    setSar,
    setOptical,
    setShowResult,
    setIsTrue,
    setSelected,
    setCurrentSlide,
    setArea,
    navigate
  ) {
    setDetails({});
    setSar([]);
    setOptical([]);
    setShowResult(false);
    setIsTrue(true);
    setSelected(false);
    setCurrentSlide("item-1");
    setArea();
    document.getElementsByClassName(
      "leaflet-draw-draw-rectangle"
    )[0].style.display = "block";
    navigate("/orders");
  }

  return (
    <div className="w-screen h-1/10 border-b border-white absolute top-0 left-0 grid grid-cols-11 justify-content items-center bg-nav">
      <div className="w-28 h-14 col-span-2 flex justify-self-center gap-5 justify-center items-center">
        <img src="/logo/main-hero.png" alt="logo" className="w-full h-full" />
        <div className="bg-my_purple w-16 h-7 rounded-md px-3">BETA</div>
      </div>

      <div className="flex text-white col-span-4 items-center justify-start gap-3">
        {breadcrumbs.map(({ breadcrumb }, index, array) => (
          <div className="flex gap-3 items-center" key={index}>
            {console.log(breadcrumb, "breadcrumb")}
            <Link
              to={breadcrumb.key}
              className="relative after:top-6 after:block after:h-1 after:absolute after:bg-white after:transition-all after:duration-200 after:ease-in-out after:w-0 hover:after:w-full hover:after:left-0"
            >
              {breadcrumb}
            </Link>
            {index !== array.length - 1 && <ChevronRightIcon />}
          </div>
        ))}
      </div>

      <div
        className={`${
          user?.invoice ? "col-start-10" : "col-start-9"
        } col-span-3 flex gap-8 items-center justify-center`}
      >
        {user?.invoice ? null : (
          <div className="flex justify-center items-center gap-1 text-pipeline_name">
            <span>{parseInt(credits)}</span>
            <span>Credits</span>
          </div>
        )}
        {/* <div
          className="flex gap-5 bg-white rounded-md text-violet-600 p-2 items-center justify-center cursor-pointer"
          onClick={() => navigate("/sample-dataset")}
        >
          <GoPackage className=" text-sm cursor-pointer" />
          <div className="flex gap-1">
            <span>Sample</span>
            <span>Dataset</span>
          </div>
        </div> */}
        <div
          className="flex gap-5 bg-white rounded-md text-violet-600 p-2 items-center justify-center cursor-pointer"
          onClick={() =>
            navigateToOrders(
              setDetails,
              setSar,
              setOptical,
              setShowResult,
              setIsTrue,
              setSelected,
              setCurrentSlide,
              setArea,
              navigate
            )
          }
        >
          <GoPackage className="text-xl cursor-pointer" />
          <span>Orders</span>
        </div>
        <DropdownMenuDemo />
      </div>
    </div>
  );
};

export default Nav;

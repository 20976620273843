import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { AiFillCopy } from "react-icons/ai";
import { BiShow } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import axios from "axios";
import toast from "react-hot-toast";

// const Json = JSON.stringify(currentOrder.data);
// console.log(Json.replaceAll("\\", ""));

const ApiKey = () => {
  const [user, setUser] = useAtom(userAtom);
  const [apiKey, setAPIKey] = useState(user.secret_api_key);
  const [showKey, setShowKey] = useState(true);

  const copyClick = () => {
    toast.success("API key copied successfully", {
      autoClose: 5000,
      position: "bottom-right",
    });
  };

  const generateNewKey = async () => {
    const toastId = toast.loading("Generating...", {
      position: "bottom-right",
    });

    //make an axios post request to generate a new api key in try catch block

    try {
      const req = await axios.post(
        "https://api.galaxeye.space/api/v1/generate_api_key",
        {
          userID: user.id,
        },
        {
          withCredentials: true,
        }
      );
      const res = await req;
      console.log(res, "res");
      setAPIKey(res.data.data.secret_api_key);
      setUser({ ...user, secret_api_key: res.data.data.secret_api_key });
      toast.success("API key generated successfully", {
        autoClose: 5000,
        position: "bottom-right",
        id: toastId,
      });
    } catch (err) {
      console.log(err);
      toast.error("Error generating API key", {
        autoClose: 5000,
        position: "bottom-right",
        id: toastId,
      });
    }
  };
  console.log(user);
  return (
    <div className="w-full h-full grid grid-rows-7 gap-10 items-center justify-items-center">
      <div className="row-span-1 w-full">
        <h1 className="text-4xl text-button_green font-pipeline_name pl-10">
          API Key
        </h1>
      </div>
      <div className="row-span-4 w-full h-full grid grid-cols-2 gap-5 p-10">
        <div className="p-5 shadow-2xl shadow-black rounded-2xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 grid grid-rows-4 gap-5">
          <div>
            <h3 className="text-xl">
              Set the below shown key and value pair in your header while making
              an API call
            </h3>
          </div>
          <div className="grid grid-cols-8 gap-1 items-center">
            <div className="col-span-1 uppercase text-xl font-bold">Key</div>
            <div className="col-span-1  text-center">:</div>
            <div className="col-span-6">
              <input
                className="bg-yellow-500 shadow-inner rounded-lg outline-none border-none px-5 py-2 w-3/4 flex justify-self-start"
                type="text"
                placeholder="api_key"
                value="authorization"
                readOnly={true}
              />
            </div>
          </div>
          <div className="grid grid-cols-8 gap-1 items-center">
            <div className="col-span-1 uppercase text-xl font-bold">Value</div>
            <div className="col-span-1 text-center">:</div>
            <div className="col-span-5 relative flex">
              <input
                className="bg-yellow-500 shadow-inner rounded-lg outline-none border-none pl-5 pr-14 py-2 w-full flex justify-self-start overflow-x-auto"
                type={showKey ? "password" : "text"}
                placeholder="Press below button to generate an api"
                value={apiKey}
                readOnly={true}
              />
              <button
                className="absolute bg-white p-2 right-0 top-0 rounded-lg"
                onClick={() => setShowKey(!showKey)}
              >
                {showKey ? (
                  <BiShow className="text-black text-2xl cursor-pointer " />
                ) : (
                  <BiHide className="text-black text-2xl cursor-pointer " />
                )}
              </button>
            </div>
            <div
              className="w-full text-center pl-3 h-full items-center justify-center flex"
              onClick={() => {
                navigator.clipboard.writeText(user.secret_api_key);
              }}
            >
              <AiFillCopy
                className="text-white text-2xl cursor-pointer "
                onClick={copyClick}
              />
            </div>
          </div>
          <div className=" flex mx-auto">
            <button className="pushable" onClick={generateNewKey}>
              <span className="shadow"></span>
              <span className="edge"></span>
              <span className="front">
                {user.secret_api_key === ""
                  ? "Generate a key"
                  : "Generate a new key"}
              </span>
            </button>
          </div>
        </div>
        <div className="p-5 shadow-2xl shadow-black rounded-2xl bg-gradient-to-r from-gray-500 via-Amber-500 to-gray-800 flex flex-col text-white gap-5 text-xl items-center justify-evenly">
          <div>Key Security</div>
          <div className="text-yellow-600">
            ⚠️ Treat your API key like password.
          </div>
          <div>
            Anyone who has your REST API key will be able to send notifications
            from your app. Do not expose the REST API key in your application
            code. Do not share it on GitHub or anywhere else online.
          </div>
        </div>
      </div>
      <div className="row-span-2 w-full p-10 text-center">
        <a
          href="https://api.galaxeye.space/documentation"
          target="_blank"
          rel="noreferrer"
        >
          <button className="w-1/2 text-4xl font-pacifico sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border ml-5  border-white text-white   hover:bg-white hover:text-black shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3 hover:italic">
            Docmentation
          </button>
        </a>
      </div>
    </div>
  );
};

export default ApiKey;

import { GiCircle } from "react-icons/gi";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useState } from "react";

const StatusBar = ({ progress, status }) => {
  return (
    <div className="flex flex-col px-16 w-full">
      <ProgressBar
        percent={progress}
        filledBackground={`${
          status === "Error" || status === "Cloud cover percentage exceeded"
            ? "linear-gradient(to right, #EB5252, #C60100)"
            : "linear-gradient(to right, #99c3ff, #3387FF)"
        }`}
        unfilledBackground="white"
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <>
              <div className="absolute bottom-8 text-white text-base uppercase">
                {status === "Error" ||
                status === "Cloud cover percentage exceeded"
                  ? ""
                  : "Placed"}
              </div>
              <div
                className={`${
                  status === "Error" ||
                  status === "Cloud cover percentage exceeded"
                    ? "bg-red-500"
                    : accomplished
                    ? "bg-blue-500"
                    : "bg-white"
                } w-5 h-5 rounded-full flex items-center justify-center`}
              />
            </>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <>
              <div
                className={`  ${
                  status === "Error" ||
                  status === "Cloud cover percentage exceeded"
                    ? "w-screen text-center"
                    : ""
                } absolute bottom-8 text-white text-base uppercase`}
              >
                {status === "Error" ||
                status === "Cloud cover percentage exceeded" ? (
                  <span className="w-80">{status}</span>
                ) : (
                  "Processing"
                )}
              </div>
              <div
                className={`${
                  status === "Error" ||
                  status === "Cloud cover percentage exceeded"
                    ? "bg-red-500"
                    : accomplished
                    ? "bg-blue-500"
                    : "bg-white"
                } w-5 h-5 rounded-full flex items-center justify-center`}
              />
            </>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <>
              <div className="absolute bottom-8 text-white text-base uppercase">
                {status === "Error" ||
                status === "Cloud cover percentage exceeded"
                  ? ""
                  : "Processed"}
              </div>
              <div
                className={`${
                  status === "Error" ||
                  status === "Cloud cover percentage exceeded"
                    ? "bg-red-500"
                    : accomplished
                    ? "bg-blue-500"
                    : "bg-white"
                } w-5 h-5 rounded-full flex items-center justify-center`}
              />
            </>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
};

export default StatusBar;

import { useState } from "react";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { nameSidebarAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

dayjs().format();

const PipelineName = Yup.object().shape({
  name: Yup.string()
    .max(40, "Must be 40 characters or less")
    .required("Please enter a project name"),
});

const NameSidebar = ({ setCurrentSlide }) => {
  const [details, setDetails] = useAtom(orderDetailsAtom);

  return (
    <div>
      <Formik
        initialValues={{
          name: details.pipeline_name || "",
        }}
        validationSchema={PipelineName}
        onSubmit={(values) => {
          // same shape as initial values
          console.log(values);
          setCurrentSlide("item-2");
          setDetails({ ...details, pipeline_name: values.name });
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="w-full h-full grid grid-rows-3 items-center gap-10">
            <div className="w-full mb-6 md:mb-0 row-span-2 flex flex-col items-center">
              <div className="text-center">
                <h2 className="text-xl text-pipeline_name uppercase pb-6">
                  Project Name
                </h2>
              </div>
              <Field
                name="name"
                className={`appearance-none block text-2xl text-black w-9/10 h-10 mx-auto rounded-sm py-5 bg-pipeline_name outline-none text-center ${
                  (errors.name && touched.name) || errors
                    ? "border border-red-500 focus:outline-none"
                    : "focus:outline-blue-500"
                }`}
                id="pipeline_name"
                type="pipeline_name"
                autoComplete="off"
                placeholder="Enter here"
              />
              <div className="text-red-500 text-xs italic">
                {errors.name && touched.name ? <div>{errors.name}</div> : null}
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                className="sm:py-2 sm:px-4 px-3 w-1/3 py-2 rounded-sm order-2 md:order-3 border ml-5 border-white text-black  bg-white  shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                type="submit"
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NameSidebar;

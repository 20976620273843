import { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { areaSectedAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import { nameSidebarAtom } from "../../atoms/index";
import { isNameHiddenAtom } from "../../atoms/index";
import { showResultsAtom } from "../../atoms/index";
import { currentSlideAtom } from "../../atoms/index";
import { sarPolygonAtom } from "../../atoms/index";
import { clearLayersAtom } from "../../atoms/index";
import { showToolBarAtom } from "../../atoms/index";
import { areaAtom } from "../../atoms/index";
import { userAtom } from "../../atoms/index";
import { creditsAtom } from "../../atoms/index";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BiHide } from "react-icons/bi";
import ReactSwipeButton from "react-swipe-button";

import { opticalPolygonAtom } from "../../atoms/index";
import toast from "react-hot-toast";
import axios from "axios";
import dayjs from "dayjs";
dayjs().format();

const onCheckout = async (
  details,
  user,
  navigate,
  setShowResult,
  setDetails,
  setSar,
  setOptical,
  setCurrentSlide,
  setAccordion,
  setShow,
  setArea,
  setDateRange,
  area,
  setCredits
) => {
  console.log("details", details);
  const orderToast = toast.loading("Processing...");
  console.log(details, "show res");
  setShowResult(false);
  try {
    const req = await axios({
      method: "post",
      url: `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/place_an_order`,

      data: {
        details: details,
        userEmail: user.email,
        pipelineName: details.pipeline_name,
        organizationId: user.organization_id,
        creditsCharged: area,
        invoice: user?.invoice,
      },
      headers: { "Content-Type": "application/json", withCredentials: true },
      withCredentials: true,
    });

    const { orderID } = req.data.data;
    console.log(req.data.data, "dataaaa");
    setDetails({});
    setSar([]);
    setOptical([]);
    setShowResult(false);
    setAccordion(false);
    setShow(true);
    setArea();
    setCurrentSlide("item-1");

    if (!user?.invoice) {
      setCredits(user.invoice.credits);
    }

    toast.success("Order placed successfully", {
      autoClose: 1000,
      closeOnClick: true,
      pauseOnHover: true,
      id: orderToast,
    });
    navigate(`/order-confirmed/${orderID}`, { replace: true });
  } catch (e) {
    console.log("error");
    console.log(e.response);
    toast.error(e.response.data.message, {
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      id: orderToast,
    });
    setShowResult(true);
    setShow(true);
    setCurrentSlide("item-2");
    setDateRange(true);
  }
};

const showData = (i, el, viewMode) => {
  return (
    <div
      key={i}
      className="grid grid-cols-2 items-center border-b-2 border-pipeline_name py-5 w-full justify-start"
    >
      <div className="grid grid-rows-3 border-r-2 border-pipeline_name gap-4 pr-4">
        <div className="flex flex-col">
          <div className=" text-gray-500 uppercase">Platform Name</div>
          <div className="text-base text-pipeline_name">{el.platformname}</div>
        </div>
        <div className="flex flex-col">
          <div className=" text-gray-500 uppercase flex justify-items-start">
            {viewMode === "sar" ? "Polarization Mode" : "Cloud Cover"}
          </div>
          <div className="text-base text-pipeline_name">
            {viewMode === "sar"
              ? el.polarisationmode
              : parseInt(el.cloudcoverpercentage).toFixed(2)}
          </div>
        </div>

        <div className="flex flex-col">
          <div className=" text-gray-500 uppercase">Date</div>
          <div className="text-base text-pipeline_name">
            {dayjs(el.date).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>

      <div className="grid grid-rows-3 gap-4 pl-5 justify-evenly">
        <div className="flex flex-col">
          <div className=" text-gray-500 uppercase">Size</div>
          <div className="text-base text-pipeline_name">{el.size}</div>
        </div>
        <div className="flex flex-col">
          <div className=" text-gray-500 uppercase">Orbit Direction</div>
          <div className="text-base text-pipeline_name">
            {el.orbitdirection}
          </div>
        </div>

        <div className="flex flex-col">
          <div className=" text-gray-500 uppercase">Product Type</div>
          <div className="text-base text-pipeline_name">{el.producttype}</div>
        </div>
        {/* <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">ID</div>
        <div className="text-base text-pipeline_name">{el.id}</div>
      </div> */}
      </div>
    </div>
  );
};

const goBack = (
  setAccordion,
  setShowResult,
  setDetails,
  setSar,
  setOptical,
  setShow,
  details
) => {
  setShowResult(false);
  setAccordion(true);
  setSar([]);
  setOptical([]);
  setShow(true);
  let tempDetails = Object.assign({}, details);
  delete tempDetails.sar;
  delete tempDetails.optical;
  setDetails(tempDetails);
};

const hideresults = (setIsHide) => {
  setIsHide(false);
};

const ShowResults = () => {
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [showResult, setShowResult] = useAtom(showResultsAtom);
  const [sar, setSar] = useAtom(sarPolygonAtom);
  const [accordian, setAccordion] = useAtom(nameSidebarAtom);
  const [optical, setOptical] = useAtom(opticalPolygonAtom);
  const [user, setUser] = useAtom(userAtom);
  const [viewMode, setViewMode] = useState();
  const [loading, setLoading] = useState(false);
  const [isHide, setIsHide] = useAtom(isNameHiddenAtom);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);
  const [show, setShow] = useAtom(showToolBarAtom);
  const [area, setArea] = useAtom(areaAtom);
  const [dateRange, setDateRange] = useAtom(areaSectedAtom);
  const [credits, setCredits] = useAtom(creditsAtom);

  const navigate = useNavigate();
  useEffect(() => {
    const resSar = details?.sar?.map((ele, i) => {
      let switchoru = ele.footprint.map((el) => {
        let test = el.toString().split(",").reverse();
        test.concat();
        return test.map((el) => {
          return parseFloat(el);
        });
      });
      return switchoru;
    });
    const resOptical = details?.optical?.map((ele, i) => {
      let switchoru = ele.footprint.map((el) => {
        let test = el.toString().split(",").reverse();
        test.concat();
        return test.map((el) => {
          return parseFloat(el);
        });
      });
      return switchoru;
    });
    setSar(resSar);
    setOptical(resOptical);
    if (details?.optical || details?.sar) {
      setShowResult(true);
    } else {
      setShowResult(false);
    }
  }, [details, setOptical, setSar, setShowResult]);

  return (
    <div
      className={`${
        showResult && details?.sar && details?.optical && isHide
          ? "translate-x-4"
          : "-translate-x-full"
      } w-3/10 bg-nav h-3/4 z-20 flex absolute top-36 rounded-lg shadow-lg p-2 flex-col gap-5 justify-evenly items-center ease-in-out duration-500`}
    >
      <div
        className="absolute top-2 left-3 text-white flex items-center"
        onClick={() =>
          goBack(
            setAccordion,
            setShowResult,
            setDetails,
            setSar,
            setOptical,
            setShow,
            details
          )
        }
      >
        <div className="flex gap-2 items-center">
          <MdOutlineKeyboardBackspace className="text-4xl cursor-pointer" />
          {/* <span className="pl-3 cursor-pointer">Go back</span> */}
        </div>
      </div>
      <div
        className="absolute top-2 right-3 text-white flex items-center"
        onClick={() => hideresults(setIsHide)}
      >
        <div className="flex gap-2 items-center">
          <BiHide className="text-3xl cursor-pointer" />
        </div>
      </div>
      <div className="border-b-2 border-pipeline_name mt-6">
        <h2 className="text-xl text-pipeline_name uppercase">
          Dataset Specifications
        </h2>
      </div>

      <div className="bg-pipeline_name rounded-full w-1/2 h-12 flex items-center justify-between p-1">
        <button
          className={` ${
            viewMode === "sar" ? "text-white" : "text-switch_alternate"
          } text-lg rounded-full ${
            viewMode === "sar" ? "bg-switch_alternate" : "bg-pipeline_name"
          } w-full h-full `}
          onClick={() => setViewMode("sar")}
        >
          SAR
        </button>
        <button
          className={` ${
            viewMode === "sar" ? "text-switch_alternate" : "text-white"
          } text-lg rounded-full ${
            viewMode === "sar" ? "bg-pipeline_name" : "bg-switch_alternate"
          } w-full h-full `}
          onClick={() => setViewMode("optical")}
        >
          Optical
        </button>
      </div>

      <div className="overflow-y-auto w-full h-2/5 p-4">
        {viewMode === "sar" && sar?.length > 0
          ? details?.sar?.map((ele, i) => showData(i, ele, viewMode))
          : details?.optical?.map((ele, i) => showData(i, ele, viewMode))}
      </div>
      <div className="flex justify-around w-full items-center justify-items-center">
        {/* <button
          className="sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border ml-5 border-white bg-white  shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
          onClick={() =>
            onReset(setOptical, setSar, setShowResult, setDetails, setIsTrue)
          }
        >
          Reset
        </button> */}

        <div className="text-my_purple text-xl">{`Total: ${area} Credits`}</div>
        {/* <ReactSwipeButton
          text="SWIPE TO CHECKOUT"
          color="#f00"
          onSuccess={() =>
            onCheckout(
              details,
              user,
              navigate,
              setShowResult,
              setDetails,
              setSar,
              setOptical,
              setCurrentSlide,
              setAccordion,
              setShow,
              setArea,
              setDateRange
            )
          }
        /> */}
        {(!user?.credits || parseInt(user?.credits) < area) &&
        !user?.invoice ? (
          <a href={`mailto:support@galaxeye.space?subject=get more credits`}>
            <button
              className={`${
                loading ? "cursor-wait" : ""
              } sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border ml-5 border-white bg-white text-sm shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3`}
            >
              Contact support for more credits
            </button>
          </a>
        ) : (
          <button
            className={`${
              loading ? "cursor-wait" : ""
            } sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border ml-5 border-white bg-white  shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3`}
            onClick={() =>
              onCheckout(
                details,
                user,
                navigate,
                setShowResult,
                setDetails,
                setSar,
                setOptical,
                setCurrentSlide,
                setAccordion,
                setShow,
                setArea,
                setDateRange,
                area,
                setCredits
              )
            }
          >
            Checkout
          </button>
        )}
      </div>
    </div>
  );
};

export default ShowResults;

import { GeoSearchControl, MapBoxProvider } from "leaflet-geosearch";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import * as L from "leaflet";
import { drawnItems } from "leaflet-draw";

const SearchField = ({ apiKey }) => {
  const provider = new MapBoxProvider({
    params: {
      access_token: apiKey,
      placeholder: "Search region of intrest",
    },
  });

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    notFoundMessage: "Sorry, that address could not be found.",
    provider: provider,
    style: "bar",
    showPopUp: false,
    showMarker: false,
    autoClose: true,
  });
  const yourEventHandler = (e) => {
    console.log(e, "geosearch");
  };
  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);
    map.on("geosearch/showlocation", yourEventHandler);
    document.querySelector(".glass").placeholder = "Search Region of Interest";
    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

export default SearchField;

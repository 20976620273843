import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  FeatureGroup,
  Polygon,
  ZoomControl,
  LayersControl,
} from "react-leaflet";
import { useState, useEffect, useRef } from "react";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import * as turf from "@turf/turf";
import toast, { Toaster } from "react-hot-toast";
import { useAtom } from "jotai";
import { mapModeAtom } from "../../atoms/index";
import { nameSidebarAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import SearchField from "./geoCoder";
import { showResultsAtom } from "../../atoms/index";
import { sarPolygonAtom } from "../../atoms/index";
import { opticalPolygonAtom } from "../../atoms/index";
import { seeSarAtom } from "../../atoms/index";
import { seeOpticalAtom } from "../../atoms/index";
import { areaAtom } from "../../atoms/index";

import { useMap } from "react-leaflet";
// import * as L from "leaflet";
import ClearButton from "./clearButtton";

const Map = () => {
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  const [selected, setSelected] = useAtom(areaSectedAtom);
  const [sideBarSelected, setSideBarSelected] = useAtom(nameSidebarAtom);
  const [seeSar, setSeeSar] = useAtom(seeSarAtom);
  const [seeOptical, setSeeOptical] = useAtom(seeOpticalAtom);
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [area, setArea] = useAtom(areaAtom);
  const [showResult, setShowResult] = useAtom(showResultsAtom);
  const [sar, setSar] = useAtom(sarPolygonAtom);
  const [optical, setOptical] = useAtom(opticalPolygonAtom);
  const ref = useRef(null);
  const [centerForMap, setCenterForMap] = useState();
  // const [area, setArea] = useState();

  const normal =
    "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA";
  const satellite =
    "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA";

  useEffect(() => {
    if (ref.current) {
      ref.current.setUrl(mapMode === "default" ? normal : satellite);
    }
    // L.drawLocal.draw.handlers.rectangle.tooltip.start = "Click and drag to draw a rectangle";
  }, [mapMode]);
  // const [btn, setBtn] = useAtom(showNext);
  // const [latlng, setLatlng] = useAtom(latlngAtom);

  const onCreate = (e, setCenterForMap, setArea) => {
    console.log(e);
    const coordinates = e.layer._latlngs[0];
    const newArray = coordinates.map((ele) => {
      return Object.values(ele);
    });

    const aoiArray = [...newArray];

    // const aoiArray = [
    //   [30.325012207031246, 50.38050249104245],
    //   [30.325012207031246, 50.583236614805884],
    //   [30.84136962890625, 50.583236614805884],
    //   [30.84136962890625, 50.38050249104245],
    // ];

    console.log(newArray, "newArray");

    let switchoru = newArray.map((el) => {
      let test = el.toString().split(",").reverse();
      test.concat();
      return test.map((el) => {
        return parseFloat(el);
      });
    });

    if (switchoru.length === 4) {
      let temp = switchoru[1];
      switchoru[1] = switchoru[3];
      switchoru[3] = temp;
    }
    let at0 = switchoru[0];
    switchoru.push(at0);

    const passedAOI = [[...switchoru]];

    console.log(
      "🚀 ~ file: index.js ~ line 80 ~ onCreate ~ passedAOI",
      passedAOI
    );

    const aoisci = turf.polygon(passedAOI, {
      fill: "#00f",
      "fill-opacity": 0.1,
    });

    const center = turf.center(aoisci);
    console.log(center.geometry.coordinates);
    setCenterForMap(center.geometry.coordinates);

    const a = turf.area(aoisci);

    const tosqkm = (a / 1000000).toFixed(2);

    console.log(tosqkm, "area");
    setArea(tosqkm);

    document.getElementsByClassName(
      "leaflet-draw-draw-rectangle"
    )[0].style.display = "none";
    if (tosqkm > 60000) {
      setDetails({
        ...details,
        aoi: aoiArray,
        area: tosqkm,
        passedAOI: switchoru,
      });
      return toast.error("Area is too large, please select a smaller area");
    } else if (tosqkm < 0.1) {
      setDetails({
        ...details,
        aoi: aoiArray,
        area: tosqkm,
        passedAOI: switchoru,
      });
      return toast.error("Area is too small, please select a larger area");
    }

    setDetails({
      ...details,
      aoi: aoiArray,
      area: tosqkm,
      passedAOI: switchoru,
    });

    setShowResult(false);
    setSideBarSelected(true);
  };

  const onDeleted = (e) => {
    console.log(e);
    setSideBarSelected(false);
    setShowResult(false);
    document.getElementsByClassName(
      "leaflet-draw-draw-rectangle"
    )[0].style.display = "block";
    setDetails({});
    setSar([]);
    setOptical([]);
  };

  const onEdit = (e) => {
    const tillLayers = e.layers._layers;
    const coordinates =
      e.layers._layers[Object.keys(tillLayers)[0]]._latlngs[0];
    console.log(coordinates);
    const newArray = coordinates.map((ele) => {
      return Object.values(ele);
    });

    const aoiArray = [...newArray];

    console.log(newArray, "newArray");

    const switchoru = newArray.map((el) => {
      let test = el.toString().split(",").reverse();
      test.concat();
      return test.map((el) => {
        return parseFloat(el);
      });
    });

    let switchoru2 = switchoru.map((el, i) => {
      if (el[i]?.length > 2) {
        return el.splice(i, 1);
      }
      return null;
    });

    console.log(switchoru2, "switchoru2");

    if (switchoru.length === 4) {
      let temp = switchoru[1];
      switchoru[1] = switchoru[3];
      switchoru[3] = temp;
    }
    let at0 = switchoru[0];
    switchoru.push(at0);

    const passedAOI = [[...switchoru]];

    console.log(
      "🚀 ~ file: index.js ~ line 80 ~ onCreate ~ passedAOI",
      passedAOI
    );

    const aoisci = turf.polygon(passedAOI, {
      fill: "#00f",
      "fill-opacity": 0.1,
    });

    const center = turf.center(aoisci);
    console.log(center.geometry.coordinates);
    setCenterForMap(center.geometry.coordinates);

    const a = turf.area(aoisci);

    const tosqkm = (a / 1000000).toFixed(2);

    console.log(tosqkm, "area");
    setArea(tosqkm);

    document.getElementsByClassName(
      "leaflet-draw-draw-rectangle"
    )[0].style.display = "none";
    if (tosqkm > 40000) {
      setDetails({
        ...details,
        aoi: aoiArray,
        area: tosqkm,
        passedAOI: switchoru,
      });
      return toast.error("Area is too large, please select a smaller area");
    }

    setDetails({
      ...details,
      aoi: aoiArray,
      area: tosqkm,
      passedAOI: switchoru,
    });

    setShowResult(false);
    setSideBarSelected(true);
  };

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  return (
    <>
      <MapContainer
        center={[24.313522079016042, 55.1254531966024]}
        zoom={4}
        scrollWheelZoom={true}
        minZoom="3"
        className={`z-10 h-full w-full relative`}
      >
        {centerForMap ? (
          <ChangeView
            center={[centerForMap[1], centerForMap[0]]}
            zoom={area < 500 ? 10 : area > 500 && area < 2500 ? 8 : 7}
          />
        ) : null}
        <FeatureGroup>
          <EditControl
            position="bottomright"
            onCreated={(e) => onCreate(e, setCenterForMap, setArea)}
            onEdited={(e) => onEdit(e)}
            onDeleted={(e) => onDeleted(e)}
            draw={{
              marker: false,
              polyline: false,
              circle: false,
              circlemarker: false,
              polygon: {
                allowIntersection: false,
                shapeOptions: { color: "blue" },
                edit: true,
                showLength: true,
                metric: ["km", "m"],
                feet: false,
                showArea: true,
              },
              rectangle: {
                allowIntersection: false,
                shapeOptions: { color: "blue" },
                edit: true,
                showLength: true,
                metric: ["km", "m"],
                feet: false,
                showArea: true,
              },
            }}
          />
        </FeatureGroup>
        <TileLayer ref={ref} url={mapMode === "default" ? normal : satellite} />
        {sar?.length > 0 && (showResult || setSelected) && seeSar
          ? sar.map((ele, i) => {
              return <Polygon positions={ele} color="red" key={i} />;
            })
          : null}
        {optical?.length > 0 && (showResult || setSelected) && seeOptical
          ? optical.map((ele, i) => {
              return <Polygon positions={ele} color="green" key={i} />;
            })
          : null}
        {/* <Polygon
          positions={[
            [20.651559586927647, 80.0784735131836],
            [20.651559586927647, 80.49666351318359],
            [20.85327751618467, 80.50764083862305],
            [20.842896187484477, 80.0784735131836],
            [20.651559586927647, 80.0784735131836],
          ]}
          color="blue"
        /> */}
        <ClearButton />
        <SearchField apiKey="pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA" />
      </MapContainer>
    </>
  );
};

export default Map;

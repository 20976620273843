import { atom } from "jotai";

export const userAtom = atom({});

export const mapModeAtom = atom("default");

export const nameSidebarAtom = atom(false);

export const areaSectedAtom = atom(false);
export const bandSidebarAtom = atom(false);
export const areaAtom = atom();

export const cloudCoverAtom = atom([0, 40]);

export const orderDetailsAtom = atom({});
export const searchLogsAtom = atom("");
export const usersAtom = atom({});
export const searchUserAtom = atom("");
export const currentLogAtom = atom({});

export const creditsAtom = atom("");

export const showResultsAtom = atom(false);

export const currentSlideAtom = atom("item-1");
export const ordersAtom = atom([]);
export const logsAtom = atom([]);
export const adminDataAtom = atom({});
export const adminUsers = atom([]);

export const sarPolygonAtom = atom([]);
export const opticalPolygonAtom = atom([]);
export const searchOrdersAtom = atom("");
export const showToolBarAtom = atom(true);

export const clearLayersAtom = atom(false);
export const rectangleDrawAtom = atom(false);
export const layersPresentAtom = atom(false);
export const seeOpticalAtom = atom(true);
export const seeSarAtom = atom(true);
export const isCalendarHiddenAtom = atom(true);
export const isNameHiddenAtom = atom(true);
export const isBandsHiddenAtom = atom(true);

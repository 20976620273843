import { useEffect, useState, Fragment } from "react";
import Lottie from "react-lottie";
import { adminDataAtom } from "../../../atoms/index";
import { searchUserAtom } from "../../../atoms/index";
import { usersAtom } from "../../../atoms/index";
import { adminUsers } from "../../../atoms/index";
import * as _ from "lodash";
import { useAtom } from "jotai";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { HiFilter } from "react-icons/hi";
import { FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsBriefcase } from "react-icons/bs";
import { BsClockHistory } from "react-icons/bs";
import { BiMoney } from "react-icons/bi";
import { Navigate, useNavigate } from "react-router-dom";

import loading from "../../../lotties/loading/loading.json";
import satelliteLottie from "../../../lotties/satellite/satellite.json";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const handleSearch = (event, setSearch, adminUser, setFilteredData) => {
  let value = event.target.value.toLowerCase();
  console.log(value);
  let result = [];
  result = adminUser.filter((data) => {
    return data.email.toLowerCase().search(value) !== -1;
  });
  setFilteredData(result);
  setSearch(value);
};

const Users = () => {
  const [adminData] = useAtom(adminDataAtom);
  const [adminUser, setAdminUsers] = useAtom(adminUsers);
  const [search, setSearch] = useAtom(searchUserAtom);
  const [filteredData, setFilteredData] = useAtom(usersAtom);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(adminUser);
    if (search !== "") {
      let result = [];
      result = adminUser.filter((data) => {
        return data.email.toLowerCase().search(search) !== -1;
      });
      setFilteredData(result);
    } else {
      setFilteredData(adminUser);
    }
  }, [adminUser, search, setFilteredData]);

  return (
    <>
      {!_.isEmpty(filteredData) ? (
        <div className="flex flex-col h-screen px-10 pt-10 gap-5">
          <div className="h-0.5/10 w-full flex flex-start item-center">
            <h1 className="text-white text-2xl">Users</h1>
          </div>
          <div className="h-0.6/10 w-full flex gap-5 justify-center item-center">
            <div className="w-7.5/10">
              <input
                type="text"
                className="w-full rounded bg-grey h-full px-4"
                placeholder="Search..."
                onChange={(event) =>
                  handleSearch(event, setSearch, adminUser, setFilteredData)
                }
                value={search ? search : ""}
              />
            </div>
            <div className="w-1.5/10 flex bg-grey rounded ">
              <button className="w-3/10 flex items-center justify-center border-r border-gray-500">
                <FaSortAmountDown className="text-input_label text-xl" />
              </button>
              <button className="w-7/10 flex items-center justify-center">
                <h3 className="text-base text-input_label">Created date</h3>
              </button>
            </div>
            <div className="w-1/10 flex bg-grey rounded items-center justify-center gap-3 text-input_label">
              <HiFilter className="text-input_label text-xl" />
              <span>filters</span>
            </div>
          </div>
          <div className="h-9/10 flex flex-col py-10 w-full gap-3 overflow-y-auto px-5">
            {filteredData.map((user, i) => {
              return (
                <div key={i} className="w-full">
                  <div className="grid grid-cols-8 uppercase text-white items-center border-b-2 border-gray-500 py-3 justify-items-center justify-center gap-5">
                    <div className="uppercase text-white w-full flex justify-self-start col-span-1">
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="uppercase text-white w-1/2 flex justify-center items-center gap-2 col-span-1">
                      <span>
                        <BsBriefcase className="text-white text-xl" />
                      </span>
                      <span>{user.job_description}</span>
                    </div>
                    <div className="uppercase text-white w-full flex justify-center items-center gap-2 overflow-x-auto col-span-2">
                      <span>
                        <AiOutlineMail className="text-white text-xl" />
                      </span>
                      <span>{user.email}</span>
                    </div>
                    <div className="uppercase text-white w-full flex justify-center items-center gap-2 col-span-2">
                      <span>
                        <BsClockHistory className="text-white text-xl" />
                      </span>
                      <span>{dayjs(user.last_login).fromNow()}</span>
                    </div>
                    <div className="uppercase text-white w-full flex justify-center items-center gap-2 col-span-1">
                      <span>
                        <BiMoney className="text-white text-xl" />
                      </span>
                      <span>{user.credits}</span>
                    </div>
                    <button
                      className="uppercase text-my_purple w-full text-right cursor-pointer col-span-1"
                      onClick={() => navigate(`${user.id}`)}
                    >
                      View
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <Lottie options={lottieSatellite} height={400} width={400} />
        </div>
      )}
    </>
  );
};

export default Users;

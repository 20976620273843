import { useEffect, useState, Fragment } from "react";
import Lottie from "react-lottie";
import { adminDataAtom } from "../../../atoms/index";
import { currentLogAtom } from "../../../atoms/index";
import { searchLogsAtom } from "../../../atoms/index";
import { logsAtom } from "../../../atoms/index";
import * as _ from "lodash";
import { useAtom } from "jotai";
import { AiOutlineCalendar } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { HiFilter } from "react-icons/hi";
import { FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { BiMoney } from "react-icons/bi";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import loading from "../../../lotties/loading/loading.json";
import satelliteLottie from "../../../lotties/satellite/satellite.json";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const handleSearch = (event, setSearch, orders, setFilteredData) => {
  let value = event.target.value.toLowerCase();
  let result = [];
  result = orders.filter((data) => {
    return data.pipeline_name.toLowerCase().search(value) !== -1;
  });
  setFilteredData(result);
  setSearch(value);
};

const removeQuery = (navigate, setFilteredData, orders) => {
  setFilteredData(orders);
  navigate("/admin/logs");
};

const Logs = () => {
  const [adminData] = useAtom(adminDataAtom);
  const [orders, setOrders] = useState([]);
  const [search, setSearch] = useAtom(searchLogsAtom);
  const [filteredData, setFilteredData] = useAtom(logsAtom);
  const [currentLog, setCurrentLog] = useAtom(currentLogAtom);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("user");

  useEffect(() => {
    if (!_.isEmpty(adminData)) {
      const mappedData = adminData?.download_details.map((ele) => {
        return _.pick(ele.order_details, [
          "pipeline_name",
          "created_at",
          "credits_charged",
          "status",
          "id",
          "user",
        ]);
      });

      setOrders(mappedData);
      setFilteredData(mappedData);
    }
  }, [adminData, setFilteredData]);

  useEffect(() => {
    if (search !== "" && !id) {
      let result = [];
      result = orders.filter((data) => {
        return data.pipeline_name.toLowerCase().search(search) !== -1;
      });
      setFilteredData(result);
    }
  }, [id, orders, search, setFilteredData]);

  useEffect(() => {
    if (id) {
      let result = [];
      result = orders.filter((data) => {
        return data.user.id.toLowerCase().search(id) !== -1;
      });
      setFilteredData(result);
      console.log(result[0], "0000");
    }
  }, [id, orders, setFilteredData]);

  return (
    <>
      {filteredData ? (
        <div className="flex flex-col h-screen px-10 pt-10 gap-5">
          <div className="h-0.5/10 w-full flex flex-start items-start gap-10">
            <h1 className="text-white text-2xl">Logs</h1>
            {id && filteredData[0] ? (
              <div className="bg-green-400 pl-3 rounded-lg h-6.5/10 flex items-center justify-center mt-1.5 gap-3">
                <div>
                  {filteredData[0].user.first_name +
                    " " +
                    filteredData[0].user.last_name}
                </div>
                <div
                  className="bg-black text-white px-3 hover:text-red-600 hover:transition-all cursor-pointer h-full"
                  onClick={() => removeQuery(navigate, setFilteredData, orders)}
                >
                  X
                </div>
              </div>
            ) : null}
          </div>
          <div className="h-0.6/10 w-full flex gap-5 justify-center item-center">
            <div className="w-7.5/10">
              <input
                type="text"
                className="w-full rounded bg-grey h-full px-4 "
                placeholder="Search..."
                onChange={(event) =>
                  handleSearch(event, setSearch, orders, setFilteredData)
                }
                value={search ? search : ""}
              />
            </div>
            <div className="w-1.5/10 flex bg-grey rounded ">
              <button className="w-3/10 flex items-center justify-center border-r border-gray-500">
                <FaSortAmountDown className="text-input_label text-xl" />
              </button>
              <button className="w-7/10 flex items-center justify-center">
                <h3 className="text-base text-input_label">Created date</h3>
              </button>
            </div>
            <div className="w-1/10 flex bg-grey rounded items-center justify-center gap-3 text-input_label">
              <HiFilter className="text-input_label text-xl" />
              <span>filters</span>
            </div>
          </div>
          <div className="h-9/10 flex flex-col py-10 w-full gap-3 overflow-y-auto px-10">
            {filteredData.map((order, i) => {
              return (
                <div key={i} className="w-full">
                  <div className="grid grid-cols-6 uppercase text-white items-center border-b-2 border-gray-500 py-3 justify-items-center justify-center">
                    <div className="uppercase text-white w-full flex justify-self-start">
                      {order.pipeline_name}
                    </div>
                    <div className="flex gap-2">
                      <div>
                        <AiOutlineCalendar className="text-white text-2xl" />
                      </div>
                      <div>{dayjs(order.created_at).fromNow()}</div>
                    </div>
                    <div className="flex gap-2">
                      <div>
                        <FaUserAlt className="text-white text-2xl" />
                      </div>
                      <div>{`${order.user.first_name} ${order.user.last_name}`}</div>
                    </div>
                    <div className="flex gap-2">
                      <div>
                        <BiMoney className="text-white text-2xl" />
                      </div>
                      <div>{`${order.credits_charged}`}</div>
                    </div>

                    <div
                      className={`${
                        order.status === "Error"
                          ? "bg-red-300"
                          : order.status === "Processing"
                          ? "bg-white"
                          : order.status === "Placed"
                          ? "bg-white"
                          : "bg-green-300"
                      } w-24 h-10 flex justify-center items-center rounded text-black lowercase `}
                    >
                      {order.status}
                    </div>
                    <div
                      className="uppercase text-my_purple w-full text-right cursor-pointer"
                      onClick={() => {
                        setCurrentLog(order);
                        navigate(`${order.id}`);
                      }}
                    >
                      View
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <Lottie options={lottieSatellite} height={400} width={400} />
        </div>
      )}
    </>
  );
};

export default Logs;

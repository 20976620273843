import { GeoSearchControl, MapBoxProvider } from "leaflet-geosearch";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import "../../../node_modules/leaflet-geosearch/dist/geosearch.css";
import * as L from "leaflet";
import { useAtom } from "jotai";
import axios from "axios";
import { clearLayersAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import { showResultsAtom } from "../../atoms/index";
import { sarPolygonAtom } from "../../atoms/index";
import { nameSidebarAtom } from "../../atoms/index";
import { currentSlideAtom } from "../../atoms/index";
import { areaAtom } from "../../atoms/index";

import { userAtom } from "../../atoms/index";

import { opticalPolygonAtom } from "../../atoms/index";

const ClearButton = () => {
  const [isTrue, setIsTrue] = useAtom(clearLayersAtom);
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [showResult, setShowResult] = useAtom(showResultsAtom);
  const [sar, setSar] = useAtom(sarPolygonAtom);
  const [optical, setOptical] = useAtom(opticalPolygonAtom);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);
  const [selected, setSelected] = useAtom(nameSidebarAtom);
  const [area, setArea] = useAtom(areaAtom);

  const map = useMap();
  useEffect(() => {
    if (isTrue) {
      map.eachLayer(function (layer) {
        if (layer._path != null) {
          layer.remove();
          setDetails({});
          setSar([]);
          setOptical([]);
          setShowResult(false);
          setIsTrue(true);
          setSelected(false);
          setCurrentSlide("item-1");
          setArea();
          document.getElementsByClassName(
            "leaflet-draw-draw-rectangle"
          )[0].style.display = "block";
        } else {
          setDetails({});
          setSar([]);
          setOptical([]);
          setShowResult(false);
          setIsTrue(true);
          setSelected(false);
          setCurrentSlide("item-1");
          setArea();
          document.getElementsByClassName(
            "leaflet-draw-draw-rectangle"
          )[0].style.display = "block";
        }
      });
      setIsTrue(false);
    }
  }, [
    isTrue,
    map,
    setArea,
    setCurrentSlide,
    setDetails,
    setIsTrue,
    setOptical,
    setSar,
    setSelected,
    setShowResult,
  ]);

  return null;
};

export default ClearButton;

import Lottie from "react-lottie";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import * as _ from "lodash";

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function IsLoggedIn({ children }) {
  const [cookies] = useCookies();
  let navigate = useNavigate();
  const [isSessionValid, setIsSessionValid] = useState();
  const [user, setUser] = useAtom(userAtom);

  useEffect(() => {
    const checkSesion = async () => {
      try {
        // if (!_.isEmpty(cookies)) {
        const req = await axios.get(
          `${process.env.REACT_APP_AUTH_SERVER}/auth/v1/protected/no-access`,
          {
            withCredentials: true,
          }
        );
        if (req.status === 200) {
          setIsSessionValid(true);
        } else {
          setIsSessionValid(false);
        }
      } catch (error) {
        console.log(error?.response?.data || error);
        setIsSessionValid(false);
      }
    };
    checkSesion();
  }, []);

  return isSessionValid === true ? navigate(-1) : children;
}

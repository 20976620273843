import { useEffect, useState, Fragment } from "react";
import Lottie from "react-lottie";
import { userAtom } from "../../atoms/index";
import { adminUsers } from "../../atoms/index";
import { adminDataAtom } from "../../atoms/index";
import * as _ from "lodash";
import { useAtom } from "jotai";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import useSWR from "swr";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const checkSesion = async (
  user,
  setIsSessionValid,
  setAdminData,
  setAdminUsers
) => {
  try {
    const req = await axios.post(
      `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_admin_orders`,
      {
        organizationId: user.organization_id,
      },
      {
        withCredentials: true,
      }
    );
    if (req.data.status === "success") {
      setIsSessionValid(true);
      setAdminData(req.data.organization);
      setAdminUsers(req.data.users);
      console.log(req.data, "admin data");
    }
  } catch (err) {
    console.log(err);
    setIsSessionValid(false);
    toast.error(err.response.data.message);
  }
};

export default function AdminProtectedRoutes({ children }) {
  const [adminData, setAdminData] = useAtom(adminDataAtom);
  const [user, setUser] = useAtom(userAtom);
  const [adminUser, setAdminUsers] = useAtom(adminUsers);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSessionValid, setIsSessionValid] = useState("null");
  const location = useLocation();

  useEffect(() => {
    user.admin === true ? setIsAdmin(true) : setIsAdmin(false);
    if (user.admin === true) {
      if (_.isEmpty(adminData)) {
        console.log("admin data is empty");
        checkSesion(user, setIsSessionValid, setAdminData, setAdminUsers);
      } else {
        setIsSessionValid(true);
      }
    } else {
      setIsSessionValid(false);
      toast.error("Please login with admin account to access.");
    }
  }, [adminData, setAdminData, setAdminUsers, user]);

  return isSessionValid === true ? (
    children
  ) : isSessionValid === false ? (
    <div>
      <Navigate to="/" state={{ from: location }} replace />
    </div>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <Lottie options={lottieSatellite} height={400} width={400} />
    </div>
  );
}

import { useEffect, useState, Fragment } from "react";
import * as V from "victory";
import Lottie from "react-lottie";
import { adminDataAtom } from "../../../atoms/index";
import * as _ from "lodash";
import { useAtom } from "jotai";
import { AiOutlineCalendar } from "react-icons/ai";
import { HiFilter } from "react-icons/hi";
import { FaSortAmountDown } from "react-icons/fa";
import { FaSortAmountUpAlt } from "react-icons/fa";

import loading from "../../../lotties/loading/loading.json";
import satelliteLottie from "../../../lotties/satellite/satellite.json";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Charts = () => {
  const [adminData] = useAtom(adminDataAtom);
  const [zoomDomain, setZoomDomain] = useState({
    x: [new Date(1990, 1, 1), new Date(2005, 1, 1)],
  });
  const handleZoom = (domain) => {
    setZoomDomain(domain);
  };
  return (
    <>
      {!_.isEmpty(adminData) ? (
        <div className="h-screen px-10 pt-10 text-white flex flex-col gap-5 items-center justify center ">
          {/* <div className="h-1/3 bg-white">
            <V.VictoryChart
              height={400}
              width={400}
              domainPadding={{ x: 50, y: [0, 20] }}
              scale={{ x: "time" }}
            >
              <V.VictoryBar
                style={{ data: { fill: "tomato" } }}
                data={[
                  { x: new Date(1986, 1, 1), y: 5 },
                  { x: new Date(1996, 1, 1), y: 3 },
                  { x: new Date(2006, 1, 1), y: 5 },
                  { x: new Date(2024, 1, 1), y: 4 },
                ]}
              />
            </V.VictoryChart>
          </div> */}
          <div className="bg-white">
            <div>
              <V.VictoryChart
                width={600}
                height={470}
                scale={{ x: "time" }}
                containerComponent={
                  <V.VictoryZoomContainer
                    zoomDimension="x"
                    zoomDomain={zoomDomain}
                    onZoomDomainChange={handleZoom}
                  />
                }
              >
                <V.VictoryLine
                  style={{
                    data: { stroke: "tomato" },
                  }}
                  data={[
                    { a: new Date(1982, 1, 1), b: 125 },
                    { a: new Date(1987, 1, 1), b: 257 },
                    { a: new Date(1993, 1, 1), b: 345 },
                    { a: new Date(1997, 1, 1), b: 515 },
                    { a: new Date(2001, 1, 1), b: 132 },
                    { a: new Date(2005, 1, 1), b: 305 },
                    { a: new Date(2011, 1, 1), b: 270 },
                    { a: new Date(2015, 1, 1), b: 470 },
                  ]}
                  x="a"
                  y="b"
                />
              </V.VictoryChart>
              <V.VictoryChart
                padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                width={600}
                height={100}
                scale={{ x: "time" }}
                containerComponent={
                  <V.VictoryBrushContainer
                    brushDimension="x"
                    brushDomain={zoomDomain}
                    onBrushDomainChange={handleZoom}
                  />
                }
              >
                <V.VictoryAxis tickFormat={(x) => new Date(x).getFullYear()} />
                <V.VictoryLine
                  style={{
                    data: { stroke: "tomato" },
                  }}
                  data={[
                    { key: new Date(1982, 1, 1), b: 125 },
                    { key: new Date(1987, 1, 1), b: 257 },
                    { key: new Date(1993, 1, 1), b: 345 },
                    { key: new Date(1997, 1, 1), b: 515 },
                    { key: new Date(2001, 1, 1), b: 132 },
                    { key: new Date(2005, 1, 1), b: 305 },
                    { key: new Date(2011, 1, 1), b: 270 },
                    { key: new Date(2015, 1, 1), b: 470 },
                  ]}
                  x="key"
                  y="b"
                />
              </V.VictoryChart>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <Lottie options={lottieSatellite} height={400} width={400} />
        </div>
      )}
    </>
  );
};

export default Charts;

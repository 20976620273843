//ThemeContext.js
import React from "react";
import toast, { Toaster } from "react-hot-toast";

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("color-theme");
    if (typeof storedPrefs === "string") {
      return storedPrefs;
    }

    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "dark";
    }
  }

  return "light"; // light theme as the default;
};

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ initialTheme, children }) => {
  const [theme, setTheme] = React.useState(getInitialTheme);

  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(rawTheme);

    localStorage.setItem("color-theme", rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        containerStyle={{
          bottom: 70,
        }}
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
              width: "300px",
              height: "80px",
              fontSize: "1.2rem",
            },
          },
          loading: {
            style: {
              width: "300px",
              height: "80px",
              fontSize: "1.2rem",
              background: "#1B1B1B",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
              width: "300px",
              height: "80px",
              fontSize: "1rem",
              padding: "0.3rem",
            },
          },
        }}
      />
    </ThemeContext.Provider>
  );
};

import * as React from "react";

const AddUserToOrgIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={717.67}
    height={628.751}
    {...props}
  >
    <path
      d="M391.43 606.493a2.807 2.807 0 0 1-2.035-4.866l.192-.765-.076-.183a7.54 7.54 0 0 0-13.907.051c-2.274 5.478-5.17 10.966-5.883 16.758a22.303 22.303 0 0 0 .391 7.67 89.42 89.42 0 0 1-8.134-37.138 86.307 86.307 0 0 1 .536-9.628q.443-3.932 1.23-7.807a90.456 90.456 0 0 1 17.938-38.337 24.073 24.073 0 0 0 10.012-10.388 18.363 18.363 0 0 0 1.67-5.018c-.487.064-1.838-7.359-1.47-7.814-.68-1.03-1.895-1.543-2.637-2.549-3.69-5.002-8.772-4.129-11.426 2.669-5.669 2.86-5.724 7.606-2.245 12.169 2.213 2.903 2.517 6.832 4.458 9.94-.2.256-.407.503-.607.759a91.04 91.04 0 0 0-9.502 15.054 37.846 37.846 0 0 0-2.26-17.579c-2.162-5.217-6.216-9.611-9.786-14.121-4.287-5.418-13.08-3.054-13.835 3.814l-.022.2q.796.448 1.557.952a3.808 3.808 0 0 1-1.535 6.93l-.078.012a37.891 37.891 0 0 0 .999 5.665c-4.58 17.71 5.307 24.16 19.424 24.45.312.16.616.32.927.472a92.924 92.924 0 0 0-5.002 23.539 88.135 88.135 0 0 0 .064 14.23l-.024-.167a23.29 23.29 0 0 0-7.95-13.448c-6.118-5.026-14.762-6.877-21.363-10.916a4.37 4.37 0 0 0-6.694 4.252l.027.176a25.579 25.579 0 0 1 2.869 1.382q.795.449 1.557.953a3.808 3.808 0 0 1-1.536 6.93l-.077.012-.16.024a37.923 37.923 0 0 0 6.975 10.922c2.864 15.46 15.162 16.927 28.318 12.425h.008a92.897 92.897 0 0 0 6.24 18.218h22.293c.08-.248.152-.503.224-.751a25.329 25.329 0 0 1-6.169-.368c1.654-2.03 3.308-4.075 4.962-6.104a1.383 1.383 0 0 0 .104-.12c.84-1.039 1.686-2.07 2.525-3.108v-.002a37.101 37.101 0 0 0-1.087-9.45ZM358.4 254a127 127 0 1 1 127-127 127.144 127.144 0 0 1-127 127Zm0-252a125 125 0 1 0 125 125 125.141 125.141 0 0 0-125-125Z"
      fill="#f2f2f2"
    />
    <path
      d="M372.335 173h-27a5 5 0 0 1 0-10h27a5 5 0 0 1 0 10Z"
      fill="#e6e6e6"
    />
    <path
      d="M378.538 318.117h-39.406a7.297 7.297 0 1 1 0-14.595h39.406a7.297 7.297 0 1 1 0 14.595Z"
      fill="#6c63ff"
    />
    <path
      d="M490.547 287.53H227.123a8.863 8.863 0 0 1-8.854-8.853v-78.534a8.863 8.863 0 0 1 8.854-8.854h263.424a8.863 8.863 0 0 1 8.854 8.854v78.534a8.863 8.863 0 0 1-8.854 8.853Z"
      fill="#e6e6e6"
    />
    <path
      d="M464.952 281.559H233.096a8.863 8.863 0 0 1-8.854-8.853v-66.587a8.863 8.863 0 0 1 8.854-8.853h251.48a8.863 8.863 0 0 1 8.853 8.853v46.963a28.509 28.509 0 0 1-28.477 28.477Z"
      fill="#fff"
    />
    <path
      d="M384.975 220.947H279.1a2.283 2.283 0 1 1 0-4.566h105.876a2.283 2.283 0 0 1 0 4.566Z"
      fill="#6c63ff"
    />
    <path
      d="M438.572 230.286H279.099a2.283 2.283 0 1 1 0-4.567h159.473a2.283 2.283 0 0 1 0 4.567ZM438.572 241.005H279.099a2.283 2.283 0 1 1 0-4.566h159.473a2.283 2.283 0 0 1 0 4.566ZM438.572 251.724H279.099a2.283 2.283 0 1 1 0-4.566h159.473a2.283 2.283 0 0 1 0 4.566ZM438.572 262.444H279.099a2.283 2.283 0 1 1 0-4.567h159.473a2.283 2.283 0 0 1 0 4.567Z"
      fill="#e6e6e6"
    />
    <path
      fill="#a0616a"
      d="m578.689 615.836-10.701-.001-5.091-41.277 15.795.001-.003 41.277z"
    />
    <path
      d="m579.066 627-32.908-.001v-.416a12.81 12.81 0 0 1 12.809-12.81l6.012-4.56 11.215 4.562h2.873Z"
      fill="#2f2e41"
    />
    <path
      fill="#a0616a"
      d="m666.179 602.133-9.589 4.75-22.887-34.726 14.153-7.011 18.323 36.987z"
    />
    <path
      d="m671.473 611.97-29.488 14.608-.185-.373a12.81 12.81 0 0 1 5.791-17.163l3.363-6.756 12.074-.892 2.574-1.275ZM546.987 393.964s-12.182 16.982-3.389 46.32 3.857 55.569 6.325 60.829 7.436 10.254 3.33 14.242-6.266 7.23-3.024 8.31 14.051 76.74 14.051 76.74l18.375 1.081s6.656-35.145-.455-63.508c0 0 1.56-20.59-1.694-26.639s-2.617-.593-2.396-8.184a66.656 66.656 0 0 1 2.383-16.238c1.08-4.324 1.707-47.898 1.707-47.898l17.887 57.825a14.399 14.399 0 0 1 .789 6.226c-.443 2.979-.447 7.43.096 8.987s1.146 1.885.844 4.964S637.514 585 637.514 585l17.887-12-17.623-56.9a26.753 26.753 0 0 1-4.324-6.485c-2.161-4.324-2.245-4.562-1.123-7.685s1.123-9.209 1.123-11.03 1.123-84.503-12.949-96.664-73.518-.272-73.518-.272Z"
      fill="#2f2e41"
    />
    <path
      d="M613.063 262.423 609.4 252l-10-10-18.446-2.202-12.008 7.894-11.121 10.964L541.4 395s77.582 29.912 87.748 4.81Z"
      fill="#ccc"
    />
    <path
      d="m619.352 262.423-10.776-10.776s-5.148 68.872-2.955 73.716 4.776 3.607 1.984 6.225-5.791.02-2.791 4.82a15.19 15.19 0 0 1 2.477 9.089s15.523 97.71 24.523 97.71 14.635-2.635 14.317-5.318-5.626-9.362-3.972-11.022 5.733-.407 1.694-3.534-6.039-2.127-5.039-6.127-6.645-55.854-6.645-55.854l-.746-86.613ZM568.947 247.692l-13.956 1.975-16.59 6.333s7.577 85.482 5.995 88.344-4.393 1.075-1.988 3.969 4.57 1.61 1.988 4.252-6.929-.705-4.255 3.968S529.932 425.55 533.4 429s12.405 1.304 12.409-1.245a14.76 14.76 0 0 0-2.996-7.548 3.042 3.042 0 0 1 0-4 9.778 9.778 0 0 0 2.793-5.046c.207-1.954 1.735-4.356 2.47-6.155s19.28-123.59 19.28-123.59Z"
      fill="#3f3d56"
    />
    <path
      d="m509.4 315 6.295-3.286c-7.86-7.089-27.357-18.686-27.357-18.686L454.73 270.37a7.163 7.163 0 1 0-7.441 9.914l37.661 33.74Z"
      fill="#a0616a"
    />
    <path
      d="m555.4 290 .069-27.634-15.351-7.342L501.4 296l-3-2h-7l-17 20s9.054 5.366 13.527 3.183 4.052.953 4.052.953a4.743 4.743 0 0 0 5.72 1.346C501.4 318 508.4 322 508.4 322Z"
      fill="#3f3d56"
    />
    <circle cx={592.194} cy={211.063} r={22.386} fill="#a0616a" />
    <path
      d="M619.218 216.747a38.93 38.93 0 0 1-8.375 10.997 8.683 8.683 0 0 1-3.643 2.457 3.174 3.174 0 0 1-3.775-1.507l-.415-.567a11.553 11.553 0 0 0 3.351-1.658 3.934 3.934 0 0 0 1.621-3.214 2.423 2.423 0 0 0-2.468-2.28c-1.399.166-2.649 1.799-3.902 1.17-1.012-.515-.867-1.989-.576-3.1 1.557-5.806.906-14.804-1.336-15.118a18.133 18.133 0 0 1-6.262-2.563 17.57 17.57 0 0 0-6.281-2.527c-.162-.02-.325-.032-.499-.046a14.872 14.872 0 0 0-.62-3.529 17.4 17.4 0 0 1-1.11 3.525 34.518 34.518 0 0 1-5.85.296c-2.084-.242-8.074 4.614-8.118 3.946a14.871 14.871 0 0 0-.621-3.529 17.4 17.4 0 0 1-1.108 3.525c-.023.047-.033.086-.056.132-.636-1.553-.819-3.289-.803-1.992-1.337-8.25 1.315-13.216 8.085-18.108a10.219 10.219 0 0 1 4.662-2.086 5.023 5.023 0 0 1 4.62 1.706 16.489 16.489 0 0 1 16.707.757c4.87 3.263 8.711 5.433 8.063 11.268 4.616.444 8.515 4.151 9.973 8.545 1.446 4.4.695 9.311-1.264 13.5Z"
      fill="#2f2e41"
    />
    <path
      d="m647.793 333.396-16.06-2.3c-5.432 9.082-7.363 33.257-7.363 33.257l-15.718 37.359a7.163 7.163 0 1 0 11.167 5.38l25.808-43.484Z"
      fill="#a0616a"
    />
    <path
      d="m610.813 277.704 6.806-9.373 13.8 6.413 11.477 44.273s8.381 5.454 6.593 9.302-2.738 6.952-.89 9.156 7.231 2.367 3.221 5.962-.577 12.987-.577 12.987L647.401 365l-24-1Z"
      fill="#3f3d56"
    />
    <path
      d="M55.738 415.015a7.392 7.392 0 0 0 1.375-10.218 7.091 7.091 0 0 0-.787-.868l10.248-23.665-10.178-9.425-10.534 33.935a7.371 7.371 0 0 0-.16 8.998 7.054 7.054 0 0 0 9.883 1.362q.078-.058.153-.119Z"
      fill="#ffb6b6"
    />
    <path
      d="M115.275 261.5s-7.586-14.421-25.183 1.153-38.394 93.529-37.99 96.46 4.705 1.771-.735 5.99-8.449.43-5.44 4.22 9.734 7.808 3.778 8.372 15.879 9.958 15.879 9.958 3.434-.825 4.488-8.75c.592-4.45-1.414-6.978 2.116-7.72s.222-8.79.73-10.057 28.695-56.58 28.695-56.58Z"
      fill="#6c63ff"
    />
    <path
      fill="#ffb6b6"
      d="m149.919 613.706 12.742-.001 6.063-49.154-18.808.001.003 49.154z"
    />
    <path
      d="m149.47 627 39.187-.001v-.496a15.254 15.254 0 0 0-15.253-15.253l-7.159-5.43-13.355 5.43-3.421.001Z"
      fill="#2f2e41"
    />
    <path
      fill="#ffb6b6"
      d="m60.597 590.265 10.424 7.329 33.234-36.721-15.387-10.818-28.271 40.21z"
    />
    <path
      d="m52.583 600.881 32.057 22.54.285-.405a15.254 15.254 0 0 0-3.704-21.25v-.001l-2.732-8.56-14.05-3.24-2.798-1.967ZM99.145 369.166l-1.059 2.262c-6.96 14.869-8.74 31.49-6.035 47.684a2.701 2.701 0 0 1-.108 1.922c-1.677 1.967.955 10.515 2.463 15.288l1.36 8.44s-2.255 7.84 1.17 7.263-1.675 7.786 1.591 9.88l8.474 52.603-35.353 58.268 13.094 18.986 52.375-67.433.655-60.887 7.856 49.102.655 77.254 23.821-2.532 8.728-74.899-8.325-103.582-13.094-44.911Z"
      fill="#2f2e41"
    />
    <path
      d="m115.512 234.31 19.27.917 9.216 15.92a28.491 28.491 0 0 1 18.223 16.6l6.001 14.826-6.826 85.718s-1.369 5.185 1.15 4.428 1.493 5.74 1.493 5.74l9.287 35.716-84.427-7.341 4.688-37.841s-6.518.21-1.935-4.373 6.811-3.123 2.409-8.54-5.057-9.347-5.057-9.347l-1.964-26.842-3.238-37.777a36.299 36.299 0 0 1 13.713-31.621Z"
      fill="#6c63ff"
    />
    <circle cx={125.505} cy={205.45} r={24.439} fill="#ffb6b6" />
    <path
      d="M116.062 227.886c7.013-.23 7.65-2.083 14.663-2.313 2.233-.073 4.868-.38 5.948-2.335a5.154 5.154 0 0 0-.262-4.588 41.9 41.9 0 0 1-2.16-4.25 7.423 7.423 0 0 1 7.355-9.787c2.134.093 4.138 1.096 6.267 1.28 2.918.251 9.373-9.386 8.029-11.99-1.308-2.533-3.94-4.445-4.355-7.267-.27-1.845.492-3.679.596-5.541.154-2.761-6.845 3.465-5.564 1.014 3.145-6.011-26.487-13.464-31.365-5.934-1.823 2.814-5.073 2.622-7.969 4.312-1.898 1.108-6.691 4.93-7.78 7.311a18.545 18.545 0 0 0-1.389 7.674c-.044 12.218 1.818 23.457 11.673 30.679"
      fill="#2f2e41"
    />
    <path
      d="M185.442 413.965a7.392 7.392 0 0 1-.762-10.282 7.092 7.092 0 0 1 .837-.82l-8.817-24.234 10.723-8.8 8.49 34.503a7.371 7.371 0 0 1-.379 8.991 7.054 7.054 0 0 1-9.946.77q-.074-.063-.146-.128Z"
      fill="#ffb6b6"
    />
    <path
      d="M135.175 257.17s8.433-13.943 25.07 2.654 32.742 95.654 32.164 98.555-4.803 1.488.376 6.024 8.408.932 5.18 4.537-10.185 7.213-4.273 8.131-16.444 8.992-16.444 8.992-3.38-1.028-3.958-9.001c-.325-4.478 1.827-6.881-1.652-7.833s.304-8.788-.128-10.082-25.267-58.193-25.267-58.193ZM378.35 124.27a20.005 20.005 0 1 1-20.01-20 19.983 19.983 0 0 1 20.01 20Z"
      fill="#6c63ff"
    />
    <circle cx={358.787} cy={120.17} r={7} fill="#fff" />
    <path
      d="M366.29 129.67h-15a3.504 3.504 0 0 0-3.5 3.5 52.96 52.96 0 0 0 1.4 8.89 19.986 19.986 0 0 0 19.34-.57 52.457 52.457 0 0 0 1.26-8.32 3.504 3.504 0 0 0-3.5-3.5Z"
      fill="#fff"
    />
    <path
      d="M0 627.561a1.186 1.186 0 0 0 1.19 1.19h715.29a1.19 1.19 0 0 0 0-2.38H1.19a1.187 1.187 0 0 0-1.19 1.19Z"
      fill="#ccc"
    />
  </svg>
);

export default AddUserToOrgIcon;

import { useState, useEffect } from "react";
import Lottie from "react-lottie";
import EmailVerifiedIcon from "../../components/emailVerfiedIcon";
import WrongIdIcon from "../../components/emailVerfiedIcon/wrongIdIcon";
import { useParams, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import axios from "axios";
import toast from "react-hot-toast";

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const navigateToOrder = (setUser, navigate, properID) => {
  setUser({});
  navigate(`/orders/${properID}`, { replace: true });
};

const AccountVerified = () => {
  const [user, setUser] = useAtom(userAtom);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [joined, setJoined] = useState(null);

  console.log(id);

  useEffect(() => {
    (async function () {
      try {
        const req = await axios.get(
          `${process.env.REACT_APP_AUTH_SERVER}/auth/v1/verify-email/${id}`,
          {
            withCredentials: true,
          }
        );
        console.log(req);
        if (req.data.status === "success") {
          setLoading(false);
          setJoined(true);
        }
      } catch (err) {
        console.log(err.response);
        if (err.response.data.message === "jwt malformed") {
          toast.error("Invalid Id");
          setJoined(false);
        } else {
          toast.error(err.response.data.message);
        }
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      {!loading ? (
        <>
          {joined ? (
            <div className="w-full h-full bg-order_confirmed grid grid-cols-2 gap-10 items-center bg-order_background justify-center">
              <div>
                <EmailVerifiedIcon />
              </div>
              <div className="flex flex-col gap-14 items-center">
                <div className="text-8xl lowercase text-black font-pipeline_name flex flex-col items-center">
                  <span>your</span>
                  <span>account has </span>
                  <span>been verified</span>
                </div>
                <button
                  className="text-2xl rounded-full bg-black px-2 py-4 text-order_background font-pipeline_name w-2/3  transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                  onClick={() => navigateToOrder(setUser, navigate)}
                >
                  Sign in
                </button>
              </div>
            </div>
          ) : (
            <div className="w-full h-full bg-order_confirmed grid grid-cols-2 gap-10 items-center bg-red-300 justify-center">
              <div>
                <WrongIdIcon />
              </div>
              <div className="flex flex-col gap-14 items-center">
                <div className="text-6xl lowercase text-black font-pipeline_name flex flex-col items-center">
                  <span>Please recheck</span>
                  <span>your Email</span>
                  <span>and try again</span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <Lottie options={lottieSatellite} height={400} width={400} />
        </div>
      )}
    </>
  );
};

export default AccountVerified;

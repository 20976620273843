import { Fragment, useState } from "react";
import Lottie from "react-lottie";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
import axios from "axios";
import { userAtom } from "../../atoms/index";
import { adminUsers } from "../../atoms/index";
import { adminDataAtom } from "../../atoms/index";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import { AiOutlineCalendar } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import * as _ from "lodash";
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const checkSesion = async (user, setIsLoading, setAdminData, setAdminUsers) => {
  setIsLoading(true);
  try {
    const req = await axios.post(
      `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_admin_orders`,
      {
        organizationId: user.organization_id,
      },
      {
        withCredentials: true,
      }
    );
    if (req.data.status === "success") {
      setAdminData(req.data.organization);
      setAdminUsers(req.data.users);
      console.log(req.data, "admin data");
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
  setIsLoading(false);
};

const Admin = () => {
  const [user, setUser] = useAtom(userAtom);
  const [adminData, setAdminData] = useAtom(adminDataAtom);
  const [adminUser, setAdminUsers] = useAtom(adminUsers);
  const [isLoading, setIsLoading] = useState("null");

  const navigate = useNavigate();

  return (
    <>
      {user.admin ? (
        <>
          {!_.isEmpty(adminData) ? (
            <div className="w-full h-full p-14">
              <div>
                <h1 className="text-3xl text-white">Dashboard</h1>
              </div>
              <div className="w-full h-full py-10 flex gap-10">
                <div className="w-6.5/10 flex flex-col gap-10">
                  <div className="h-2.5/10 flex gap-5">
                    <div className="w-1/3 bg-nav flex flex-col items-center justify-center text-white uppercase text-2xl">
                      <div>{adminData.credits}</div>
                      <div className="text-sm">Credits Left</div>
                    </div>
                    <div className="w-1/3 bg-nav flex flex-col items-center justify-center text-white uppercase text-2xl">
                      <div>{dayjs(adminData.contract_period).fromNow()}</div>
                      <div className="text-sm">Contract Left</div>
                    </div>
                    <div className="w-1/3 bg-nav flex flex-col items-center justify-center text-white uppercase text-2xl">
                      <div>{adminData.credits}</div>
                      <div>Credits</div>
                    </div>
                  </div>
                  <div className="h-7.5/10 flex flex-col">
                    <div className="flex justify-between">
                      <h2 className="text-white text-2xl">Logs</h2>

                      <div className="text-my_purple flex gap-5 justify-center items-center">
                        <div
                          className="flex cursor-pointer items-center justify-end text-my_purple gap-2"
                          onClick={() =>
                            checkSesion(
                              user,
                              setIsLoading,
                              setAdminData,
                              setAdminUsers
                            )
                          }
                        >
                          <BiRefresh
                            className={`${
                              isLoading === true ? "animate-spin" : ""
                            } text-xl`}
                          />
                          <span> Refresh</span>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => navigate("/admin/logs")}
                        >
                          View all
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col py-3 w-full gap-3">
                      {adminData.download_details.map((order, i) => {
                        return (
                          <div key={i} className="w-full">
                            {i <= 5 ? (
                              <div className="grid grid-cols-4 uppercase text-white items-center border-b-2 border-gray-500 py-3 justify-items-center">
                                <div className="uppercase text-white w-full flex justify-self-start">
                                  {order.order_details.pipeline_name}
                                </div>
                                <div className="flex gap-2">
                                  <div>
                                    <AiOutlineCalendar className="text-white text-2xl" />
                                  </div>
                                  <div>
                                    {dayjs(
                                      order.order_details.created_at
                                    ).fromNow()}
                                  </div>
                                </div>
                                <div></div>

                                <div
                                  className={`${
                                    order.order_details.status === "Error"
                                      ? "bg-red-300"
                                      : order.order_details.status ===
                                        "Processing"
                                      ? "bg-white"
                                      : order.order_details.status === "Placed"
                                      ? "bg-white"
                                      : "bg-green-300"
                                  } w-24 h-10 flex justify-center items-center rounded text-black lowercase justify-self-end`}
                                >
                                  {order.order_details.status}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="w-3.5/10 flex flex-col gap-5"></div>
              </div>
            </div>
          ) : (
            <div className="w-screen h-screen flex justify-center items-center">
              <Lottie options={lottieSatellite} height={400} width={400} />
            </div>
          )}
        </>
      ) : (
        <Navigate to="/" replace={true} />
      )}
    </>
  );
};

export default Admin;

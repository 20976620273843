import React from "react";

const Background = ({ children }) => {
  return (
    <div className="bg-background transition-all w-screen h-screen">
      {children}
    </div>
  );
};

export default Background;

import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { nameSidebarAtom } from "../../atoms/index";
import { bandSidebarAtom } from "../../atoms/index";
import { currentSlideAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import BandsSidebar from "../bandsSidebar";
import NameSidebar from "../nameSidebar";
import Sidebar from "../sidebar";
import React from "react";
import { styled, keyframes } from "@stitches/react";
import { violet, blackA, mauve } from "@radix-ui/colors";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

const StyledAccordion = styled(AccordionPrimitive.Root, {
  borderRadius: 6,
  width: "100%",
  backgroundColor: "#30313A",
  boxShadow: `0 2px 10px ${blackA.blackA4}`,
});

const StyledItem = styled(AccordionPrimitive.Item, {
  overflow: "hidden",
  marginTop: 1,

  "&:first-child": {
    marginTop: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  "&:last-child": {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },

  "&:focus-within": {
    position: "relative",
    zIndex: 1,
    boxShadow: `0 0 0 1px ${violet.violet10}`,
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
  display: "flex",
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  fontFamily: "inherit",
  backgroundColor: "#1B1B1B",
  padding: "0 20px",
  height: 45,
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 15,
  lineHeight: 1,
  color: "#fff",
  boxShadow: `0 1px 0 ${mauve.mauve6}`,
  '&[data-state="closed"]': { backgroundColor: "#1B1B1B" },
  '&[data-state="open"]': { backgroundColor: "#1B1B1B" },
  "&:hover": { backgroundColor: mauve.mauve2, color: violet.violet10 },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  overflow: "hidden",
  fontSize: 15,
  color: mauve.mauve11,
  backgroundColor: "#1B1B1B",

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const StyledContentText = styled("div", {
  padding: "15px 20px",
});

const StyledChevron = styled(ChevronDownIcon, {
  color: violet.violet10,
  transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  "[data-state=open] &": { transform: "rotate(180deg)" },
});

// Exports
export const Accordion = StyledAccordion;
export const AccordionItem = StyledItem;
export const AccordionTrigger = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <StyledHeader>
      <StyledTrigger {...props} ref={forwardedRef}>
        {children}
        <StyledChevron aria-hidden />
      </StyledTrigger>
    </StyledHeader>
  )
);
export const AccordionContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
      <StyledContentText>{children}</StyledContentText>
    </StyledContent>
  )
);

const SidebarAccordian = () => {
  const [selected, setSelected] = useAtom(nameSidebarAtom);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);
  const [dateRange, setDateRange] = useAtom(areaSectedAtom);
  const [bandSidebar, setBandSidebar] = useAtom(bandSidebarAtom);

  console.log(dateRange);

  return (
    <div
      className={`${
        selected ? "translate-x-4" : "-translate-x-full"
      } w-3/10 bg-nav z-20 flex absolute top-1/14 rounded-lg shadow-lg ease-in-out duration-500`}
    >
      <Accordion type="single" defaultValue={"item-1"} value={currentSlide}>
        <AccordionItem value="item-1">
          <AccordionTrigger>Project name</AccordionTrigger>
          <AccordionContent>
            <NameSidebar setCurrentSlide={setCurrentSlide} />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2" disabled={!dateRange}>
          <AccordionTrigger>Date Range and cloud cover</AccordionTrigger>
          <AccordionContent>
            <Sidebar setCurrentSlide={setCurrentSlide} />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3" disabled={!bandSidebar}>
          <AccordionTrigger>Bands</AccordionTrigger>
          <AccordionContent>
            <BandsSidebar setCurrentSlide={setCurrentSlide} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default SidebarAccordian;

import {
  MapContainer,
  GeoJSON,
  Marker,
  Popup,
  TileLayer,
  FeatureGroup,
  LayersControl,
  ImageOverlay,
} from "react-leaflet";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import StatusBar from "../../components/statusBar";
import useSWR from "swr";
import { useCookies } from "react-cookie";
import Lottie from "react-lottie";
import toast from "react-hot-toast";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import * as turf from "@turf/turf";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { useParams } from "react-router-dom";
import axios from "axios";
import geoData from "./data.json";
import { FaFileInvoice, FaDownload } from "react-icons/fa";
import {
  MdOutlineContactSupport,
  MdPreview,
  MdInfoOutline,
} from "react-icons/md";
import { FiSettings, FiClock } from "react-icons/fi";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const fetcher = (url, orderID) =>
  axios
    .post(
      url,
      { orderID: orderID },
      {
        headers: { "Content-Type": "application/json", withCredentials: true },
        withCredentials: true,
      }
    )
    .then((res) => res.data);

const onDownload = async (id) => {
  try {
    const req = await axios({
      method: "post",
      url: `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/get_signed_url`,

      data: {
        orderID: id,
      },
      headers: { "Content-Type": "application/json", withCredentials: true },
      withCredentials: true,
    });
    window.open(req.data.url);
  } catch (e) {
    console.log(e);
  }
};

const onPreview = async (id) => {
  try {
    const req = await axios({
      method: "post",
      url: `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/get_preview_url`,

      data: {
        orderID: id,
      },
      headers: { "Content-Type": "application/json", withCredentials: true },
      withCredentials: true,
    });
    window.open(req.data.url);
  } catch (e) {
    console.log(e);
  }
};

const showData = (ele, i, dataOrder) => {
  return (
    <div className="flex flex-col gap-3 border-b-2 pb-10 border-white" key={i}>
      <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">Platform Name</div>
        <div className="text-base text-pipeline_name">{ele.platformname}</div>
      </div>
      <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">Bands</div>
        <div className="text-base text-pipeline_name">
          {ele.platformname === "Sentinel-1"
            ? dataOrder.sar_bands.map((ele, i, arr) => {
                if (i < arr.length - 1) {
                  return ele + ", ";
                } else {
                  return ele;
                }
              })
            : dataOrder.optical_bands.map((ele, i, arr) => {
                if (i < arr.length - 1) {
                  return ele + ", ";
                } else {
                  return ele;
                }
              })}
        </div>
      </div>
      <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">orbit Direction</div>
        <div className="text-base text-pipeline_name capitalize">
          {ele.orbitdirection}
        </div>
      </div>
      <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">Size</div>
        <div className="text-base text-pipeline_name ">{ele.size}</div>
      </div>
      <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">Date</div>
        <div className="text-base text-pipeline_name">{ele.date}</div>
      </div>
      <div className="flex flex-col">
        <div className=" text-gray-500 uppercase">Product Type</div>
        <div className="text-base text-pipeline_name">{ele.producttype}</div>
      </div>
    </div>
  );
};

const Order = () => {
  const [user, setUser] = useAtom(userAtom);
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const orders = user.orders;
  let { id } = useParams();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState();
  const [sarUrl, setSatUrl] = useState("");
  const [opticalUrl, setOpticalUrl] = useState("");
  const mapRef = useRef(null);
  const [seeSar, setSeeSar] = useState(false);
  const [seeOptical, setSeeOptical] = useState(true);
  const [json, setJson] = useState({});
  const [tilesci, setTilesci] = useState(null);
  const [centerCoord, setCenterCoord] = useState([0, 0]);

  const { data, error } = useSWR(
    `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/check_order_status`,
    (url) => fetcher(url, id),
    { refreshInterval: 60000 }
  );

  if (error) {
    console.log(error);
  }

  useEffect(() => {
    if (!currentOrder) {
      (async function () {
        try {
          const req = await axios.get(
            `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_single_order/${id}`,
            {
              withCredentials: true,
            }
          );
          console.log(req, "reqqqq");
          if (req.data.status === "success") {
            console.log(req.data.data.order, "ordersssss");
            const order = req.data.data.order;
            setCurrentOrder(order);
            setJson(JSON.parse(order.data));
            setStatus(order.status.status);
          }
        } catch (err) {
          console.log(err);
          navigate("/404", { replace: true });
        }
      })();
    }

    console.log("Setting JSONNN");
    if (json?.aoi) {
      console.log(json, "json");
      let switchoru = json.aoi.map((el) => {
        let test = el.toString().split(",").reverse();
        test.concat();
        return test.map((el) => {
          return parseFloat(el);
        });
      });

      if (switchoru.length === 4) {
        let temp = switchoru[1];
        switchoru[1] = switchoru[3];
        switchoru[3] = temp;
      }

      let at0 = switchoru[0];
      switchoru.push(at0);

      const passedAOI = [[...switchoru]];
      const tilesci = turf.polygon(passedAOI, {
        fill: "#F00",
        "fill-opacity": 0.1,
      });

      setTilesci(tilesci);

      var center = turf.center(tilesci);

      // exchange first position with second position
      const centerPosition = center.geometry.coordinates;
      const centerCoord = centerPosition
        .toString()
        .split(",")
        .reverse()
        .concat()
        .map((el) => {
          return parseFloat(el);
        });

      setCenterCoord(centerCoord);
    }
    setIsLoading(true);
  }, [currentOrder, id, json, json.aoi, navigate, orders]);

  useEffect(() => {
    setIsLoading(false);
    if (data?.status.status) {
      setStatus(data?.status.status);
      if (data?.status.status === "Processed") {
        (async function () {
          try {
            const sarReq = await axios({
              method: "post",
              url: `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/get_demo_sar`,

              data: {
                orderID: id,
              },
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
              },
              withCredentials: true,
            });

            setSatUrl(sarReq.data.url);

            const opticalReq = await axios({
              method: "post",
              url: `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/get_demo_optical`,

              data: {
                orderID: id,
              },
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
              },
              withCredentials: true,
            });

            setOpticalUrl(opticalReq.data.url);
            setProgress(100);
            setSeeOptical(true);
          } catch (err) {
            console.log(err);
          }
        })();
      } else if (data?.status.status === "Processing") {
        setProgress(50);
        setSeeOptical(false);
        setSeeSar(false);
      } else if (data?.status.status === "Placed") {
        setProgress(0);
      } else if (
        data?.status.status === "Error" ||
        data?.status.status === "Cloud cover percentage exceeded"
      ) {
        setProgress(100);
        setSeeOptical(false);
        setSeeSar(false);
      } else {
        setProgress(0);
        setSeeOptical(false);
        setSeeSar(false);
      }
    }
    setIsLoading(true);
  }, [data?.status.status, id, status]);

  return (
    <>
      {currentOrder && isLoading && status ? (
        <>
          {/* <div className="absolute top-3 left-7 w-28 h-14">
            <img src="../../../logo/main-hero.png" alt="logo" />
          </div> */}
          <div className="bg-switch_button text-pipeline_name absolute right-0 z-50 w-48 text-xl text-center rounded-l px-2">
            Area : {json.area} sqkm
          </div>
          <div className="w-full h-full grid grid-cols-2">
            <div className="w-full h-full grid grid-rows-17 px-14 py-4">
              <div className="row-span-1 h-full flex items-center justify-between pt-4">
                <h2 className="font-pipeline_name text-3xl text-pipeline_name">
                  {currentOrder.pipeline_name}
                </h2>
                <a
                  className="flex items-center gap-5"
                  href={`mailto:support@galaxeye.space?subject=order_Id - ${id}`}
                >
                  <MdOutlineContactSupport className="text-2xl cursor-pointer text-pipeline_name" />
                </a>
              </div>
              <div className="row-span-3 w-full h-full flex flex-col items-center justify-center">
                <StatusBar progress={progress} status={status} />
                {status === "Processing" ? (
                  <div className="text-base italic text-my_purple pt-7">
                    An email will be sent once your order is processed.
                  </div>
                ) : null}
              </div>
              <div className="row-span-7 border-2 border-white rounded-sm grid grid-cols-2 items-center">
                <div className="grid grid-rows-8 items-center justify-center pl-10 col-span-1 w-full">
                  <div className="text-2xl font-pipeline_name text-pipeline_name row-span-2 mb-5">
                    SAR
                  </div>
                  <div className="row-span-6 row-start-3 overflow-y-auto h-60">
                    {json?.sar?.map((ele, i) => showData(ele, i, json))}
                  </div>
                </div>
                <div className="grid grid-rows-8 items-center justify-center pl-10 col-span-1 w-full">
                  <div className="text-2xl font-pipeline_name text-pipeline_name row-span-2 mb-5">
                    Optical
                  </div>
                  <div className="row-span-6 row-start-3 overflow-y-auto h-60">
                    {json?.optical?.map((ele, i) => showData(ele, i, json))}
                  </div>
                </div>
              </div>
              <div className="row-span-3 flex justify-around items-center">
                {progress === 100 &&
                status !== "Error" &&
                status !== "Cloud cover percentage exceeded" ? (
                  <>
                    <button
                      className="px-8 py-2 bg-white rounded-sm text-center h-12 flex justify-between items-center gap-5"
                      onClick={() => onDownload(id)}
                    >
                      <span>Download</span>
                      <FaDownload className="text-xl cursor-pointer" />
                    </button>
                    <button
                      className="px-8 py-2 bg-white rounded-sm text-center h-12 flex justify-between items-center gap-5"
                      onClick={() => onPreview(id)}
                    >
                      <div className="flex flex-col text-sm">
                        <span className="uppercase text-base">Preview</span>
                        <span>Coregistered image</span>
                      </div>
                      <MdPreview className="text-2xl cursor-pointer" />
                    </button>
                  </>
                ) : (
                  <>
                    {status === "Error" ||
                    status === "Cloud cover percentage exceeded" ? (
                      <button className="px-8 py-2 bg-white rounded-sm text-center h-12 flex justify-between items-center gap-5">
                        <span>Contact Support</span>
                        <FiSettings className="text-xl cursor-pointer" />
                      </button>
                    ) : (
                      <button className="px-8 py-2 bg-white rounded-sm text-center h-12 flex justify-between items-center gap-5">
                        <span>Est time 1-2 hour</span>
                        <FiClock className="text-xl cursor-pointer" />
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-full h-full">
              <MapContainer
                center={centerCoord}
                zoom={
                  json.area < 500
                    ? 10
                    : json.area > 500 && json.area < 2500
                    ? 8
                    : 7
                }
                scrollWheelZoom={true}
                style={{ height: "100%", width: "100%" }}
                minZoom="5"
                className="z-10"
                ref={mapRef}
              >
                <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA"></TileLayer>
                {seeSar || seeOptical ? null : <GeoJSON data={tilesci} />}
                {sarUrl !== "" && seeSar && status !== "Error" ? (
                  <ImageOverlay
                    url={sarUrl}
                    bounds={[json.aoi[0], json.aoi[2]]}
                  />
                ) : null}
                {opticalUrl !== "" && seeOptical && status !== "Error" ? (
                  <ImageOverlay
                    url={opticalUrl}
                    bounds={[json.aoi[0], json.aoi[2]]}
                  />
                ) : null}
              </MapContainer>

              <div
                className={`${
                  sarUrl &&
                  opticalUrl &&
                  status !== "Error" &&
                  status !== "Processing"
                    ? "flex flex-col gap-5 absolute bottom-5 right-2 z-20 bg-background px-3 py-3  text-white items-start justify-start rounded-md"
                    : "none"
                }`}
              >
                <div className="flex items-center justify-center gap-1">
                  <input
                    type="checkbox"
                    id="sar"
                    checked={seeSar}
                    onChange={() => setSeeSar(!seeSar)}
                    className="accent-red-600"
                  />
                  <label htmlFor="sar" className="text-red-600">
                    SAR
                  </label>
                </div>
                <div className="flex items-center justify-center gap-1">
                  <input
                    type="checkbox"
                    id="optical"
                    checked={seeOptical}
                    onChange={() => setSeeOptical(!seeOptical)}
                    className="accent-green-600"
                  />
                  <label htmlFor="optical" className="text-green-600">
                    Optical
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-screen h-screen flex justify-center items-center">
          <Lottie options={lottieSatellite} height={400} width={400} />
        </div>
      )}
    </>
  );
};

export default Order;

import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAtom } from "jotai";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { BsArrowUpRight } from "react-icons/bs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { currentLogAtom } from "../../../../atoms/index";

import "react-loading-skeleton/dist/skeleton.css";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Log = () => {
  const navigate = useNavigate();
  const { log } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentLog, setCurrentLog] = useAtom(currentLogAtom);
  const [json, setJson] = useState({});
  const [currentOrder, setCurrentOrder] = useState(null);
  const [status, setStatus] = useState();

  useEffect(() => {
    if (log) {
      (async function () {
        try {
          const req = await axios.get(
            `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_single_order/${log}`,
            {
              withCredentials: true,
            }
          );
          console.log(req, "reqqqq");
          if (req.data.status === "success") {
            console.log(req.data.data.order, "ordersssss");
            const order = req.data.data.order;
            setCurrentOrder(order);
            setJson(JSON.parse(order.data));
            setStatus(order.status);
            console.log(status);
          }
        } catch (err) {
          console.log(err);
          navigate("/404", { replace: true });
        }
      })();
    }
  }, [log, navigate, status]);

  return (
    <>
      {status ? (
        <div
          className={`${
            status === "Processed"
              ? "bg-green-400"
              : status === "Error"
              ? "bg-red-400"
              : "bg-white"
          } w-full h-full p-5`}
        >
          <SkeletonTheme baseColor="#111726" highlightColor="#808080">
            <div className="px-10 py-10 flex flex-col gap-10 w-full h-full bg-background rounded-md relative ">
              <div
                className="absolute top-0 left-0 bg-white p-2 rounded-br-md cursor-pointer"
                onClick={() => navigate("/admin/logs")}
              >
                Go back
              </div>
              <div className="h-3/10"></div>
              <div className="h-6/10"></div>
              <div className="h-1/10"></div>
              <div className="text-black">{status}</div>
            </div>
          </SkeletonTheme>
        </div>
      ) : null}
    </>
  );
};

export default Log;

import { useAtom } from "jotai";
import { seeSarAtom } from "../../atoms/index";
import { seeOpticalAtom } from "../../atoms/index";
import { showResultsAtom } from "../../atoms/index";
import { userAtom } from "../../atoms/index";
import { useEffect } from "react";

import { opticalPolygonAtom } from "../../atoms/index";

const CheckBoxLayer = () => {
  const [seeSar, setSeeSar] = useAtom(seeSarAtom);
  const [seeOptical, setSeeOptical] = useAtom(seeOpticalAtom);
  const [showResults, setShowResults] = useAtom(showResultsAtom);

  return (
    <div
      className={`${
        showResults
          ? "flex flex-col gap-5 absolute bottom-3/10 right-2 z-20 bg-background px-3 py-3  text-white items-start justify-start rounded-md"
          : "none"
      }`}
    >
      <div className="flex items-center justify-center gap-1">
        <input
          type="checkbox"
          id="sar"
          checked={seeSar}
          onChange={() => setSeeSar(!seeSar)}
          className="accent-red-600"
        />
        <label htmlFor="sar" className="text-red-600">
          SAR
        </label>
      </div>
      <div className="flex items-center justify-center gap-1">
        <input
          type="checkbox"
          id="optical"
          checked={seeOptical}
          onChange={() => setSeeOptical(!seeOptical)}
          className="accent-green-600"
        />
        <label htmlFor="optical" className="text-green-600">
          Optical
        </label>
      </div>
    </div>
  );
};

export default CheckBoxLayer;

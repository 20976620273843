import * as Avatar from "@radix-ui/react-avatar";
import { useAtom } from "jotai";
import { FaUser } from "react-icons/fa";
import { mapModeAtom } from "../../atoms/index";

const onClick = (mode, setMapMode) => {
  console.log(mode);
  setMapMode(mode);
};

const SwitchMode = () => {
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  return (
    <div className="bg-switch_button rounded-full w-1/6 h-12 flex items-center z-20 absolute top-28 right-20 justify-between p-1">
      <button
        className={`text-white text-sm rounded-full ${
          mapMode === "default" ? "bg-switch_alternate" : "bg-switch_button"
        } w-full h-full `}
        onClick={() => onClick("default", setMapMode)}
      >
        Default
      </button>
      <button
        className={`text-white text-sm rounded-full ${
          mapMode === "default" ? "bg-switch_button" : "bg-switch_alternate"
        } w-full h-full `}
        onClick={() => onClick("satellite", setMapMode)}
      >
        Satellite
      </button>
    </div>
  );
};

export default SwitchMode;

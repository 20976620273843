import Icon404 from "../../components/Icon404";

export default function MobileView() {
  return (
    <div className="flex justify-center items-center w-screen h-screen flex-col gap-10">
      <div className="text-4xl text-white flex justify-center items-center w-full text-center">
        This site is not compatible with mobile, please use a laptop or desktop.
      </div>
    </div>
  );
}

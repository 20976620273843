import Icon404 from "../../components/Icon404";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center w-screen h-screen flex-col gap-10">
      <Icon404 />
      <div className="text-4xl text-white">Page not found</div>
      <button
        className="text-2xl rounded-full bg-my_purple px-2 py-4 text-order_background font-pipeline_name w-3/10  transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
        onClick={() => navigate(`/`, { replace: true })}
      >
        Return to home page
      </button>
    </div>
  );
}

import * as React from "react";

const WrongIdIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={742.417}
    height={712.573}
    {...props}
  >
    <path
      data-name="Path 968"
      d="M624.815 168.892h-3.9V61.915A61.915 61.915 0 0 0 559 0H332.35a61.915 61.915 0 0 0-61.916 61.914v586.884a61.915 61.915 0 0 0 61.915 61.915h226.648a61.915 61.915 0 0 0 61.915-61.915V245.04h3.9Z"
      fill="#3f3d56"
    />
    <path
      data-name="Path 969"
      d="M608.215 57.768v595.175a46.959 46.959 0 0 1-46.942 46.952h-231.3A46.966 46.966 0 0 1 283 652.943V57.768a46.965 46.965 0 0 1 46.971-46.951h28.058a22.329 22.329 0 0 0 20.656 30.74h131.868a22.329 22.329 0 0 0 20.656-30.74h30.055a46.959 46.959 0 0 1 46.951 46.942Z"
      fill="#fff"
    />
    <circle
      data-name="Ellipse 18"
      cx={445.565}
      cy={200.129}
      r={96.565}
      fill="#6c63ff"
    />
    <path
      data-name="Path 39"
      d="M550.881 400.596H345.344a3.81 3.81 0 0 1-3.806-3.806v-50.984a3.811 3.811 0 0 1 3.806-3.806h205.537a3.811 3.811 0 0 1 3.806 3.806v50.985a3.811 3.811 0 0 1-3.806 3.806Zm-205.537-57.074a2.286 2.286 0 0 0-2.284 2.284v50.985a2.286 2.286 0 0 0 2.284 2.284h205.537a2.286 2.286 0 0 0 2.284-2.284v-50.985a2.286 2.286 0 0 0-2.284-2.284Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 40"
      d="M408.908 360.361a2.664 2.664 0 0 0 0 5.329h125.605a2.665 2.665 0 0 0 .204-5.326q-.058-.002-.117-.002H408.908Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 41"
      d="M408.908 376.345a2.664 2.664 0 0 0 0 5.329h125.605a2.665 2.665 0 0 0 .204-5.326q-.058-.002-.117-.002H408.908Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 42"
      d="M550.881 485.576H345.344a3.81 3.81 0 0 1-3.806-3.806v-50.984a3.811 3.811 0 0 1 3.806-3.806h205.537a3.811 3.811 0 0 1 3.806 3.806v50.985a3.811 3.811 0 0 1-3.806 3.805Zm-205.537-57.074a2.286 2.286 0 0 0-2.284 2.284v50.985a2.286 2.286 0 0 0 2.284 2.284h205.537a2.286 2.286 0 0 0 2.284-2.284v-50.985a2.286 2.286 0 0 0-2.284-2.284Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 43"
      d="M408.908 445.617a2.664 2.664 0 0 0 0 5.329h125.605a2.665 2.665 0 0 0 .087-5.328H408.908Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 44"
      d="M408.908 461.605a2.664 2.664 0 0 0 0 5.329h125.605a2.665 2.665 0 0 0 .087-5.328H408.908Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 39-2"
      d="M550.881 570.834H345.344a3.81 3.81 0 0 1-3.806-3.806v-50.984a3.811 3.811 0 0 1 3.806-3.806h205.537a3.811 3.811 0 0 1 3.806 3.806v50.985a3.811 3.811 0 0 1-3.806 3.806ZM345.344 513.76a2.286 2.286 0 0 0-2.284 2.284v50.985a2.286 2.286 0 0 0 2.284 2.284h205.537a2.286 2.286 0 0 0 2.284-2.284v-50.985a2.286 2.286 0 0 0-2.284-2.284Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 40-2"
      d="M408.908 530.878a2.664 2.664 0 1 0 0 5.329h125.605a2.665 2.665 0 0 0 .204-5.326q-.058-.002-.117-.002H408.908Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 41-2"
      d="M408.908 546.865a2.664 2.664 0 0 0 0 5.329h125.605a2.665 2.665 0 0 0 .204-5.326q-.058-.002-.117-.002H408.908Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 970"
      d="M740.479 712.573H1.938c-1.071 0-1.938-.468-1.938-1.045s.868-1.045 1.938-1.045h738.541c1.07 0 1.938.468 1.938 1.045s-.867 1.045-1.938 1.045Z"
      fill="#e6e6e6"
    />
    <g data-name="Group 58">
      <path
        data-name="Path 438"
        d="M709.201 671.808a19.474 19.474 0 0 1-18.806-3.313c-6.587-5.528-8.652-14.636-10.332-23.07l-4.97-24.945 10.405 7.165c7.483 5.152 15.134 10.47 20.316 17.933s7.443 17.651 3.28 25.726"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 439"
        d="M707.594 703.745c1.31-9.542 2.657-19.206 1.738-28.849-.816-8.565-3.429-16.93-8.749-23.789a39.574 39.574 0 0 0-10.153-9.2c-1.015-.641-1.95.968-.939 1.606a37.622 37.622 0 0 1 14.881 17.956c3.24 8.241 3.76 17.224 3.2 25.977-.338 5.294-1.053 10.553-1.774 15.805a.964.964 0 0 0 .65 1.144.936.936 0 0 0 1.144-.65Z"
        fill="#f2f2f2"
      />
      <path
        data-name="Path 442"
        d="M698.167 688.435a14.336 14.336 0 0 1-12.491 6.447c-6.323-.3-11.595-4.713-16.34-8.9l-14.035-12.395 9.289-.444c6.68-.32 13.533-.618 19.9 1.442s12.231 7.018 13.394 13.6"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 443"
        d="M711.295 709.23c-6.3-11.156-13.618-23.555-26.685-27.518a29.779 29.779 0 0 0-11.224-1.159c-1.192.1-.894 1.94.3 1.837a27.665 27.665 0 0 1 17.912 4.739c5.051 3.438 8.983 8.217 12.311 13.286 2.039 3.1 3.865 6.341 5.691 9.573.58 1.032 2.286.287 1.695-.758Z"
        fill="#f2f2f2"
      />
    </g>
    <g data-name="Group 59">
      <circle
        data-name="Ellipse 5"
        cx={370.986}
        cy={370.985}
        r={15.986}
        fill="#6c63ff"
      />
      <path
        data-name="Path 40-3"
        d="M363.333 367.999c-.184 0-.333 1.193-.333 2.664s.149 2.665.333 2.665h15.719c.184.024.336-1.149.339-2.62a5.943 5.943 0 0 0-.328-2.708h-15.73Z"
        fill="#e6e6e6"
      />
    </g>
    <g data-name="Group 60">
      <circle
        data-name="Ellipse 5-2"
        cx={370.986}
        cy={456.278}
        r={15.986}
        fill="#6c63ff"
      />
      <path
        data-name="Path 40-4"
        d="M363.333 453.292c-.184 0-.333 1.193-.333 2.664s.149 2.665.333 2.665h15.719c.184.024.336-1.149.339-2.62a5.943 5.943 0 0 0-.328-2.708h-15.73Z"
        fill="#e6e6e6"
      />
    </g>
    <g data-name="Group 61">
      <circle
        data-name="Ellipse 5-3"
        cx={370.986}
        cy={541.536}
        r={15.986}
        fill="#6c63ff"
      />
      <path
        data-name="Path 40-5"
        d="M363.333 538.55c-.184 0-.333 1.193-.333 2.664s.149 2.665.333 2.665h15.719c.184.024.336-1.149.339-2.62a5.943 5.943 0 0 0-.328-2.708h-15.73Z"
        fill="#e6e6e6"
      />
    </g>
    <path
      fill="#fff"
      d="m419.048 179.27 5.657-5.658 47.376 47.377-5.657 5.656z"
    />
    <path
      fill="#fff"
      d="m466.425 173.612 5.657 5.657-47.377 47.376-5.657-5.657z"
    />
    <path
      d="M90.26 456.135a10.056 10.056 0 0 0 5.387-14.447l23.565-26.864-18.395-2.538-19.558 25.9a10.11 10.11 0 0 0 9 17.949ZM118.679 694.215l-16.435-.001-7.819-63.394 24.257.001-.003 63.394z"
      fill="#9f616a"
    />
    <path
      d="m122.87 710.147-52.994-.002v-.67a20.628 20.628 0 0 1 20.627-20.627h.001l32.368.001Z"
      fill="#2f2e41"
    />
    <path
      fill="#9f616a"
      d="m204.713 680.461-15.696 4.873-26.266-58.224 23.166-7.192 18.796 60.543z"
    />
    <path
      d="m213.44 694.433-50.61 15.714-.2-.64a20.628 20.628 0 0 1 13.583-25.816h.001l30.912-9.598Z"
      fill="#2f2e41"
    />
    <path
      data-name="Path 973"
      d="M106.093 401.943 81.752 429.82l19.056 1.715Z"
      fill="#e6e6e6"
    />
    <path
      data-name="Path 975"
      d="M96.582 437.876s-8.455 4.227-9.512 23.251 3.171 68.7 3.171 68.7-4.227 22.194 0 42.274-4.227 93 1.057 93 32.762 3.171 33.819 0 2.114-50.729 2.114-50.729 8.455-24.308 0-39.1c0 0 29.52 51.548 48.615 90.889 4.179 8.61 35.933-1.057 30.649-10.569s-17.966-52.843-17.966-52.843-9.512-31.706-26.421-45.445l8.455-67.639s17.967-45.445 7.4-51.786-81.381-.003-81.381-.003Z"
      fill="#2f2e41"
    />
    <circle
      data-name="Ellipse 182"
      cx={128.287}
      cy={238.129}
      r={27.478}
      fill="#a0616a"
    />
    <path
      data-name="Path 976"
      d="m158.936 267.721-34.16 20.08s-13.08 7.366-17.966 20.08c-5.208 13.55-2.181 32.628 0 36.99 4.227 8.455-1.773 29.592-1.773 29.592l-5.284 48.615s-19.023 17.966-4.227 20.08 41.217-1.057 57.07 0 33.819 3.171 28.535-7.4-11.625-17.967-5.284-39.1c4.962-16.54 4.747-78.383 4.419-104.5a21.025 21.025 0 0 0-10.211-17.767Z"
      fill="#e5e5e5"
    />
    <path
      data-name="Path 980"
      d="m372.407 394.726 3.17 64.468-30.726 62.223-5.21-1.983 31.705-58.127Z"
      transform="translate(-228.791 -93.713)"
      opacity={0.1}
      style={{
        isolation: "isolate",
      }}
    />
    <path
      data-name="Path 982"
      d="M407.28 472.932v-7.4l-35.93 59.186Z"
      transform="translate(-228.791 -93.713)"
      opacity={0.1}
      style={{
        isolation: "isolate",
      }}
    />
    <path
      data-name="Path 983"
      d="m108.785 212.674-4.539-1.816s9.5-10.457 22.713-9.548l-3.717-4.092s9.085-3.637 17.345 5.91c4.342 5.019 9.365 10.919 12.5 17.564h4.865l-2.03 4.471 7.106 4.471-7.294-.8a24.74 24.74 0 0 1-.69 11.579l.2 3.534s-8.459-13.089-8.459-14.905v4.547s-4.543-4.092-4.543-6.82l-2.478 3.183-1.239-5-15.28 5 2.476-4.094-9.5 1.364 3.717-5s-10.737 5.91-11.15 10.912-5.781 11.366-5.781 11.366l-2.478-4.547s-3.716-20.459 8.256-27.279Z"
      fill="#2f2e41"
    />
    <path
      d="M126.563 459.125a10.056 10.056 0 0 0 2.737-15.174l18.423-30.62-18.554.768-14.65 28.96a10.11 10.11 0 0 0 12.044 16.066Z"
      fill="#9f616a"
    />
    <path
      data-name="Path 981"
      d="m168.449 281.462 7.926-1.585s23.779 17.438 16.381 52.314-40.16 87.719-40.16 87.719-7.4 9.512-9.512 11.625-6.341 0-4.227 3.171-3.171 5.284-3.171 5.284-23.251 0-21.137-8.455 38.047-68.7 38.047-68.7l-5.284-56.013s-4.229-27.474 21.137-25.36Z"
      fill="#e5e5e5"
    />
  </svg>
);

export default WrongIdIcon;

import OrderConfirmIcon from "../../components/orderConfirmedIcon";
import { useParams, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";

// const Json = JSON.stringify(currentOrder.data);
// console.log(Json.replaceAll("\\", ""));

const navigateToOrder = (setUser, navigate, properID) => {
  setUser({});
  navigate(`/orders/${properID}`, { replace: true });
};

const OrderConfirmed = () => {
  const [user, setUser] = useAtom(userAtom);
  const { id } = useParams();
  const navigate = useNavigate();
  const properID = id.replaceAll(" ", "-");
  return (
    <div className="w-full h-full bg-order_confirmed grid grid-cols-2 gap-10 items-center bg-order_background justify-center">
      <div>
        <OrderConfirmIcon />
      </div>
      <div className="flex flex-col gap-14 items-center">
        <div className="text-8xl lowercase text-black font-pipeline_name flex flex-col items-center">
          <span>your</span>
          <span>order is</span>
          <span>confirmed</span>
        </div>
        <button
          className="text-2xl rounded-full bg-black px-2 py-4 text-order_background font-pipeline_name w-2/3  transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
          onClick={() => navigateToOrder(setUser, navigate, properID)}
        >
          View order details
        </button>
      </div>
    </div>
  );
};

export default OrderConfirmed;

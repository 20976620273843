import * as React from "react";

const EmailVerifiedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={883.968}
    height={607.464}
    {...props}
  >
    <path
      d="M772.718 128.073h-.125l-71.49 30.526-97.813 41.767a3.155 3.155 0 0 1-2.416.013l-100.915-41.867-73.246-30.39-.112-.05h-.125a4.364 4.364 0 0 0-4.359 4.36v189.312a4.364 4.364 0 0 0 4.36 4.359h346.241a4.364 4.364 0 0 0 4.36-4.36V132.433a4.364 4.364 0 0 0-4.36-4.36Z"
      fill="#fff"
    />
    <path
      d="M773.029 129.318a.62.62 0 0 1-.356-.112L600.45 9.266a3.123 3.123 0 0 0-3.567.005L425.9 129.205a.623.623 0 0 1-.716-1.02L596.167 8.251a4.373 4.373 0 0 1 4.995-.008l172.223 119.941a.623.623 0 0 1-.356 1.134Z"
      fill="#3f3d56"
    />
    <path
      fill="#e6e6e6"
      d="M435.67 133.57 598.834 12.645l164.403 129.529-155.996 92.476-84.692-19.304-86.879-81.776z"
    />
    <path
      d="M529.698 291.77h-80.607a3.766 3.766 0 0 1-.011-7.533h80.618a3.766 3.766 0 0 1 .012 7.532ZM478.011 275.578h-28.92a3.766 3.766 0 0 1-.011-7.532h28.931a3.766 3.766 0 1 1 .011 7.532Z"
      fill="#6c63ff"
    />
    <path
      d="M601.13 200.923a4.71 4.71 0 0 1-1.788-.35L498.714 158.82V28.123a4.364 4.364 0 0 1 4.359-4.359h193.048a4.364 4.364 0 0 1 4.36 4.36V158.91l-.19.08-97.321 41.562a4.743 4.743 0 0 1-1.84.37Z"
      fill="#fff"
    />
    <path
      d="M601.13 201.234a5.027 5.027 0 0 1-1.906-.372l-100.822-41.835V28.123a4.676 4.676 0 0 1 4.671-4.67h193.048a4.676 4.676 0 0 1 4.671 4.67v130.993l-97.7 41.723a5.05 5.05 0 0 1-1.963.395Zm-101.482-43.038 100.05 41.514a3.81 3.81 0 0 0 2.908-.017l96.94-41.399V28.124a3.43 3.43 0 0 0-3.425-3.426H503.073a3.43 3.43 0 0 0-3.425 3.425Z"
      fill="#3f3d56"
    />
    <path
      d="M771.784 128.073h-.125l-71.49 30.526-97.813 41.767a3.155 3.155 0 0 1-2.416.013l-100.915-41.867-73.246-30.39-.112-.05h-.125a4.364 4.364 0 0 0-4.359 4.36v189.312a4.364 4.364 0 0 0 4.36 4.359h346.24a4.364 4.364 0 0 0 4.36-4.36V132.433a4.364 4.364 0 0 0-4.36-4.36Zm3.114 193.67a3.115 3.115 0 0 1-3.114 3.114H425.542a3.115 3.115 0 0 1-3.114-3.113V132.432a3.12 3.12 0 0 1 2.996-3.114l73.601 30.539 100.435 41.674a4.434 4.434 0 0 0 3.388-.02l97.321-41.56 71.74-30.633a3.124 3.124 0 0 1 2.989 3.114Z"
      fill="#3f3d56"
    />
    <path
      d="M585.463 72.619h-68.501a4.982 4.982 0 0 1 0-9.964h68.501a4.982 4.982 0 0 1 0 9.964ZM549.967 51.446h-33.005a4.982 4.982 0 0 1 0-9.964h33.005a4.982 4.982 0 0 1 0 9.964Z"
      fill="#6c63ff"
    />
    <path
      d="M650.83 122.438H546.212a4.982 4.982 0 0 1 0-9.964h104.62a4.982 4.982 0 0 1 0 9.964ZM650.83 145.479H546.212a4.982 4.982 0 0 1 0-9.964h104.62a4.982 4.982 0 0 1 0 9.964Z"
      fill="#ccc"
    />
    <circle cx={769.853} cy={129.878} r={47.371} fill="#6c63ff" />
    <path
      fill="#fff"
      d="m765.339 149.875-14.178-18.231 8.245-6.413 6.713 8.632 22.679-23.941 7.584 7.185-31.043 32.768z"
    />
    <path
      d="M239.765 409.853a10.056 10.056 0 0 1 4.214-14.833l-3.08-35.601 16.325 8.848.423 32.452a10.11 10.11 0 0 1-17.882 9.134Z"
      fill="#9f616a"
    />
    <path
      d="m242.859 387.33-.133-.336-18.012-45.503-2.858-86.253.48-.034a26.466 26.466 0 0 1 28.321 25.114l2.642 46.374 7.488 59.642Z"
      fill="#e5e5e5"
    />
    <path
      fill="#9f616a"
      d="m127.8 577.505 10.354 6.565 30.247-36.815-15.282-9.688-25.319 39.938z"
    />
    <path
      d="m127.302 572.45 20.391 12.928h.001a15.387 15.387 0 0 1 4.756 21.234l-.268.422-33.386-21.166Z"
      fill="#2f2e41"
    />
    <path
      fill="#9f616a"
      d="m225.692 595.15 12.26-.001 5.832-47.288-18.094.001.002 47.288z"
    />
    <path
      d="M222.565 591.147h24.145a15.387 15.387 0 0 1 15.386 15.386v.5l-39.53.001ZM191.003 397.016l28.863 173.602s-5.678 14.195 2.838 16.088 27.443-1.893 27.443-1.893 5.678-14.195-2.839-16.087l-16.087-181.389Z"
      fill="#2f2e41"
    />
    <path
      d="m208.037 400.112-19.996 81.174-50.977 74.02s-18.926 1.893-8.517 12.302 15.14 11.356 15.14 11.356 16.088-1.893 15.142-9.463l62.673-73.77 29.118-98.458Z"
      fill="#2f2e41"
    />
    <path
      d="m190.587 407.26-4.828-27.557c-7.272-7.6 6.468-26.391 8-28.432l-.9-11.686a3.507 3.507 0 0 1-1.992-1.085c-4.51-4.6-4.208-22.75-4.193-23.521l-4.978-51.785 12.386-7.226 31.322-1.01 15.296 13.257 14.733 135.352-.525.029Z"
      fill="#e5e5e5"
    />
    <path
      d="M201.427 416.926a10.056 10.056 0 0 1-2.443-15.224L181.16 370.73l18.535 1.128 14.086 29.238a10.11 10.11 0 0 1-12.353 15.83Z"
      fill="#9f616a"
    />
    <path
      d="m185.642 387.965-15.377-35.644-2.57-72.811a13.098 13.098 0 0 1 6.607-11.862l17.739-10.84 3.827 76.544 11.447 45.747Z"
      fill="#e5e5e5"
    />
    <circle cx={213.238} cy={226.044} r={22.008} fill="#9f616a" />
    <path
      d="M221.196 193.548c-22.165-6.392-28.456 11.979-28.456 11.979-10.684 7.088-3.995 15.975-3.995 15.975-2.696.598 2.694 16.374 4.492 15.975.959-.213 3.425 2.73 5.516 5.522a11.07 11.07 0 0 1 2.137.206l-4.058-15.013s13.679-2.096 17.872-8.685c4.013-6.305 17.222-8.295 20.98-4.084a9.505 9.505 0 0 1 1.341-3.404 6.8 6.8 0 0 1 1.971-1.976c-3.323-14.124-17.8-16.495-17.8-16.495Z"
      fill="#2f2e41"
    />
    <path
      opacity={0.2}
      d="m196.224 305.464-1.228 43.811 15.242 32.126-14.014-77.102"
    />
    <path d="M382 607.464H1a1 1 0 1 1 0-2h381a1 1 0 0 1 0 2Z" fill="#3f3d56" />
    <path
      d="M329.336 47.278a1.999 1.999 0 0 0-2.997 1.991l1.543 11.324a1.3 1.3 0 0 1-.033.499 1.221 1.221 0 0 1-.237.436l-8.647 10.243a1.954 1.954 0 0 0-.127 2.414l.009.013a1.951 1.951 0 0 0 2.282.752l6.245-2.12a1.202 1.202 0 0 1 1.207.272 1.162 1.162 0 0 1 .373.855l.244 22.249a1.984 1.984 0 0 0 .874 1.628 2.004 2.004 0 0 0 1.833.214l46.607-17.825a1.998 1.998 0 0 0 .306-3.586l-.634-.377-48.848-28.982Zm-2.208 1.883a1.2 1.2 0 0 1 1.802-1.193L377.76 76.94l.65.385a1.201 1.201 0 0 1-.182 2.151l-46.607 17.825a1.198 1.198 0 0 1-1.625-1.106l-.244-22.249a1.915 1.915 0 0 0-.111-.646l47.45 5.452a.8.8 0 0 0 .48-1.497.696.696 0 0 0-.125-.054l-48.761-16.276a1.97 1.97 0 0 0-.015-.442l-1.543-11.323Z"
      fill="#f1f1f1"
    />
    <path
      d="M329.336 47.278a1.999 1.999 0 0 0-2.997 1.991l1.543 11.324a1.3 1.3 0 0 1-.033.499 1.221 1.221 0 0 1-.237.436l-8.647 10.243a1.954 1.954 0 0 0-.127 2.414l.009.013a1.951 1.951 0 0 0 2.282.752l6.245-2.12a1.202 1.202 0 0 1 1.207.272 1.162 1.162 0 0 1 .373.855l.244 22.249a1.984 1.984 0 0 0 .874 1.628 2.004 2.004 0 0 0 1.833.214l46.607-17.825a1.998 1.998 0 0 0 .306-3.586l-.634-.377-48.848-28.982Zm-2.208 1.883a1.2 1.2 0 0 1 1.802-1.193L377.76 76.94l.65.385a1.201 1.201 0 0 1-.182 2.151l-46.607 17.825a1.198 1.198 0 0 1-1.625-1.106l-.244-22.249a1.915 1.915 0 0 0-.111-.646 1.943 1.943 0 0 0-.512-.782c-.032-.031-.067-.06-.102-.09l-.002-.003a1.992 1.992 0 0 0-1.908-.35l-6.247 2.12a1.162 1.162 0 0 1-1.37-.455l-.004-.007a1.16 1.16 0 0 1 .077-1.447l8.647-10.243a1.89 1.89 0 0 0 .239-.35l.003-.002a1.916 1.916 0 0 0 .152-.377 1.857 1.857 0 0 0 .07-.39 1.97 1.97 0 0 0-.014-.441l-1.543-11.323Z"
      fill="#e5e5e5"
    />
    <path
      d="m328.686 60.926 48.76 16.276a.697.697 0 0 1 .125.054.8.8 0 0 1-.48 1.497l-47.45-5.452-.837-.095.092-.793.13.013.002.003 48.154 5.529-48.72-16.267-.003.003-.21-.073.253-.758Z"
      fill="#e5e5e5"
    />
    <path
      d="M873.112.278 824.265 29.26l-.634.377a1.998 1.998 0 0 0 .306 3.586l46.607 17.825a2.004 2.004 0 0 0 1.833-.214 1.984 1.984 0 0 0 .874-1.628l.244-22.25a1.162 1.162 0 0 1 .372-.854 1.202 1.202 0 0 1 1.208-.271l6.245 2.12a1.951 1.951 0 0 0 2.282-.753l.01-.013a1.954 1.954 0 0 0-.128-2.414l-8.647-10.243a1.221 1.221 0 0 1-.237-.436 1.3 1.3 0 0 1-.034-.5L876.11 2.27a1.999 1.999 0 0 0-2.998-1.99Zm2.21 1.883-1.545 11.323a1.97 1.97 0 0 0-.014.442l-48.76 16.276a.696.696 0 0 0-.126.054.8.8 0 0 0 .48 1.497l47.45-5.452a1.915 1.915 0 0 0-.112.646l-.244 22.25a1.198 1.198 0 0 1-1.624 1.105L824.22 32.477a1.201 1.201 0 0 1-.182-2.15l.65-.386L873.519.968a1.2 1.2 0 0 1 1.802 1.193Z"
      fill="#f1f1f1"
    />
    <path
      d="M873.112.278 824.265 29.26l-.634.377a1.998 1.998 0 0 0 .306 3.586l46.607 17.825a2.004 2.004 0 0 0 1.833-.214 1.984 1.984 0 0 0 .874-1.628l.244-22.25a1.162 1.162 0 0 1 .372-.854 1.202 1.202 0 0 1 1.208-.271l6.245 2.12a1.951 1.951 0 0 0 2.282-.753l.01-.013a1.954 1.954 0 0 0-.128-2.414l-8.647-10.243a1.221 1.221 0 0 1-.237-.436 1.3 1.3 0 0 1-.034-.5L876.11 2.27a1.999 1.999 0 0 0-2.998-1.99Zm2.21 1.883-1.545 11.323a1.97 1.97 0 0 0-.014.442 1.857 1.857 0 0 0 .071.389 1.916 1.916 0 0 0 .152.377l.003.002a1.89 1.89 0 0 0 .24.35l8.646 10.243a1.16 1.16 0 0 1 .077 1.447l-.005.007a1.162 1.162 0 0 1-1.369.456l-6.247-2.122a1.992 1.992 0 0 0-1.908.35l-.003.004c-.035.03-.07.059-.1.09a1.943 1.943 0 0 0-.513.782 1.915 1.915 0 0 0-.112.646l-.244 22.25a1.198 1.198 0 0 1-1.624 1.105L824.22 32.477a1.201 1.201 0 0 1-.182-2.15l.65-.386L873.519.968a1.2 1.2 0 0 1 1.802 1.193Z"
      fill="#e5e5e5"
    />
    <path
      d="m873.947 13.863.253.758-.21.073-.004-.003-48.72 16.267 48.154-5.53.003-.002.13-.013.092.793-.838.095-47.45 5.452a.8.8 0 0 1-.48-1.497.697.697 0 0 1 .125-.054l48.761-16.276Z"
      fill="#e5e5e5"
    />
    <path
      d="M455.126 405.222a1.999 1.999 0 0 0 .08 3.597l10.397 4.747a1.3 1.3 0 0 1 .403.295 1.221 1.221 0 0 1 .242.434l4.037 12.782a1.954 1.954 0 0 0 1.972 1.398h.016a1.951 1.951 0 0 0 1.856-1.528l1.546-6.411a1.202 1.202 0 0 1 .875-.875 1.162 1.162 0 0 1 .92.141l18.936 11.684a1.984 1.984 0 0 0 1.844.132 2.004 2.004 0 0 0 1.16-1.435l9.842-48.919a1.998 1.998 0 0 0-2.867-2.175l-.658.334-50.6 25.799Zm.41 2.872a1.2 1.2 0 0 1-.044-2.16l50.585-25.79.673-.343a1.201 1.201 0 0 1 1.72 1.304l-9.842 48.919a1.198 1.198 0 0 1-1.802.782l-18.936-11.684a1.915 1.915 0 0 0-.606-.251l29.967-37.192a.8.8 0 0 0-1.009-1.206.696.696 0 0 0-.113.077l-39.816 32.515a1.97 1.97 0 0 0-.38-.224l-10.396-4.747Z"
      fill="#f1f1f1"
    />
    <path
      d="M455.126 405.222a1.999 1.999 0 0 0 .08 3.597l10.397 4.747a1.3 1.3 0 0 1 .403.295 1.221 1.221 0 0 1 .242.434l4.037 12.782a1.954 1.954 0 0 0 1.972 1.398h.016a1.951 1.951 0 0 0 1.856-1.528l1.546-6.411a1.202 1.202 0 0 1 .875-.875 1.162 1.162 0 0 1 .92.141l18.936 11.684a1.984 1.984 0 0 0 1.844.132 2.004 2.004 0 0 0 1.16-1.435l9.842-48.919a1.998 1.998 0 0 0-2.867-2.175l-.658.334-50.6 25.799Zm.41 2.872a1.2 1.2 0 0 1-.044-2.16l50.585-25.79.673-.343a1.201 1.201 0 0 1 1.72 1.304l-9.842 48.919a1.198 1.198 0 0 1-1.802.782l-18.936-11.684a1.915 1.915 0 0 0-.606-.251 1.943 1.943 0 0 0-.934.014c-.044.01-.087.024-.13.038h-.005a1.992 1.992 0 0 0-1.316 1.425l-1.545 6.415a1.162 1.162 0 0 1-1.118.913h-.008a1.16 1.16 0 0 1-1.181-.838l-4.037-12.783a1.89 1.89 0 0 0-.168-.388v-.004a1.915 1.915 0 0 0-.238-.33 1.857 1.857 0 0 0-.29-.268 1.97 1.97 0 0 0-.382-.224l-10.395-4.747Z"
      fill="#e5e5e5"
    />
    <path
      d="m466.313 413.065 39.816-32.515a.697.697 0 0 1 .113-.077.8.8 0 0 1 1.009 1.206l-29.967 37.192-.528.657-.62-.502.08-.103h.003l30.408-37.746-39.785 32.486v.004l-.174.139-.506-.619Z"
      fill="#e5e5e5"
    />
  </svg>
);

export default EmailVerifiedIcon;

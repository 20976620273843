import React, { useState } from "react";
import { styled, keyframes } from "@stitches/react";
import { violet, blackA, mauve, green } from "@radix-ui/colors";
import { Cross2Icon } from "@radix-ui/react-icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FaUserPlus } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAtom } from "jotai";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { adminDataAtom } from "../../../atoms/index";
import { userAtom } from "../../../atoms/index";

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA9,
  position: "fixed",
  inset: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  backgroundColor: "white",
  borderRadius: 6,
  boxShadow:
    "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: "450px",
  maxHeight: "85vh",
  padding: 25,
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  "&:focus": { outline: "none" },
});

function Content({ children, ...props }) {
  return (
    <DialogPrimitive.Portal>
      <StyledOverlay />
      <StyledContent {...props}>{children}</StyledContent>
    </DialogPrimitive.Portal>
  );
}

const StyledTitle = styled(DialogPrimitive.Title, {
  margin: 0,
  fontWeight: 500,
  color: mauve.mauve12,
  fontSize: 17,
});

const StyledDescription = styled(DialogPrimitive.Description, {
  margin: "10px 0 20px",
  color: mauve.mauve11,
  fontSize: 15,
  lineHeight: 1.5,
});

// Exports
export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogContent = Content;
export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;
export const DialogClose = DialogPrimitive.Close;

// Your app...
const Flex = styled("div", { display: "flex" });
const Box = styled("div", {});

const Button = styled("button", {
  all: "unset",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  padding: "0 15px",
  fontSize: 15,
  lineHeight: 1,
  fontWeight: 500,
  height: 35,

  variants: {
    variant: {
      violet: {
        backgroundColor: "white",
        color: violet.violet11,
        boxShadow: `0 2px 10px ${blackA.blackA7}`,
        "&:hover": { backgroundColor: mauve.mauve3 },
        "&:focus": { boxShadow: `0 0 0 2px black` },
      },
      green: {
        backgroundColor: green.green4,
        color: green.green11,
        "&:hover": { backgroundColor: green.green5 },
        "&:focus": { boxShadow: `0 0 0 2px ${green.green7}` },
      },
    },
  },

  defaultVariants: {
    variant: "violet",
  },
});

const IconButton = styled("button", {
  all: "unset",
  fontFamily: "inherit",
  borderRadius: "100%",
  height: 25,
  width: 25,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  color: violet.violet11,
  position: "absolute",
  top: 10,
  right: 10,

  "&:hover": { backgroundColor: violet.violet4 },
  "&:focus": { boxShadow: `0 0 0 2px ${violet.violet7}` },
});

const Fieldset = styled("fieldset", {
  all: "unset",
  display: "flex",
  gap: 20,
  alignItems: "center",
  marginBottom: 15,
  justifyContent: "center",
});

const Label = styled("label", {
  fontSize: 15,
  color: violet.violet11,
  width: "fit-content",
  textAlign: "left",
});

const Input = styled("input", {
  all: "unset",
  width: "100%",
  flex: "1",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 4,
  padding: "0 10px",
  fontSize: 15,
  lineHeight: 1,
  color: violet.violet11,
  boxShadow: `0 0 0 1px ${violet.violet7}`,
  height: 35,

  "&:focus": { boxShadow: `0 0 0 2px ${violet.violet8}` },
});

const addAnUser = async (
  userEmail,
  organizationName,
  organisation_id,
  setIsLoading,
  setOpenModal
) => {
  console.log("add user");
  setIsLoading(true);
  try {
    const req = await axios.post(
      `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/add_user_to_org`,
      {
        organisation_id,
        userEmail,
        organizationName,
      },
      {
        withCredentials: true,
      }
    );
    console.log(req);
    if (req.data.status === "Mail send successfully") {
      toast.success("An Email has been send to user");
      setIsLoading(false);
      setOpenModal(false);
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
    setIsLoading(false);
  }
};

const AddUser = () => {
  const [adminData, setAdminData] = useAtom(adminDataAtom);
  const [user, setUser] = useAtom(userAtom);
  const [isLoading, setIsLoading] = useState(null);
  const [value, setValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  return (
    <Dialog open={openModal}>
      <DialogTrigger asChild onClick={() => setOpenModal(true)}>
        <div className="border border-white p-4 h-1/10 flex items-center justify-center rounded-full absolute bottom-16 text-white hover:text-black hover:transition hover:duration-500 hover:ease-in-out hover:-translate-y-2 hover:scale-105 active:translate-y-3 hover:bg-my_purple">
          <div>
            <FaUserPlus className="text-2xl" />
          </div>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Add an user</DialogTitle>
        <DialogDescription>
          Enter email address to add a new user to your organisation.
        </DialogDescription>
        <Fieldset>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            placeholder="demo@organisation.com"
            onChange={(e) => setValue(e.target.value)}
          />
        </Fieldset>
        <Flex css={{ marginTop: 25, justifyContent: "flex-end" }}>
          <Button
            variant="green"
            onClick={() =>
              addAnUser(
                value,
                adminData.name,
                adminData.id,
                setIsLoading,
                setOpenModal
              )
            }
          >
            {isLoading === true ? (
              <div className="flex justify-items-center item-center gap-3 justify-center">
                <AiOutlineLoading3Quarters className="text-xl text-black animate-spin" />
                <span className="flex items-center justify-center">Add</span>
              </div>
            ) : (
              <>Add</>
            )}
          </Button>
        </Flex>
        <DialogClose asChild onClick={() => setOpenModal(false)}>
          <IconButton>
            <Cross2Icon />
          </IconButton>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default AddUser;

import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  GeoJSON,
  CircleMarker,
} from "react-leaflet";
import { useState, useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("./tower.jpg"),
  iconUrl: require("./tower.jpg"),
  shadowUrl: require("./tower.jpg"),
});

const tilesci2 = {
  type: "FeatureCollection",
  name: "tower",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-196",
        "Long DD": 80.5025,
        "Lat DD": 20.84811,
      },
      geometry: { type: "Point", coordinates: [80.5025, 20.84811] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-197",
        "Long DD": 80.49936,
        "Lat DD": 20.84589,
      },
      geometry: { type: "Point", coordinates: [80.49936, 20.84589] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-198",
        "Long DD": 80.49614,
        "Lat DD": 20.84361,
      },
      geometry: { type: "Point", coordinates: [80.49614, 20.84361] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-199",
        "Long DD": 80.49286,
        "Lat DD": 20.84131,
      },
      geometry: { type: "Point", coordinates: [80.49286, 20.84131] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-200",
        "Long DD": 80.48928,
        "Lat DD": 20.84056,
      },
      geometry: { type: "Point", coordinates: [80.48928, 20.84056] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-201",
        "Long DD": 80.48558,
        "Lat DD": 20.83981,
      },
      geometry: { type: "Point", coordinates: [80.48558, 20.83981] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-202",
        "Long DD": 80.482,
        "Lat DD": 20.83908,
      },
      geometry: { type: "Point", coordinates: [80.482, 20.83908] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-203",
        "Long DD": 80.47875,
        "Lat DD": 20.83842,
      },
      geometry: { type: "Point", coordinates: [80.47875, 20.83842] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-204",
        "Long DD": 80.4755,
        "Lat DD": 20.83778,
      },
      geometry: { type: "Point", coordinates: [80.4755, 20.83778] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-205",
        "Long DD": 80.47153,
        "Lat DD": 20.83697,
      },
      geometry: { type: "Point", coordinates: [80.47153, 20.83697] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-206",
        "Long DD": 80.46794,
        "Lat DD": 20.83622,
      },
      geometry: { type: "Point", coordinates: [80.46794, 20.83622] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-207",
        "Long DD": 80.46433,
        "Lat DD": 20.8355,
      },
      geometry: { type: "Point", coordinates: [80.46433, 20.8355] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-208",
        "Long DD": 80.46058,
        "Lat DD": 20.83472,
      },
      geometry: { type: "Point", coordinates: [80.46058, 20.83472] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-209",
        "Long DD": 80.45767,
        "Lat DD": 20.82906,
      },
      geometry: { type: "Point", coordinates: [80.45767, 20.82906] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-210",
        "Long DD": 80.45533,
        "Lat DD": 20.82592,
      },
      geometry: { type: "Point", coordinates: [80.45533, 20.82592] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-211",
        "Long DD": 80.45186,
        "Lat DD": 20.82428,
      },
      geometry: { type: "Point", coordinates: [80.45186, 20.82428] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-212",
        "Long DD": 80.44856,
        "Lat DD": 20.82269,
      },
      geometry: { type: "Point", coordinates: [80.44856, 20.82269] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-213",
        "Long DD": 80.44492,
        "Lat DD": 20.82097,
      },
      geometry: { type: "Point", coordinates: [80.44492, 20.82097] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-214",
        "Long DD": 80.4415,
        "Lat DD": 20.81933,
      },
      geometry: { type: "Point", coordinates: [80.4415, 20.81933] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-215",
        "Long DD": 80.43806,
        "Lat DD": 20.81769,
      },
      geometry: { type: "Point", coordinates: [80.43806, 20.81769] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-216",
        "Long DD": 80.43547,
        "Lat DD": 20.81569,
      },
      geometry: { type: "Point", coordinates: [80.43547, 20.81569] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-217",
        "Long DD": 80.43264,
        "Lat DD": 20.81347,
      },
      geometry: { type: "Point", coordinates: [80.43264, 20.81347] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-218",
        "Long DD": 80.42961,
        "Lat DD": 20.81111,
      },
      geometry: { type: "Point", coordinates: [80.42961, 20.81111] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-219",
        "Long DD": 80.4265,
        "Lat DD": 20.80869,
      },
      geometry: { type: "Point", coordinates: [80.4265, 20.80869] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-220",
        "Long DD": 80.42336,
        "Lat DD": 20.80625,
      },
      geometry: { type: "Point", coordinates: [80.42336, 20.80625] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-221",
        "Long DD": 80.42022,
        "Lat DD": 20.80378,
      },
      geometry: { type: "Point", coordinates: [80.42022, 20.80378] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-222",
        "Long DD": 80.41739,
        "Lat DD": 20.80089,
      },
      geometry: { type: "Point", coordinates: [80.41739, 20.80089] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-223",
        "Long DD": 80.41578,
        "Lat DD": 20.79919,
      },
      geometry: { type: "Point", coordinates: [80.41578, 20.79919] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-224",
        "Long DD": 80.41364,
        "Lat DD": 20.79406,
      },
      geometry: { type: "Point", coordinates: [80.41364, 20.79406] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-225",
        "Long DD": 80.41128,
        "Lat DD": 20.79075,
      },
      geometry: { type: "Point", coordinates: [80.41128, 20.79075] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-226",
        "Long DD": 80.41069,
        "Lat DD": 20.79128,
      },
      geometry: { type: "Point", coordinates: [80.41069, 20.79128] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-227",
        "Long DD": 80.40711,
        "Lat DD": 20.78461,
      },
      geometry: { type: "Point", coordinates: [80.40711, 20.78461] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-228",
        "Long DD": 80.40447,
        "Lat DD": 20.78269,
      },
      geometry: { type: "Point", coordinates: [80.40447, 20.78269] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-229",
        "Long DD": 80.40286,
        "Lat DD": 20.77978,
      },
      geometry: { type: "Point", coordinates: [80.40286, 20.77978] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-230",
        "Long DD": 80.39986,
        "Lat DD": 20.77794,
      },
      geometry: { type: "Point", coordinates: [80.39986, 20.77794] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-231",
        "Long DD": 80.39678,
        "Lat DD": 20.77606,
      },
      geometry: { type: "Point", coordinates: [80.39678, 20.77606] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-232",
        "Long DD": 80.39347,
        "Lat DD": 20.77406,
      },
      geometry: { type: "Point", coordinates: [80.39347, 20.77406] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-233",
        "Long DD": 80.39039,
        "Lat DD": 20.77217,
      },
      geometry: { type: "Point", coordinates: [80.39039, 20.77217] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-234",
        "Long DD": 80.38703,
        "Lat DD": 20.77014,
      },
      geometry: { type: "Point", coordinates: [80.38703, 20.77014] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-235",
        "Long DD": 80.38369,
        "Lat DD": 20.76811,
      },
      geometry: { type: "Point", coordinates: [80.38369, 20.76811] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-236",
        "Long DD": 80.38028,
        "Lat DD": 20.76606,
      },
      geometry: { type: "Point", coordinates: [80.38028, 20.76606] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-237",
        "Long DD": 80.37697,
        "Lat DD": 20.76403,
      },
      geometry: { type: "Point", coordinates: [80.37697, 20.76403] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-238",
        "Long DD": 80.37356,
        "Lat DD": 20.76194,
      },
      geometry: { type: "Point", coordinates: [80.37356, 20.76194] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-239",
        "Long DD": 80.37186,
        "Lat DD": 20.76092,
      },
      geometry: { type: "Point", coordinates: [80.37186, 20.76092] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-240",
        "Long DD": 80.36797,
        "Lat DD": 20.76167,
      },
      geometry: { type: "Point", coordinates: [80.36797, 20.76167] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-241",
        "Long DD": 80.36428,
        "Lat DD": 20.76236,
      },
      geometry: { type: "Point", coordinates: [80.36428, 20.76236] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-242",
        "Long DD": 80.36028,
        "Lat DD": 20.76308,
      },
      geometry: { type: "Point", coordinates: [80.36028, 20.76308] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-243",
        "Long DD": 80.35633,
        "Lat DD": 20.76383,
      },
      geometry: { type: "Point", coordinates: [80.35633, 20.76383] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-244",
        "Long DD": 80.35244,
        "Lat DD": 20.76272,
      },
      geometry: { type: "Point", coordinates: [80.35244, 20.76272] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-245",
        "Long DD": 80.34856,
        "Lat DD": 20.76158,
      },
      geometry: { type: "Point", coordinates: [80.34856, 20.76158] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-246",
        "Long DD": 80.34481,
        "Lat DD": 20.76053,
      },
      geometry: { type: "Point", coordinates: [80.34481, 20.76053] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-247",
        "Long DD": 80.34078,
        "Lat DD": 20.75933,
      },
      geometry: { type: "Point", coordinates: [80.34078, 20.75933] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-248",
        "Long DD": 80.33725,
        "Lat DD": 20.75742,
      },
      geometry: { type: "Point", coordinates: [80.33725, 20.75742] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-249",
        "Long DD": 80.33403,
        "Lat DD": 20.75742,
      },
      geometry: { type: "Point", coordinates: [80.33403, 20.75742] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-250",
        "Long DD": 80.33189,
        "Lat DD": 20.75608,
      },
      geometry: { type: "Point", coordinates: [80.33189, 20.75608] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-251",
        "Long DD": 80.32875,
        "Lat DD": 20.75414,
      },
      geometry: { type: "Point", coordinates: [80.32875, 20.75414] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-252",
        "Long DD": 80.32539,
        "Lat DD": 20.75208,
      },
      geometry: { type: "Point", coordinates: [80.32539, 20.75208] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-253",
        "Long DD": 80.32197,
        "Lat DD": 20.74997,
      },
      geometry: { type: "Point", coordinates: [80.32197, 20.74997] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-254",
        "Long DD": 80.31858,
        "Lat DD": 20.74789,
      },
      geometry: { type: "Point", coordinates: [80.31858, 20.74789] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-255",
        "Long DD": 80.31569,
        "Lat DD": 20.74608,
      },
      geometry: { type: "Point", coordinates: [80.31569, 20.74608] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-256",
        "Long DD": 80.31342,
        "Lat DD": 20.74175,
      },
      geometry: { type: "Point", coordinates: [80.31342, 20.74175] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-257",
        "Long DD": 80.31122,
        "Lat DD": 20.73756,
      },
      geometry: { type: "Point", coordinates: [80.31122, 20.73756] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-258",
        "Long DD": 80.30961,
        "Lat DD": 20.7345,
      },
      geometry: { type: "Point", coordinates: [80.30961, 20.7345] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-259",
        "Long DD": 80.30694,
        "Lat DD": 20.72889,
      },
      geometry: { type: "Point", coordinates: [80.30694, 20.72889] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-260",
        "Long DD": 80.30511,
        "Lat DD": 20.72564,
      },
      geometry: { type: "Point", coordinates: [80.30511, 20.72564] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-261",
        "Long DD": 80.30375,
        "Lat DD": 20.72178,
      },
      geometry: { type: "Point", coordinates: [80.30375, 20.72178] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-262",
        "Long DD": 80.30219,
        "Lat DD": 20.71933,
      },
      geometry: { type: "Point", coordinates: [80.30219, 20.71933] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-263",
        "Long DD": 80.30047,
        "Lat DD": 20.71706,
      },
      geometry: { type: "Point", coordinates: [80.30047, 20.71706] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-264",
        "Long DD": 80.29714,
        "Lat DD": 20.71486,
      },
      geometry: { type: "Point", coordinates: [80.29714, 20.71486] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-265",
        "Long DD": 80.29397,
        "Lat DD": 20.71278,
      },
      geometry: { type: "Point", coordinates: [80.29397, 20.71278] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-266",
        "Long DD": 80.29072,
        "Lat DD": 20.71064,
      },
      geometry: { type: "Point", coordinates: [80.29072, 20.71064] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-267",
        "Long DD": 80.28747,
        "Lat DD": 20.7085,
      },
      geometry: { type: "Point", coordinates: [80.28747, 20.7085] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-268",
        "Long DD": 80.28422,
        "Lat DD": 20.70633,
      },
      geometry: { type: "Point", coordinates: [80.28422, 20.70633] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-269",
        "Long DD": 80.281,
        "Lat DD": 20.70422,
      },
      geometry: { type: "Point", coordinates: [80.281, 20.70422] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-270",
        "Long DD": 80.278,
        "Lat DD": 20.70222,
      },
      geometry: { type: "Point", coordinates: [80.278, 20.70222] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-271",
        "Long DD": 80.27525,
        "Lat DD": 20.70042,
      },
      geometry: { type: "Point", coordinates: [80.27525, 20.70042] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-272",
        "Long DD": 80.27214,
        "Lat DD": 20.69836,
      },
      geometry: { type: "Point", coordinates: [80.27214, 20.69836] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-273",
        "Long DD": 80.26892,
        "Lat DD": 20.69625,
      },
      geometry: { type: "Point", coordinates: [80.26892, 20.69625] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-274",
        "Long DD": 80.26525,
        "Lat DD": 20.69458,
      },
      geometry: { type: "Point", coordinates: [80.26525, 20.69458] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-275",
        "Long DD": 80.26158,
        "Lat DD": 20.69292,
      },
      geometry: { type: "Point", coordinates: [80.26158, 20.69292] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-315",
        "Long DD": 80.12014,
        "Lat DD": 20.65814,
      },
      geometry: { type: "Point", coordinates: [80.12014, 20.65814] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-316",
        "Long DD": 80.11647,
        "Lat DD": 20.65828,
      },
      geometry: { type: "Point", coordinates: [80.11647, 20.65828] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-317",
        "Long DD": 80.11286,
        "Lat DD": 20.65839,
      },
      geometry: { type: "Point", coordinates: [80.11286, 20.65839] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-318",
        "Long DD": 80.10964,
        "Lat DD": 20.65808,
      },
      geometry: { type: "Point", coordinates: [80.10964, 20.65808] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-319",
        "Long DD": 80.10647,
        "Lat DD": 20.65778,
      },
      geometry: { type: "Point", coordinates: [80.10647, 20.65778] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-320",
        "Long DD": 80.10272,
        "Lat DD": 20.65742,
      },
      geometry: { type: "Point", coordinates: [80.10272, 20.65742] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-321",
        "Long DD": 80.09883,
        "Lat DD": 20.65706,
      },
      geometry: { type: "Point", coordinates: [80.09883, 20.65706] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-322",
        "Long DD": 80.09586,
        "Lat DD": 20.65678,
      },
      geometry: { type: "Point", coordinates: [80.09586, 20.65678] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-323",
        "Long DD": 80.09208,
        "Lat DD": 20.65736,
      },
      geometry: { type: "Point", coordinates: [80.09208, 20.65736] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-324",
        "Long DD": 80.08806,
        "Lat DD": 20.658,
      },
      geometry: { type: "Point", coordinates: [80.08806, 20.658] },
    },
    {
      type: "Feature",
      properties: {
        "Tower Numb": "TN-325",
        "Long DD": 80.08431,
        "Lat DD": 20.65861,
      },
      geometry: { type: "Point", coordinates: [80.08431, 20.65861] },
    },
  ],
};

const tilesci3 = {
  type: "FeatureCollection",
  name: "pl",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { id: 1 },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [80.084313561943347, 20.658609823649513],
            [80.088056131039068, 20.658000337497718],
            [80.092087044752489, 20.657347779583016],
            [80.095860103888967, 20.656779684424706],
            [80.09883198478127, 20.657058134350009],
            [80.102722709615975, 20.657422048158981],
            [80.106472725392635, 20.657781005926452],
            [80.109640306675516, 20.658074774927854],
            [80.112858366610013, 20.658389446742497],
            [80.116468163303963, 20.65828376364329],
            [80.120137530299772, 20.658145186897141],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { id: 2 },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [80.261578458879953, 20.692920585246441],
            [80.265248758003338, 20.694582049687931],
            [80.26891136985806, 20.69625789614474],
            [80.272148534684831, 20.698358321835126],
            [80.275258317067937, 20.700431240489113],
            [80.278008711196321, 20.702221056983248],
            [80.281009031089354, 20.70421267977818],
            [80.284215636788744, 20.706333459242533],
            [80.28747271801366, 20.708500968183266],
            [80.290721260791472, 20.710632154294704],
            [80.293969765987697, 20.712791227866205],
            [80.297148367430069, 20.714860939531274],
            [80.30047169363192, 20.717057462227409],
            [80.302186322373458, 20.719330214679449],
            [80.30374726286604, 20.721780880551531],
            [80.305113678054866, 20.725643115564559],
            [80.30694285548698, 20.728891331273722],
            [80.30960198773883, 20.734503880268509],
            [80.311212172852564, 20.737559683099633],
            [80.313415877797425, 20.741750219936197],
            [80.315685070173643, 20.746085901320242],
            [80.318581940505041, 20.74789223610156],
            [80.321964077438182, 20.749962313345559],
            [80.325394458484396, 20.752092549770428],
            [80.328750502126823, 20.754133412182004],
            [80.331897980026483, 20.756077137487871],
            [80.334026983656983, 20.757412375152356],
            [80.337242464129943, 20.757419629518122],
            [80.340764417236471, 20.759331516916134],
            [80.344808960028445, 20.760530084579834],
            [80.348559265640304, 20.761582531399192],
            [80.352450115453095, 20.762722374179713],
            [80.356343367208808, 20.763829016301255],
            [80.360286377025844, 20.763063287467201],
            [80.364273074199488, 20.762368014663259],
            [80.367973650162554, 20.761663315662378],
            [80.371859217394416, 20.76092401947492],
            [80.373567385063581, 20.761938430112263],
            [80.376970269888673, 20.764043749294853],
            [80.380276844718225, 20.766041006097101],
            [80.383697726299218, 20.768096575717124],
            [80.387039496544929, 20.77014351573137],
            [80.39039029517366, 20.772157301372719],
            [80.393477486338014, 20.774060372947421],
            [80.396788895469982, 20.7760594061149],
            [80.399854366952312, 20.777929166401425],
            [80.402867199604799, 20.779769707586706],
            [80.40447144421799, 20.782694231519567],
            [80.407118707678961, 20.784612098567994],
            [80.41128181602231, 20.79074794590495],
            [80.410689759647326, 20.791283643096833],
            [80.413647659169854, 20.794054874751545],
            [80.415776496089848, 20.799193072932354],
            [80.417385918392156, 20.800885740618835],
            [80.420223632043829, 20.803789462887941],
            [80.423380146935003, 20.806257403718853],
            [80.426501695331552, 20.808683767328795],
            [80.429610157390329, 20.81110382137517],
            [80.435473843100397, 20.815689004719449],
            [80.438062286466888, 20.817698335798458],
            [80.44150649618463, 20.819331575479588],
            [80.444919933127508, 20.820970858004767],
            [80.44855774789707, 20.822689462476429],
            [80.451854808322494, 20.824281655676547],
            [80.455332324056997, 20.825921974187818],
            [80.457668341028452, 20.829061567533298],
            [80.46057903188121, 20.834716140476871],
            [80.464328573432013, 20.835498011283004],
            [80.467946235774974, 20.836221426074861],
            [80.471533018791902, 20.836969512014193],
            [80.475511608787485, 20.837776688503329],
            [80.478750880741771, 20.838424191420458],
            [80.482007777383743, 20.839077897384552],
            [80.485572711230233, 20.839817344791385],
            [80.489282987101859, 20.840567502864385],
            [80.492861155772545, 20.841322365578975],
            [80.496139167354954, 20.843610281134101],
            [80.49935561340132, 20.845898990220107],
            [80.502506335009642, 20.848112922272154],
          ],
        ],
      },
    },
  ],
};
const high10 = {
  type: "FeatureCollection",
  name: "pl",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        stroke: "#ff0000",
        "stroke-width": 2,
        "stroke-opacity": 1,
        fill: "#ff0000",
        "fill-opacity": 0.5,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [80.460990932202094, 20.834665739304935],
              [80.461081245098597, 20.83466602337797],
              [80.461081547241676, 20.834581119081168],
              [80.461171860089081, 20.834581403105346],
              [80.461172162180006, 20.834496498806441],
              [80.461081849383248, 20.834496214783524],
              [80.461082151523271, 20.834411310485041],
              [80.460991838778668, 20.834411026415797],
              [80.460990932202094, 20.834665739304935],
            ],
          ],
        ],
      },
    },
  ],
};
const high11 = {
  type: "Feature",
  properties: {
    stroke: "#ff0000",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#ff0000",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.459909599929645, 20.833983092449394],
          [80.46009022487776, 20.83398366166994],
          [80.460090527565583, 20.833898757380283],
          [80.459909902718735, 20.833898188162269],
          [80.459909599929645, 20.833983092449394],
        ],
      ],
    ],
  },
};

const high17 = {
  type: "Feature",
  properties: {
    stroke: "#ff0000",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#ff0000",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.426769784644577, 20.8087435518284],
          [80.426860081568236, 20.808743853579603],
          [80.426860402459738, 20.808658949520957],
          [80.426770105586641, 20.808658647771097],
          [80.426769784644577, 20.8087435518284],
        ],
      ],
    ],
  },
};
const high22 = {
  type: "Feature",
  properties: {
    stroke: "#ff0000",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#ff0000",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.372334924335973, 20.761515773670855],
          [80.372425192274875, 20.761516103363093],
          [80.372425542771168, 20.76143119968739],
          [80.372335274882673, 20.761430869996619],
          [80.372334924335973, 20.761515773670855],
        ],
      ],
    ],
  },
};

const medium9 = {
  type: "Feature",
  properties: {
    stroke: "#FFAC33",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#FFAC33",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.46081030641362, 20.834665171016077],
          [80.460900619307097, 20.834665455184307],
          [80.460900921551442, 20.834580550890035],
          [80.460810608708584, 20.834580266723066],
          [80.46081030641362, 20.834665171016077],
        ],
      ],
    ],
  },
};
const medium15 = {
  type: "Feature",
  properties: {
    stroke: "#FFAC33",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#FFAC33",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.436312226975275, 20.816586455589974],
          [80.436402528719853, 20.816586752424353],
          [80.43640347575213, 20.816332040055649],
          [80.436313174159253, 20.816331743225224],
          [80.436312226975275, 20.816586455589974],
        ],
      ],
    ],
  },
};

const medium25 = {
  type: "Feature",
  properties: {
    stroke: "#FFAC33",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#FFAC33",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.296814964025344, 20.714865637503891],
          [80.296995441807084, 20.714866374596653],
          [80.296995833465004, 20.714781471765484],
          [80.296815355783806, 20.714780734676008],
          [80.296814964025344, 20.714865637503891],
        ],
      ],
    ],
  },
};

const low8 = {
  type: "Feature",
  properties: {
    stroke: "#fff",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#fff",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.46180344658562, 20.834753198554832],
          [80.461893759546399, 20.83475348220076],
          [80.461894061235327, 20.834668577893456],
          [80.461803748325195, 20.834668294248786],
          [80.46180344658562, 20.834753198554832],
        ],
      ],
    ],
  },
};

const low13 = {
  type: "Feature",
  properties: {
    stroke: "#fff",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#fff",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.443614206788439, 20.820006513498075],
          [80.443704510683645, 20.820006806532977],
          [80.443704822328783, 20.819921902338461],
          [80.443614518484168, 20.81992160930486],
          [80.443614206788439, 20.820006513498075],
        ],
      ],
    ],
  },
};

const low16 = {
  type: "Feature",
  properties: {
    stroke: "#fff",
    "stroke-width": 2,
    "stroke-opacity": 1,
    fill: "#fff",
    "fill-opacity": 0.5,
  },
  geometry: {
    type: "MultiPolygon",
    coordinates: [
      [
        [
          [80.435593922695972, 20.815480325705011],
          [80.435774524846522, 20.815480920052671],
          [80.435774840858926, 20.815396015928879],
          [80.435594238809514, 20.815395421583865],
          [80.435593922695972, 20.815480325705011],
        ],
      ],
    ],
  },
};

const enchroch1 = {
  type: "Polygon",
  coordinates: [
    [
      [80.47290450539539, 20.837468970968253],
      [80.47299996070063, 20.837469255256323],
      [80.47299962130418, 20.837563406460543],
      [80.47309499165105, 20.837563685972587],
      [80.473094737104, 20.837653430523982],
      [80.47290382660172, 20.837652861933105],
      [80.47290450539539, 20.837468970968253],
    ],
  ],
};

const enchroch2 = {
  type: "Polygon",
  coordinates: [
    [
      [80.4731961316145, 20.837290419584804],
      [80.47329158658444, 20.837290703648364],
      [80.47329192597968, 20.837201053847306],
      [80.4734827507409, 20.83720161701913],
      [80.47348241134642, 20.83729135688622],
      [80.4733869565959, 20.837291072945494],
      [80.47338670204975, 20.837380727505284],
      [80.47319587706777, 20.837380164136384],
      [80.4731961316145, 20.837290419584804],
    ],
  ],
};

const enchroch3 = {
  type: "Polygon",
  coordinates: [
    [
      [80.4542089331878, 20.82512574042229],
      [80.45439971690931, 20.825126318484674],
      [80.45439937743689, 20.825216053770987],
      [80.4542085937146, 20.825215475671357],
      [80.4542089331878, 20.82512574042229],
    ],
  ],
};

const enchroch4 = {
  type: "Polygon",
  coordinates: [
    [
      [80.42390866153158, 20.807045865566014],
      [80.42409942976943, 20.807046560470663],
      [80.42409909017348, 20.807136198506015],
      [80.42390832193487, 20.807135593581123],
      [80.42390866153158, 20.807045865566014],
    ],
  ],
};

const enchroch5 = {
  type: "Polygon",
  coordinates: [
    [
      [80.41300332857467, 20.793813761037637],
      [80.4130987676674, 20.79381407018265],
      [80.4130984280267, 20.793908295801963],
      [80.41300298893358, 20.79390798663731],
      [80.41300332857467, 20.793813761037637],
    ],
  ],
};

const enchroch6 = {
  type: "Polygon",
  coordinates: [
    [
      [80.40871151344301, 20.786299996014197],
      [80.40880687252553, 20.78630030161039],
      [80.40880653286737, 20.78639002512587],
      [80.40871108886982, 20.78638971475019],
      [80.40871151344301, 20.786299996014197],
    ],
  ],
};

const enchroch7 = {
  type: "Polygon",
  coordinates: [
    [
      [80.40698908719789, 20.7840380868881],
      [80.40708444824641, 20.78403839312521],
      [80.40708410858124, 20.784128116132766],
      [80.40698874753234, 20.784127719864866],
      [80.40698908719789, 20.7840380868881],
    ],
  ],
};

const enchroch8 = {
  type: "Polygon",
  coordinates: [
    [
      [80.40430882828062, 20.781588890174206],
      [80.40440427730685, 20.781589202391416],
      [80.40440385271178, 20.781678919926296],
      [80.40430848860417, 20.78167861245266],
      [80.40430882828062, 20.781588890174206],
    ],
  ],
};

const enchroch9 = {
  type: "Polygon",
  coordinates: [
    [
      [80.39568527239229, 20.775867135825035],
      [80.39578064633592, 20.77586753765139],
      [80.39578030662483, 20.77595716781364],
      [80.3956849326808, 20.775956855977803],
      [80.39568527239229, 20.775867135825035],
    ],
  ],
};

const enchroch10 = {
  type: "Polygon",
  coordinates: [
    [
      [80.38255558269053, 20.76786844630051],
      [80.38265088665558, 20.767868760131662],
      [80.38265054689118, 20.767958477113215],
      [80.38255515798457, 20.767958068485203],
      [80.38255558269053, 20.76786844630051],
    ],
  ],
};

const enchroch11 = {
  type: "Polygon",
  coordinates: [
    [
      [80.35740343998957, 20.763887622647317],
      [80.35759418996, 20.763888375695718],
      [80.35759376512756, 20.76398249269815],
      [80.35740301515618, 20.763981829611023],
      [80.35740343998957, 20.763887622647317],
    ],
  ],
};

const enchroch12 = {
  type: "Polygon",
  coordinates: [
    [
      [80.37211362070853, 20.760600610381026],
      [80.37220893655065, 20.760600929414554],
      [80.37220859674393, 20.760690643782674],
      [80.37211328090142, 20.760690324730366],
      [80.37211362070853, 20.760600610381026],
    ],
  ],
};

const enchroch13 = {
  type: "Polygon",
  coordinates: [
    [
      [80.34214144382224, 20.760125215923466],
      [80.34224155268986, 20.76012555253813],
      [80.34224112777981, 20.76021975605015],
      [80.34214101891168, 20.76021941941466],
      [80.34214144382224, 20.760125215923466],
    ],
  ],
};

const enchroch14 = {
  type: "Polygon",
  coordinates: [
    [
      [80.29614650875489, 20.714585763908783],
      [80.29634198906848, 20.714586564930052],
      [80.29634113878537, 20.714770446026417],
      [80.2961456584698, 20.71476964492531],
      [80.29614650875489, 20.714585763908783],
    ],
  ],
};

const enchroch15 = {
  type: "Polygon",
  coordinates: [
    [
      [80.2958609825425, 20.714494922453053],
      [80.29595629976838, 20.71449527604607],
      [80.29595587462487, 20.714584966883056],
      [80.29586055739851, 20.71458461327105],
      [80.2958609825425, 20.714494922453053],
    ],
  ],
};

const showBurnt = {
  high: [
    [20.8087435518284, 80.426769784644577],
    [20.833983092449394, 80.459909599929645],
    [20.761515773670855, 80.372334924335973],
    [20.834665739304935, 80.460990932202094],
  ],
  medium: [
    [20.834665171016077, 80.46081030641362],
    [20.816586455589974, 80.436312226975275],
    [20.714865637503891, 80.296814964025344],
  ],
  low: [
    [20.834753198554832, 80.46180344658562],
    [20.820006513498075, 80.443614206788439],
    [20.815480325705011, 80.435593922695972],
  ],
  enchrolcoords: [
    [20.83746897, 80.47290451],
    [20.83729042, 80.47319613],
    [20.82512574, 80.45420893],
    [20.80704587, 80.42390866],
    [20.79381376, 80.41300333],
    [20.7863, 80.40871151],
    [20.78403809, 80.40698909],
    [20.78158889, 80.40430883],
    [20.77586714, 80.39568527],
    [20.76786845, 80.38255558],
    [20.76388762, 80.35740344],
    [20.76060061, 80.37211362],
    [20.76012522, 80.34214144],
    [20.71458576, 80.29614651],
    [20.71449492, 80.29586098],
  ],
};

const enchrolcoords = [
  [20.83746897, 80.47290451],
  [20.83729042, 80.47319613],
  [20.82512574, 80.45420893],
  [20.80704587, 80.42390866],
  [20.79381376, 80.41300333],
  [20.7863, 80.40871151],
  [20.78403809, 80.40698909],
  [20.78158889, 80.40430883],
  [20.77586714, 80.39568527],
  [20.76786845, 80.38255558],
  [20.76388762, 80.35740344],
  [20.76060061, 80.37211362],
  [20.76012522, 80.34214144],
  [20.71458576, 80.29614651],
  [20.71449492, 80.29586098],
];

let myQueue = [];
let switchedQueue = [];

const AdaniTransmissions = () => {
  const [centerCoord, setCenterCoord] = useState(
    switchedQueue[0] || [20.8087435518284, 80.426769784644577]
  );
  const [coords, setCoords] = useState({});
  const [current, setCurrent] = useState(0);
  const [burntType, setBurntType] = useState([]);
  //   const [enchrochment, setEnchrochmentType] = useState(false);
  const navigate = useNavigate();
  //   const [map, setMap] = useState(null);

  function ChangeView({ center }) {
    const map = useMap();
    map.flyTo(center, 18, {
      animate: true,
    });
    return null;
  }

  //   useEffect(() => {
  //     if (!map) return;

  //     map.addEventListener("mousemove", (e) => {
  //       setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
  //     });
  //   }, [map]);
  function setViewOnClickNext() {
    if (switchedQueue.length !== 0) {
      if (current + 1 === switchedQueue.length) {
        setCurrent(0);
        setCenterCoord(switchedQueue[0]);
      } else {
        setCenterCoord(switchedQueue[current + 1]);
        setCurrent(current + 1);
      }
    } else {
      toast.error("Please check one checkbox to proceed");
    }
  }
  function setViewOnClickPrevious() {
    if (switchedQueue.length !== 0) {
      if (current - 1 > 0) {
        console.log("first");
        setCenterCoord(switchedQueue[current - 1]);
        setCurrent(current - 1);
      } else {
        console.log("second");
        setCurrent(0);
        setCenterCoord(
          switchedQueue[0] || [20.8087435518284, 80.426769784644577]
        );
      }
    }
  }
  let tempBurntType;
  let temp = [];
  let typeOut;
  function burntTypeFnc(type) {
    typeOut = type;
    if (burntType.includes(type)) {
      tempBurntType = burntType.filter((item) => item !== type);
      setBurntType(tempBurntType);
      setCurrent(0);
      setCenterCoord(
        switchedQueue[0] || [20.8087435518284, 80.426769784644577]
      );
      console.log(tempBurntType, "left");
    } else {
      if (burntType.length === 0) {
        setBurntType([type]);
        temp = [];
        temp.unshift(showBurnt[type]);
        myQueue = temp;
      } else {
        tempBurntType = [...burntType, type];
        setBurntType([...burntType, type]);

        // check burntType and if it's element is same as key in showBurnt then add it's element to showBurnt else remove it's element from showBurnt
        tempBurntType.map((ele) => temp.push(showBurnt[ele]));

        myQueue = temp;
      }
    }
  }

  useEffect(() => {
    switchedQueue = myQueue.flat();
    if (burntType.length === 0) {
      switchedQueue = [];
    }
    console.log(switchedQueue, "switchedQueue");
  }, [burntType]);

  //   const { lat, lng } = coords;

  //   console.log(lat, lng);

  //   useEffect(() => {
  //     if ([lng, lat] === showBurnt.high[0]) {
  //       console.log("---------------------");
  //     }
  //   }, [lat, lng]);

  return (
    <div className="w-screen h-screen p-10 pb-20 relative">
      <div
        className="absolute top-2 left-5 flex gap-5 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img src="./logo/logo.png" alt="logo" className="w-24 h-14" />
      </div>
      <div className="w-screen text-2xl text-white uppercase text-center absolute top-5 right-5">
        transmission line - predective monitoring
      </div>
      <div
        className="absolute top-2 right-5 flex gap-5 cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img src="./img/adani.png" alt="logo" className="w-24 h-14" />
      </div>
      <MapContainer
        center={centerCoord}
        zoom={19}
        minZoom={11}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%", marginTop: "40px" }}
        className="z-10"
        // whenCreated={setMap}
        bounds={[
          [20.630589962546409, 80.068388965007003],
          [20.85272133921892, 80.511170291030055],
        ]}
      >
        <ChangeView center={centerCoord} zoom={19} />
        <TileLayer url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA"></TileLayer>
        {burntType.includes("high") && (
          <>
            <GeoJSON data={high11} color="red" />

            <GeoJSON data={high10} color="red" />

            <GeoJSON data={high22} color="red" />

            <GeoJSON data={high17} color="red" />
          </>
        )}
        {burntType.includes("medium") && (
          <>
            <GeoJSON data={medium9} color="yellow" />
            <GeoJSON data={medium15} color="yellow" />
            <GeoJSON data={medium25} color="yellow" />
          </>
        )}
        {burntType.includes("low") && (
          <>
            <GeoJSON data={low13} color="white" />
            <GeoJSON data={low8} color="white" />
            <GeoJSON data={low16} color="white" />
          </>
        )}

        {burntType.includes("enchrolcoords") && (
          <>
            <>
              <GeoJSON data={enchroch1} color="green" />
              <GeoJSON data={enchroch2} color="green" />
              <GeoJSON data={enchroch3} color="green" />
              <GeoJSON data={enchroch4} color="green" />
              <GeoJSON data={enchroch5} color="green" />
              <GeoJSON data={enchroch6} color="green" />
              <GeoJSON data={enchroch7} color="green" />
              <GeoJSON data={enchroch8} color="green" />
              <GeoJSON data={enchroch9} color="green" />
              <GeoJSON data={enchroch10} color="green" />
              <GeoJSON data={enchroch11} color="green" />
              <GeoJSON data={enchroch12} color="green" />
              <GeoJSON data={enchroch13} color="green" />
              <GeoJSON data={enchroch14} color="green" />
              <GeoJSON data={enchroch15} color="green" />
            </>
          </>
        )}
        <GeoJSON data={tilesci2} />
        <GeoJSON data={tilesci3} />
      </MapContainer>
      <div className="flex gap-5">
        <button
          className={"z-50 bg-white px-6 py-2 w-full text-black"}
          onClick={setViewOnClickPrevious}
        >
          Previous Point
        </button>
        <button
          className={"z-50 bg-white px-6 py-2 w-full text-black"}
          onClick={setViewOnClickNext}
        >
          Next Point
        </button>
      </div>

      <div
        className={`${"flex flex-col gap-5 absolute bottom-40 right-14 z-20 bg-background px-3 py-3  text-white items-start justify-start rounded-md"}`}
      >
        <div className="w-full text-center uppercase">Burning of crops</div>
        <div className="flex gap-3 items-center justify-center w-full">
          <div className="flex items-center justify-between w-full gap-5">
            <div className="flex gap-3 items-center justify-center">
              <input
                type="checkbox"
                id="sar"
                checked={burntType.includes("high") ? true : false}
                onChange={() => burntTypeFnc("high")}
                className="accent-red-600 cursor-pointer"
              />

              <label htmlFor="sar" className="text-red-600">
                High
              </label>
            </div>
          </div>

          <div className="flex items-center justify-between w-full gap-5">
            <div className="flex gap-3 items-center justify-center">
              <input
                type="checkbox"
                id="optical"
                checked={burntType.includes("medium") ? true : false}
                onChange={() => burntTypeFnc("medium")}
                className="accent-yellow-600 cursor-pointer"
              />

              <label htmlFor="sar" className="text-yellow-600">
                Medium
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between w-full gap-5">
            <div className="flex gap-3 items-center justify-center">
              <input
                type="checkbox"
                id="optical"
                checked={burntType.includes("low") ? true : false}
                onChange={() => burntTypeFnc("low")}
                className="accent-white cursor-pointer"
              />

              <label htmlFor="sar" className="text-white">
                Low
              </label>
            </div>
          </div>
        </div>
        <div className="flex h-full w-full items-center justify-center">
          <button
            className="rounded bg-white text-black px-3 p-2"
            onClick={() =>
              window.open(
                "https://adani-files.s3.ap-south-1.amazonaws.com/Burning+of+Crops.xlsx",
                "_blank"
              )
            }
          >
            Download
          </button>
        </div>
      </div>

      <div
        className={`${"flex flex-col gap-5 absolute top-40 right-14 z-20 bg-background px-3 py-3  text-white items-start justify-start rounded-md"}`}
      >
        <div className="w-full text-center uppercase">Encroachment</div>

        <div className="flex flex-col items-center justify-center gap-3 w-full">
          <div className="flex gap-3 items-center justify-center w-full">
            <input
              type="checkbox"
              id="optical"
              checked={burntType.includes("enchrolcoords") ? true : false}
              onChange={() => burntTypeFnc("enchrolcoords")}
              className="accent-green-600 cursor-pointer"
            />

            <label htmlFor="sar" className="text-green-600">
              Show
            </label>
          </div>
          <div className="flex h-full w-full items-center justify-center">
            <button
              className="rounded bg-white text-black px-3 p-2"
              onClick={() =>
                window.open(
                  "https://adani-files.s3.ap-south-1.amazonaws.com/Encroachment.xlsx",
                  "_blank"
                )
              }
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdaniTransmissions;

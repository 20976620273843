import { useState, useEffect } from "react";
import { RiHome5Line } from "react-icons/ri";

import { BiBarChartSquare } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { FaHistory } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import AddUser from "./addUser";

const AdminNav = () => {
  const [selected, setSelected] = useState("home");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/admin") {
      setSelected("home");
    } else if (pathname === "/admin/charts") {
      setSelected("charts");
    } else if (pathname === "/admin/products") {
      setSelected("products");
    } else if (pathname === "/admin/users") {
      setSelected("users");
    } else if (pathname === "/admin/logs") {
      setSelected("logs");
    }
  }, [pathname]);

  return (
    <div className="h-full w-full flex flex-col items-center justify-start pt-10 gap-5">
      <div
        className="w-full h-1/10 flex items-start justify-center"
        onClick={() => navigate("/")}
      >
        <img src="./logo/main-hero.png" alt="logo" className="w-8/10 h-1/2" />
      </div>
      <div
        className={`${
          selected === "home" ? "border-my_purple border-r-4" : ""
        }  w-full flex justify-center items-center h-0.7/10 cursor-pointer`}
        onClick={() => navigate("/admin")}
      >
        <RiHome5Line className="text-2xl text-white" />
      </div>
      <div
        className={`${
          selected === "charts" ? "border-my_purple border-r-4" : ""
        }  w-full flex justify-center items-center h-0.7/10 cursor-pointer`}
        onClick={() => navigate("/admin/charts")}
      >
        <BiBarChartSquare className="text-2xl text-white" />
      </div>
      <div
        className={`${
          selected === "logs" ? "border-my_purple border-r-4" : ""
        }  w-full flex justify-center items-center h-0.7/10 cursor-pointer`}
        onClick={() => navigate("/admin/logs")}
      >
        <FaHistory className="text-2xl text-white" />
      </div>
      <div
        className={`${
          selected === "users" ? "border-my_purple border-r-4" : ""
        }  w-full flex justify-center items-center h-0.7/10 cursor-pointer`}
        onClick={() => navigate("/admin/users")}
      >
        <FiUsers className="text-2xl text-white" />
      </div>
      <div className="w-full flex justify-center items-center">
        <AddUser />
      </div>
    </div>
  );
};

export default AdminNav;

import * as React from "react";

const JoinedToOrg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={297.128}
    height={482.027}
    {...props}
  >
    <circle cx={144.92} cy={211.415} r={42.616} fill="#6c63ff" />
    <path
      d="M41.273 315.113a5.579 5.579 0 0 1-3.16-4.733l-.62-11.736a5.58 5.58 0 0 1 5.273-5.86l97.816-5.168a5.58 5.58 0 0 1 5.86 5.272l.62 11.737a5.58 5.58 0 0 1-5.272 5.86l-97.816 5.167a5.54 5.54 0 0 1-2.7-.54Z"
      fill="#6c63ff"
    />
    <path
      d="M108.704 315.343a5.59 5.59 0 0 1-3.157-5.07l-.116-22.097a5.573 5.573 0 0 1 5.62-5.532l44.624 3.934c1.181.007 2.672 1.837 3.871 3.306.376.461.7.86.938 1.1.104.106.203.216.297.328a5.564 5.564 0 0 1 1.303 3.628l-.098 11.751a5.58 5.58 0 0 1-5.62 5.528l-45.257 3.673a5.62 5.62 0 0 1-2.405-.55Z"
      fill="#2f2e41"
    />
    <path
      d="M136.612 482.027H124.86a5.58 5.58 0 0 1-5.574-5.574v-99.546a5.58 5.58 0 0 1 5.574-5.574h11.752a5.58 5.58 0 0 1 5.574 5.574v99.546a5.58 5.58 0 0 1-5.574 5.574ZM167.612 482.027H155.86a5.58 5.58 0 0 1-5.574-5.574v-99.546a5.58 5.58 0 0 1 5.574-5.574h11.752a5.58 5.58 0 0 1 5.574 5.574v99.546a5.58 5.58 0 0 1-5.574 5.574Z"
      fill="#6c63ff"
    />
    <path
      d="m150.593 227.21.044-1.592c-2.963-.083-5.575-.269-7.537-1.702a4.895 4.895 0 0 1-1.897-3.606 2.798 2.798 0 0 1 .918-2.305c1.303-1.1 3.4-.744 4.928-.044l1.318.605-2.527-18.466-1.578.216 2.15 15.708a5.622 5.622 0 0 0-5.319.765 4.356 4.356 0 0 0-1.48 3.577 6.476 6.476 0 0 0 2.547 4.836c2.522 1.843 5.8 1.934 8.433 2.008ZM127.882 201.67h8.577v1.593h-8.577zM154.955 201.67h8.577v1.593h-8.577zM193.472 353.339 154.137 274.9h.555a5.182 5.182 0 0 0 5.176-5.176v-1.593a5.182 5.182 0 0 0-5.176-5.176h-17.518a5.182 5.182 0 0 0-5.176 5.176v1.593a5.165 5.165 0 0 0 4.581 5.115l-39.448 78.542a24.5 24.5 0 0 0 21.894 35.496h52.512a24.539 24.539 0 0 0 21.935-35.54Z"
      fill="#2f2e41"
    />
    <path
      d="m146.76 292.653 88.727-41.501a5.58 5.58 0 0 1 7.41 2.687l4.98 10.645a5.58 5.58 0 0 1-2.687 7.41l-88.727 41.502a5.58 5.58 0 0 1-7.41-2.687l-4.98-10.646a5.535 5.535 0 0 1-.188-4.26 5.6 5.6 0 0 1 .83-1.516 5.538 5.538 0 0 1 2.046-1.634Z"
      fill="#6c63ff"
    />
    <path
      d="m143.718 292.397 39.063-23.284a5.58 5.58 0 0 1 7.437 2.603l9.85 19.79a5.571 5.571 0 0 1-2.635 7.452l-41.73 16.12c-1.092.525-3.237-.458-4.96-1.249-.541-.248-1.008-.462-1.327-.573a5.622 5.622 0 0 1-.412-.163 5.563 5.563 0 0 1-2.768-2.682l-5.094-10.591a5.59 5.59 0 0 1 .614-5.834 5.46 5.46 0 0 1 1.962-1.589Z"
      fill="#2f2e41"
    />
    <path
      d="M111.26 189.25a2.158 2.158 0 0 1-2.15-2.152 1.996 1.996 0 0 1 .056-.503l11.14-49.064a2.164 2.164 0 0 1 3.573-1.112l36.929 34.197a2.165 2.165 0 0 1-.822 3.651l-48.078 14.886a2.225 2.225 0 0 1-.647.097Z"
      fill="#3f3d56"
    />
    <path
      fill="#fff"
      d="m141.465 153.383-23.196-4.616.365-1.611 20.507 4.077 2.324 2.15zM159.73 170.303l-44.35-8.825.365-1.619 41.66 8.285 2.325 2.159zM143.864 178.738l-3.329 1.029-28.036-5.579.365-1.619 31 6.169z"
    />
    <circle cx={252} cy={183} r={7} fill="#6c63ff" />
    <circle cx={162} cy={5} r={5} fill="#ff6584" />
    <circle cx={53} cy={160} r={5} fill="#ff6584" />
    <circle cx={228} cy={143} r={5} fill="#6c63ff" />
    <circle cx={76} cy={211} r={5} fill="#6c63ff" />
    <circle cx={234} cy={225} r={5} fill="#e6e6e6" />
    <circle cx={190} cy={47} r={5} fill="#ccc" />
    <path
      d="M1.017 106.977a33.198 33.198 0 0 1 25.966 15.38c.786 1.257 1.812 2.638 1.268 4.185a2.751 2.751 0 0 1-2.85 1.904 3.605 3.605 0 0 1-.373-6.948c3.631-1.549 7.295.845 10.252 2.755 6.74 4.353 14.153 8.29 22.32 8.786 7.555.459 15.658-2.504 19.86-9.056.736-1.147-1.09-2.206-1.821-1.065-4.168 6.498-12.595 8.832-19.92 7.828a38.726 38.726 0 0 1-12.466-4.232c-2.013-1.045-3.966-2.204-5.88-3.421a37.522 37.522 0 0 0-5.767-3.307c-3.363-1.381-7.815-1.273-10.164 1.907-2.192 2.968-.498 7.908 3.167 8.742a4.953 4.953 0 0 0 5.802-5.892c-.365-1.748-1.681-3.458-2.7-4.886a35.31 35.31 0 0 0-26.694-14.79c-1.358-.067-1.354 2.043 0 2.11ZM296.728 170.848a26.404 26.404 0 0 1-12.07-20.747c-.06-1.178-.237-2.535.74-3.398a2.188 2.188 0 0 1 2.718-.217 2.868 2.868 0 0 1-2.435 4.97c-3.122-.333-4.738-3.415-6.052-5.888-2.993-5.636-6.615-11.243-12.095-14.754-5.07-3.247-11.845-4.33-17.304-1.409-.955.511-.097 1.955.853 1.446 5.414-2.897 12.17-1.252 16.869 2.285a30.8 30.8 0 0 1 7.016 7.77 61.482 61.482 0 0 1 2.758 4.656 29.844 29.844 0 0 0 2.724 4.532c1.8 2.263 4.934 3.914 7.798 2.616a4.606 4.606 0 0 0 1.188-7.3 3.94 3.94 0 0 0-6.313 1.844 11.492 11.492 0 0 0-.019 4.44 28.084 28.084 0 0 0 12.806 20.619c.917.573 1.733-.894.818-1.465ZM124.191 32.406a26.404 26.404 0 0 1-20.652-12.233c-.625-1-1.441-2.098-1.008-3.329a2.188 2.188 0 0 1 2.267-1.514 2.868 2.868 0 0 1 .296 5.526c-2.888 1.232-5.802-.672-8.154-2.191-5.36-3.462-11.256-6.593-17.752-6.988-6.01-.365-12.454 1.991-15.796 7.203-.585.912.867 1.754 1.449.847 3.315-5.169 10.017-7.025 15.844-6.226a30.8 30.8 0 0 1 9.913 3.366 61.48 61.48 0 0 1 4.677 2.72 29.844 29.844 0 0 0 4.587 2.63c2.675 1.1 6.216 1.013 8.084-1.516a4.606 4.606 0 0 0-2.519-6.953 3.94 3.94 0 0 0-4.615 4.687 11.492 11.492 0 0 0 2.148 3.885 28.084 28.084 0 0 0 21.231 11.764c1.08.053 1.077-1.625 0-1.678ZM123 85.571h5V102h-5z"
      fill="#ccc"
    />
    <path
      fill="#b3b3b3"
      d="m50.632 70.631-3.475 3.475-11.418-11.418 3.475-3.474zM191.215 112.107l-3.475-3.475 11.418-11.418 3.474 3.475z"
    />
    <path
      fill="#ff6584"
      d="m49.215 254.107-3.475-3.475 11.418-11.418 3.474 3.475z"
    />
  </svg>
);

export default JoinedToOrg;

import React from "react";

function OrderConfirmIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="680.839"
      height="584.232"
      data-name="Layer 1"
      viewBox="0 0 680.839 584.232"
    >
      <path
        fill="#e6e6e6"
        d="M310.706 694.028a24.215 24.215 0 0023.382-4.119c8.19-6.874 10.758-18.196 12.847-28.682l6.18-31.016-12.938 8.908c-9.305 6.407-18.818 13.019-25.26 22.298s-9.252 21.947-4.078 31.988"
        data-name="Path 438"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M312.703 733.739c-1.628-11.864-3.303-23.881-2.158-35.872 1.014-10.65 4.263-21.049 10.878-29.58a49.206 49.206 0 0112.625-11.44c1.262-.796 2.424 1.204 1.167 1.997a46.78 46.78 0 00-18.505 22.326c-4.028 10.246-4.675 21.416-3.981 32.3.42 6.582 1.31 13.121 2.206 19.653a1.198 1.198 0 01-.808 1.422 1.163 1.163 0 01-1.423-.808z"
        data-name="Path 439"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M324.424 714.702a17.825 17.825 0 0015.532 8.019c7.864-.373 14.418-5.86 20.317-11.07l17.452-15.41-11.55-.552c-8.306-.398-16.827-.771-24.738 1.793s-15.208 8.727-16.654 16.916"
        data-name="Path 442"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M308.1 740.558c7.84-13.871 16.933-29.288 33.181-34.215a37.026 37.026 0 0113.956-1.441c1.482.128 1.111 2.412-.367 2.284a34.398 34.398 0 00-22.272 5.893c-6.28 4.274-11.17 10.217-15.308 16.519-2.535 3.86-4.806 7.884-7.076 11.903-.726 1.284-2.848.357-2.114-.943z"
        data-name="Path 443"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#fff"
        d="M935.396 569.317H503.18a5.03 5.03 0 01-5.024-5.024V162.908a5.03 5.03 0 015.024-5.024h432.215a5.03 5.03 0 015.023 5.024v401.384a5.03 5.03 0 01-5.023 5.024z"
        data-name="Path 141"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#cacaca"
        d="M935.396 569.317H503.18a5.03 5.03 0 01-5.024-5.024V162.908a5.03 5.03 0 015.024-5.024h432.215a5.03 5.03 0 015.023 5.024v401.384a5.03 5.03 0 01-5.023 5.024zM503.18 159.889a3.018 3.018 0 00-3.012 3.012v401.391a3.018 3.018 0 003.012 3.012h432.215a3.017 3.017 0 003.011-3.012V162.908a3.018 3.018 0 00-3.011-3.012z"
        data-name="Path 141"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M707.41 262.185a3.41 3.41 0 000 6.821h187.143a3.41 3.41 0 000-6.82z"
        data-name="Path 142"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M707.41 282.65a3.41 3.41 0 000 6.821h95.54a3.41 3.41 0 000-6.82z"
        data-name="Path 143"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M543.841 392.705a3.41 3.41 0 000 6.82h350.894a3.41 3.41 0 000-6.82z"
        data-name="Path 142"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M543.841 413.17a3.41 3.41 0 000 6.82h259.292a3.41 3.41 0 000-6.82z"
        data-name="Path 143"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M543.841 433.172a3.41 3.41 0 000 6.82h350.894a3.41 3.41 0 000-6.82z"
        data-name="Path 142"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M543.841 453.637a3.41 3.41 0 000 6.82h259.292a3.41 3.41 0 000-6.82z"
        data-name="Path 143"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M543.841 474.172a3.41 3.41 0 000 6.82h350.894a3.41 3.41 0 000-6.82z"
        data-name="Path 142"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#e4e4e4"
        d="M543.841 494.637a3.41 3.41 0 000 6.82h259.292a3.41 3.41 0 000-6.82z"
        data-name="Path 143"
        transform="translate(-259.58 -157.884)"
      ></path>
      <path
        fill="#6c63ff"
        d="M339.839 166.944a49 49 0 1149-49 49.056 49.056 0 01-49 49z"
      ></path>
      <path
        fill="#a0616a"
        d="M191.098 352.216a12.248 12.248 0 00-14.953-11.362l-16.197-22.825-16.271 6.46 23.325 31.912a12.314 12.314 0 0024.096-4.185z"
      ></path>
      <path
        fill="#3f3d56"
        d="M159.531 350.525l-49.007-63.578 18.362-57.711c1.346-14.51 10.425-18.56 10.812-18.726l.59-.253 15.978 42.612-11.732 31.286 28.796 48.432z"
      ></path>
      <path
        fill="#a0616a"
        d="M329.727 154.536a12.248 12.248 0 00-10.172 15.787l-21.505 17.912 7.699 15.724 30.013-25.723a12.314 12.314 0 00-6.035-23.7z"
      ></path>
      <path
        fill="#3f3d56"
        d="M330.481 186.138l-59.598 53.777-58.958-13.846c-14.57-.22-19.312-8.958-19.506-9.33l-.298-.569 41.249-19.226 32.1 9.279 46.06-32.455z"
      ></path>
      <path
        fill="#a0616a"
        d="M227.248 568.437L243.261 568.436 250.878 506.672 227.245 506.673 227.248 568.437z"
      ></path>
      <path
        fill="#2f2e41"
        d="M274.143 583.304l-50.326.002v-19.444l36.206-.002a14.12 14.12 0 0114.12 14.119v5.325z"
      ></path>
      <path
        fill="#a0616a"
        d="M163.247 568.437L179.26 568.436 186.878 506.672 163.245 506.673 163.247 568.437z"
      ></path>
      <path
        fill="#2f2e41"
        d="M210.143 583.304l-50.326.002-.001-19.444 36.207-.002a14.12 14.12 0 0114.12 14.119v5.325z"
      ></path>
      <path
        fill="#2f2e41"
        d="M157.552 342.991L158.858 434.42 160.165 554.584 188.899 551.972 203.267 386.094 221.553 551.972 251.218 551.972 254.206 384.788 243.757 348.216 157.552 342.991z"
      ></path>
      <path
        fill="#3f3d56"
        d="M213.793 355.27c-31.265.002-60.044-14.15-60.433-14.343l-.322-.162-2.624-62.966c-.761-2.225-15.743-46.131-18.28-60.086-2.571-14.14 34.688-26.548 39.213-27.999l1.027-11.374 41.753-4.5 5.292 14.554 14.98 5.617a7.41 7.41 0 014.592 8.704l-8.326 33.857L251 348.584l-4.378.19c-10.493 4.739-21.817 6.495-32.828 6.495z"
      ></path>
      <circle
        cx="454.467"
        cy="294.46"
        r="30.063"
        fill="#a0616a"
        transform="rotate(-28.663 15.686 723.534)"
      ></circle>
      <path
        fill="#2f2e41"
        d="M170.536 165.677c5.73 6.103 16.37 2.827 17.116-5.51a10.072 10.072 0 00-.013-1.946c-.385-3.693-2.519-7.046-2.008-10.945a5.74 5.74 0 011.05-2.687c4.566-6.114 15.283 2.734 19.592-2.8 2.642-3.394-.464-8.737 1.564-12.53 2.676-5.006 10.601-2.536 15.572-5.278 5.53-3.05 5.2-11.535 1.559-16.696-4.44-6.294-12.224-9.652-19.91-10.136s-15.321 1.594-22.498 4.39c-8.154 3.178-16.24 7.57-21.257 14.74-6.103 8.719-6.69 20.44-3.638 30.636 1.857 6.202 8.192 13.779 12.87 18.762z"
      ></path>
      <path
        fill="#cacaca"
        d="M382 584.079H1a1 1 0 010-2h381a1 1 0 010 2z"
      ></path>
      <path
        fill="#fff"
        d="M337.01 136.451a3.488 3.488 0 01-2.382-.935l-16.157-15.007a3.5 3.5 0 014.763-5.13l13.686 12.713 27.076-27.077a3.5 3.5 0 114.95 4.95l-29.461 29.462a3.493 3.493 0 01-2.476 1.024z"
      ></path>
    </svg>
  );
}

export default OrderConfirmIcon;

import { ErrorBoundary } from "react-error-boundary";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { clearLayersAtom } from "../../atoms/index";
import { isCalendarHiddenAtom } from "../../atoms/index";
import { isNameHiddenAtom } from "../../atoms/index";
import { isBandsHiddenAtom } from "../../atoms/index";
import { rectangleDrawAtom } from "../../atoms/index";
import { areaAtom } from "../../atoms/index";
import Map from "../../components/map";
import SwitchMode from "../../components/switchMode";
import Sidebar from "../../components/sidebar";
import BandsSidebar from "../../components/bandsSidebar";
import ShowResults from "../../components/showResults";
import CheckBoxLayer from "../../components/map/checkBoxLayer";
import NameSidebar from "../../components/nameSidebar";
import { ImBin } from "react-icons/im";
import { BiShow } from "react-icons/bi";
import SidebarAccordian from "../../components/sidebarAccordian";
import ToolBar from "../../components/toolbar";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const myErrorHandler = (error, info) => {
  console.error(error, info);
};

function Console() {
  const [user, setUser] = useAtom(userAtom);
  const [isTrue, setIsTrue] = useAtom(clearLayersAtom);
  const [isRectangle, setIsRectangle] = useAtom(rectangleDrawAtom);
  const [isCalendarHide, setIsCalendarHide] = useAtom(isCalendarHiddenAtom);
  const [isBandHidden, setIsBandHidden] = useAtom(isBandsHiddenAtom);
  const [isNameHide, setIsNameHide] = useAtom(isNameHiddenAtom);
  const [area, setArea] = useAtom(areaAtom);
  const [drawPolygon, setDrawPolygon] = useState(false);
  console.log(user, "user");

  console.log(
    process.env.REACT_APP_GENERAL_SERVER,
    "process.env.REACT_APP_API_URL"
  );
  console.log(
    process.env.REACT_APP_AUTH_SERVER,
    "process.env.REACT_APP_AUTH_URL"
  );
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={myErrorHandler}
    >
      <div className="w-full h-full">
        {/* <div className="absolute top-3 left-7 w-28 h-14">
          <img src="../../../logo/main-hero.png" alt="logo" />
        </div> */}
        <Map />
        <SwitchMode />
        <SidebarAccordian />
        <ShowResults />
        <ToolBar />

        <div className={`absolute top-1/5 -left-1 z-20`}>
          <button
            className={`${
              isNameHide
                ? "-translate-x-full"
                : "translate-x-0 p-1 w-16 order-2 md:order-3 flex items-center justify-center border bg-background text-white rounded-md shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3z-20"
            } `}
            onClick={() => setIsNameHide(true)}
          >
            <BiShow className="text-2xl cursor-pointer" />
          </button>
        </div>

        <div
          className={`${
            area
              ? "translate-x-1 absolute top-9/10 right-1 z-20 p-4 order-2 md:order-3 flex items-center justify-center border bg-background text-white rounded-md shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3z-20"
              : "translate-x-full"
          } `}
          onClick={() => setIsNameHide(true)}
        >
          {area} sqKm
        </div>

        <CheckBoxLayer />
      </div>
    </ErrorBoundary>
  );
}

export default Console;

import Lottie from "react-lottie";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import toast, { Toaster } from "react-hot-toast";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { creditsAtom } from "../../atoms/index";
import * as _ from "lodash";
import Tracker from "@openreplay/tracker";

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const checkSesion = async (
  user,
  setUser,
  setIsSessionValid,
  isSessionValid,
  setCredits
) => {
  console.log(isSessionValid, "isSessionValid");
  // if (cookies !== {}) {
  console.log("check session");
  try {
    const urlEnd = `${
      Object.keys(user).length === 0 ? "get-access" : "no-access"
    }`;

    // user is empty then protected/accessToken or protected
    const req = await axios.get(
      `${process.env.REACT_APP_AUTH_SERVER}/auth/v1/protected/${urlEnd}`,
      {
        withCredentials: true,
      }
    );
    if (req.status === 200) {
      console.log("session valid");

      if (urlEnd === "get-access") {
        console.log("user is filled");
        setUser(req.data.data);
        console.log(req.data.data, "user protectedd");
        setCredits(req.data.data.credits);
        setIsSessionValid(true);
      } else {
        setIsSessionValid(true);
      }
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      toast.error("You are not authorized, Please login again!");
    }
    console.log(error);
    // console.log("protected log");

    setUser(null);
    setIsSessionValid(false);
  }
  // }
};

export default function ProtectedRoute({ children }) {
  const location = useLocation();
  const [isSessionValid, setIsSessionValid] = useState("null");
  const [user, setUser] = useAtom(userAtom);
  const [credits, setCredits] = useAtom(creditsAtom);

  useEffect(() => {
    if (isSessionValid === "null") {
      checkSesion(user, setUser, setIsSessionValid, isSessionValid, setCredits);
    }
    const tracker = new Tracker({
      projectKey: "h2i72okKhEoMPnWzG4Eb",
    });
    tracker.start();
    tracker.setUserID(user?.email);
  }, [isSessionValid, setCredits, setUser, user]);

  return isSessionValid === true ? (
    children
  ) : isSessionValid === false ? (
    <div>
      <Navigate to="/sign-in" state={{ from: location }} replace />;
    </div>
  ) : (
    <div className="w-screen h-screen flex justify-center items-center">
      <Lottie options={lottieSatellite} height={400} width={400} />
    </div>
  );
}

import React, { useRef, useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  FeatureGroup,
  Polygon,
  ZoomControl,
  LayersControl,
  GeoJSON,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { FaPause, FaPlay } from "react-icons/fa";
import Slider from "@mui/material/Slider";

const links = [
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2018h1.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2018h2.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2019h1R.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2019h2R.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2020h1.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2020h2.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2021h1.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/l2021h2.geojson",
  "https://adani-files.s3.ap-south-1.amazonaws.com/revised/Q12022.geojson",
];

const list = [
  "1st half-2018",
  "2nd half-2018",
  "1st half-2019",
  "2nd half-2019",
  "1st half-2020",
  "2nd half-2020",
  "1st half-2021",
  "2nd half-2021",
  "1st half-2022",
];

const marks = [
  {
    value: 0,
    label: "1st half-2018",
  },
  {
    value: 1,
    label: "2nd half-2018",
  },
  {
    value: 2,
    label: "1st half-2019",
  },

  {
    value: 3,
    label: "2nd half-2019",
  },
  {
    value: 4,
    label: "1st half-2020",
  },
  {
    value: 5,
    label: "2nd half-2020",
  },
  {
    value: 6,
    label: "1st half-2021",
  },
  {
    value: 7,
    label: "2nd half-2021",
  },
  {
    value: 8,
    label: "1st half-2022",
  },
];

const TataSteel = () => {
  console.log("Hello");
  const ref = useRef(null);
  const [data, setData] = useState([]);
  const [play, setPlay] = useState(false);
  const [i, setI] = useState(0);

  useEffect(() => {
    // if i < 17 then add 1 to i every 2 sec oterhwise stop the timer
    let timer;
    if (play) {
      timer = setInterval(() => {
        if (i <= 7) {
          setI(i + 1);
        } else if (i === 8) {
          setI(0);
        } else {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setI(i);
      return () => clearInterval(timer);
    }
  }, [i, play]);

  useEffect(() => {
    // make a fetch request to a url and store that response in setData

    if (data.length === 0) {
      fetch(links[0])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[1])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[2])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[3])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[4])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[5])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[6])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[7])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
      fetch(links[8])
        .then((res) => res.json())
        .then((data) => {
          setData((prevData) => [...prevData, data]);
        });
    }
  }, [data]);

  const handleChange = (event, newValue) => {
    setI(newValue);
  };

  console.log(data, "data");
  return (
    <>
      <div className="absolute top-5 left-5 bg-background w-36 flex items-center justify-center text-white z-50 p-4 roundedp-md">
        {list[i]}
      </div>
      <div className="absolute top-5 right-14 w-1/3 h-1/2 z-50">
        <img src="./tata.jpg" alt="logo" />
      </div>
      <div className="absolute bottom-0 flex justify-around items-center w-full gap-10 h-20 z-50 p-5 text-white">
        <div className="flex gap-5">
          {i > 0 ? (
            <button
              className="p-3 bg-background text-white"
              onClick={() => {
                setI(i - 1);
                play(false);
              }}
            >
              Previous
            </button>
          ) : null}
          {i !== 8 ? (
            <button
              className="p-3 bg-background text-white"
              onClick={() => {
                setI(i + 1);
                play(false);
              }}
            >
              Next
            </button>
          ) : null}
        </div>
        <button
          className="p-3 bg-background text-white"
          onClick={() => setPlay(!play)}
        >
          <div className="flex gap-3 items-center justify-center">
            {play ? (
              <FaPause className="text-white text-xl" />
            ) : (
              <FaPlay className="text-white text-xl" />
            )}
            <>{play ? "Pause" : "Play"}</>
          </div>
        </button>
        <div className="w-full text-black flex justify-center items-center px-12 bg-white">
          <Slider
            getAriaLabel={() => "Year Range"}
            value={i}
            min={0}
            max={8}
            step={1}
            marks={marks}
            onChange={handleChange}
            valueLabelDisplay="off"
            className="text-white"
          />
        </div>
      </div>

      <MapContainer
        center={[22.79, 86.269]}
        zoom={13.3}
        scrollWheelZoom={true}
        minZoom="12"
        maxZoom="16"
        className={`z-10 h-full w-full relative`}
      >
        <TileLayer
          ref={ref}
          // url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA"
          url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVzdC1rdXNoIiwiYSI6ImNrcWNrc3M0eDBsNjIycG5tNmllbWVrZ2oifQ.ZwkoOmSGWjAfsxjGJ8fzdA"
        />
        {data[0] && i === 0 && (
          <GeoJSON
            data={
              i === 0 ? data.filter((ele) => ele.name === "l2018h1") : data[0]
            }
            color="green"
          />
        )}
        {data[1] && i === 1 && (
          <GeoJSON
            data={
              i === 1 ? data.filter((ele) => ele.name === "l2018h2") : data[1]
            }
            color="green"
          />
        )}
        {data[2] && i === 2 && (
          <GeoJSON
            data={
              i === 2 ? data.filter((ele) => ele.name === "l2019h1R") : data[2]
            }
            color="green"
          />
        )}
        {data[3] && i === 3 && (
          <GeoJSON
            data={
              i === 3 ? data.filter((ele) => ele.name === "l2019h2R") : data[3]
            }
            color="green"
          />
        )}
        {data[4] && i === 4 && (
          <GeoJSON
            data={
              i === 3 ? data.filter((ele) => ele.name === "l2020h1") : data[3]
            }
            color="green"
          />
        )}
        {data[5] && i === 5 && (
          <GeoJSON
            data={
              i === 5 ? data.filter((ele) => ele.name === "l2020h2") : data[5]
            }
            color="green"
          />
        )}
        {data[6] && i === 6 && (
          <GeoJSON
            data={
              i === 6 ? data.filter((ele) => ele.name === "l2021h1") : data[6]
            }
            color="green"
          />
        )}
        {data[7] && i === 7 && (
          <GeoJSON
            data={
              i === 7 ? data.filter((ele) => ele.name === "l2021h2") : data[7]
            }
            color="green"
          />
        )}
        {data[8] && i === 8 && (
          <GeoJSON
            data={
              i === 8 ? data.filter((ele) => ele.name === "Q12022") : data[8]
            }
            color="green"
          />
        )}
      </MapContainer>
    </>
  );
};

export default TataSteel;

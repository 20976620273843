import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAtom } from "jotai";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { BsArrowUpRight } from "react-icons/bs";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const block = (loading, navigate, id, output, name) => {
  return (
    <>
      {loading ? (
        <Skeleton style={{ width: "100%", height: "100%" }} />
      ) : (
        <div className="relative">
          <div className="h-full w-full col-span-1 shadow-md shadow-gray-600 rounded-2xl bg-gray-900 flex items-end justify-start p-10">
            <div
              className="flex flex-col gap-2 text-white hover:text-my_purple hover:transition-all cursor-pointer"
              onClick={() => navigate(`/admin/logs?user=${id}`)}
            >
              <div className="text-4xl text-center">{output || 0}</div>
              <div className="text-lg uppercase text-center opacity-70">
                {name}
              </div>
            </div>
          </div>
          <div className="absolute top-5 right-5">
            <BsArrowUpRight className="text-white text-2xl hover:text-my_purple hover:transition-all cursor-pointer" />
          </div>
        </div>
      )}
    </>
  );
};

const User = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      (async function () {
        try {
          const req = await axios.post(
            `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_user_orders`,
            {
              userId: id,
            },
            {
              withCredentials: true,
            }
          );

          console.log(req, "req");
          if (req.status === 200) {
            setUserData(req.data.data);
            setLoading(false);
          }
        } catch (err) {
          console.log(err.response);
          toast.error(err.response.data.message);
          navigate("/sign-in");
        }
      })();
    }
  }, [id, navigate]);

  return (
    <div className="w-full max-h-full overflow-y-auto">
      <SkeletonTheme baseColor="#111726" highlightColor="#808080">
        <div className=" px-32 py-10 flex flex-col gap-10">
          <div
            className="h-10 text-white"
            onClick={() => navigate("/admin/users")}
          >
            Go back
          </div>
          <div className="h-96 shadow-2xl shadow-black rounded-2xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            <div className="grid grid-rows-5 h-full w-full px-10">
              <div className="row-span-1 row-start-4 text-white">
                {loading ? (
                  <Skeleton style={{ width: "90%", height: "30%" }} />
                ) : (
                  <div className="text-3xl tracking-wide">{userData.email}</div>
                )}
              </div>
              <div className="row-span-1 row-start-5 text-white w-full h-full">
                {loading ? (
                  <Skeleton style={{ width: "40%", height: "20%" }} />
                ) : (
                  <div className="flex gap-10">
                    <div>
                      {userData.first_name} {userData.last_name}
                    </div>
                    <div>{userData.job_description}</div>
                    <div>{dayjs(userData.last_login).fromNow()}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="h-64 grid grid-cols-3 gap-10 ">
            {block(loading, navigate, id, userData?.credits, "Credits")}
            {block(loading, navigate, id, userData?.orders?.length, "Orders")}
            {block(loading, navigate, id, userData?.queue?.length, "Queue")}
          </div>
          <div className="h-96 border border-white"></div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default User;

import Lottie from "react-lottie";
import React, { useEffect, useState, Fragment } from "react";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { areaSectedAtom } from "../../atoms/index";
import { searchOrdersAtom } from "../../atoms/index";
import { ordersAtom } from "../../atoms/index";
import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { FaSpinner, FaSearch } from "react-icons/fa";
import loading from "../../lotties/loading/loading.json";
import satelliteLottie from "../../lotties/satellite/satellite.json";
import axios from "axios";

const lottieSatellite = {
  loop: true,
  autoplay: true,
  animationData: satelliteLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

dayjs().format();

const onNextButton = (setPage, page, newPage, setOffset, offset) => {
  setPage(page + 1);
  console.log(offset, "offset next");
  if (page + 1 === newPage[newPage.length - 1]) {
    setOffset(offset + 2);
  }
};

const onPrevButton = (setPage, page, newPage, setOffset, offset) => {
  setPage(page - 1);

  console.log(offset, "offset prev");
  if ((page - 1 === newPage[0] || page === newPage[0]) && page - 1 !== 0) {
    setOffset(offset - 2);
  }
};

const onPageSelected = (
  setPage,
  newPage,
  setOffset,
  offset,
  ele,
  result,
  i
) => {
  console.log("onPageSelected");
  setPage(ele);

  console.log(i, result.length - 1, "on page select");

  if (ele === newPage[newPage.length - 1]) {
    setOffset(offset + 2);
  } else if ((ele === newPage[0] || ele === newPage[0]) && ele !== 0) {
    setOffset(offset - 2);
  }
  console.log(offset, "offset page");
};

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useAtom(searchOrdersAtom);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [orders, setOrders] = useState([]);
  // const orders = user.orders;
  const [filteredData, setFilteredData] = useAtom(ordersAtom);
  // const [result, setResult] = useState([]);
  // const [newPage, setNewPage] = useState([]);
  const navigate = useNavigate();
  console.log("Dashboard");
  // console.log(orders);

  useEffect(() => {
    setIsLoading(false);

    if (orders.length === 0) {
      (async function () {
        try {
          const req = await axios.get(
            `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/fetch_orders`,
            {
              withCredentials: true,
            }
          );
          console.log(req, "reqqqq");
          if (req.data.status === "success") {
            console.log(req.data.data.orders, "ordersssss");
            const orders = req.data.data.orders;
            setFilteredData(orders);
            const sorted = orders.sort((a, b) => {
              const created_at = dayjs(a.created_at);
              const created_at2 = dayjs(b.created_at);
              return created_at2 - created_at;
            });
            console.log(sorted, "result after sorting");
            setOrders(sorted);
          }
        } catch (err) {
          console.log(err.response);
          if (err.response.data.status === "fail") {
            navigate("/sign-in");
          }
        }
      })();

      setIsLoading(true);
    }
    setIsLoading(true);
  }, [navigate, offset, orders.length, setFilteredData]);

  useEffect(() => {
    if (search !== "") {
      let result = [];
      result = orders.filter((data) => {
        return data.pipeline_name.toLowerCase().search(search) !== -1;
      });
      setFilteredData(result);
    }
  }, [orders, search, setFilteredData]);

  const handleSearch = (event, setSearch) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = orders.filter((data) => {
      return data.pipeline_name.toLowerCase().search(value) !== -1;
    });
    setFilteredData(result);
    setSearch(value);
  };

  let n = 4;
  const result = filteredData.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / n);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  console.log(offset, "offsetust before j");

  const newPage = result.map((item, index) => index).slice(offset, offset + 3);

  return (
    <>
      {filteredData && isLoading ? (
        <div className="grid grid-rows-10 h-screen">
          <div className="row-span-1 w-full flex items-center justify-center">
            <FaSearch className="text-black text-2xl relative left-10 z-50" />
            <input
              className="w-1/4 rouned-md px-5 py-4 rounded-3xl opacity-75 text-center"
              type="text"
              placeholder="Search pipeline Name"
              onChange={(event) => handleSearch(event, setSearch)}
              value={search ? search : ""}
            />
          </div>
          <div className="row-span-9">
            {filteredData?.length > 0 ? (
              <div className="pb-6 px-20 grid grid-rows-10 max-h-full w-full items-center justify-center">
                {/* <h1 className="font-pipeline_name text-4xl text-pipeline_name pt-5 row-span-1 uppecase flex justify-self-start">
            Orders
          </h1> */}
                <div className="grid grid-cols-8 justify-evenly items-center text-center row-span-1 px-10 relative top-9">
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    Name
                  </div>
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    Created at
                  </div>
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    Status
                  </div>
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    Credits Charged
                  </div>
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    Area
                  </div>
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    Start Date
                  </div>
                  <div className="text-xl text-pipeline_name font-pipeline_name tracking-wide uppercase">
                    End Date
                  </div>
                </div>
                <div className="my-20 pb-5 row-span-7">
                  {result[page].map((order) => {
                    return (
                      <div
                        key={order.id}
                        className="border-b-2 border-white py-7 px-10 grid grid-cols-8 justify-evenly items-center text-center"
                      >
                        <div className="text-xl text-pipeline_name">
                          {order.pipeline_name}
                        </div>
                        <div className="text-xl text-pipeline_name">
                          {dayjs(order.created_at).format("DD/MM/YYYY")}
                        </div>
                        <div className="text-xl text-pipeline_name">
                          {order.status}
                        </div>
                        <div className="text-xl text-pipeline_name">
                          {order.credits_charged}
                        </div>
                        <div className="text-xl text-pipeline_name">
                          {order.area}
                        </div>
                        <div className="text-xl text-pipeline_name">
                          {dayjs(order.start_date).format("DD/MM/YYYY")}
                        </div>
                        <div className="text-xl text-pipeline_name">
                          {dayjs(order.end_date).format("DD/MM/YYYY")}
                        </div>
                        <button
                          className="text-xl bg-my_purple w-24 py-2 justify-self-end transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                          onClick={() => navigate(`/orders/${order.id}`)}
                        >
                          View
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div className="flex w-full justify-center gap-5 row-span-1 ">
                  <div>
                    <button
                      onClick={() =>
                        onPrevButton(setPage, page, newPage, setOffset, offset)
                      }
                      className={`${
                        page === 0 ? "hidden" : ""
                      } sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border  border-white text-white hover:bg-white hover:text-black shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3`}
                    >
                      Previous
                    </button>
                  </div>
                  <div>
                    {newPage.map((ele, i) => (
                      <Fragment key={ele}>
                        <button
                          key={i}
                          className={`${
                            page === ele
                              ? "text-black bg-white"
                              : "border-blackborder-white text-white hover:bg-white hover:text-black shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                          } sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border mx-3`}
                          onClick={() =>
                            onPageSelected(
                              setPage,
                              newPage,
                              setOffset,
                              offset,
                              ele,
                              result,
                              i
                            )
                          }
                        >
                          {ele + 1}
                        </button>
                      </Fragment>
                    ))}
                    {newPage.length < 3 ||
                    newPage[newPage.length - 1] === result.length - 1 ? null : (
                      <>
                        <button
                          className={`border-blackborder-white text-white shadow-2xl dark:shadow-none sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border mx-3`}
                        >
                          ...
                        </button>
                        <button
                          className={`${
                            page === result.length - 1
                              ? "text-black bg-white"
                              : "border-blackborder-white text-white hover:bg-white hover:text-black shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                          } sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border mx-3`}
                          onClick={() =>
                            page === result.length - 1
                              ? null
                              : setPage(result.length - 1)
                          }
                        >
                          {result.length}
                        </button>
                      </>
                    )}
                  </div>
                  <button
                    onClick={() =>
                      onNextButton(setPage, page, newPage, setOffset, offset)
                    }
                    className={`${
                      page === result.length - 1 ? "hidden" : ""
                    } sm:py-2 sm:px-4 px-3 py-2 rounded-sm order-2 md:order-3 border  border-white text-white hover:bg-white hover:text-black shadow-2xl dark:shadow-none transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3`}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : result.length === 0 && orders.length > 0 ? (
              <div className="w-full h-full flex items-center justify-center text-3xl uppercase font-pipeline_name text-pipeline_name row-start-2">
                No Orders Found!
              </div>
            ) : (
              <div className="w-screen h-screen flex justify-center items-center">
                <Lottie options={lottieSatellite} height={400} width={400} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {result.length === 0 && orders.length > 0 ? (
            <div className="w-full h-full flex items-center justify-center text-3xl uppercase font-pipeline_name text-pipeline_name row-start-2">
              No Orders Found!
            </div>
          ) : (
            <div className="w-screen h-screen flex justify-center items-center">
              <Lottie options={lottieSatellite} height={400} width={400} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(Dashboard);

import { useState, useEffect } from "react";
import { ImBin } from "react-icons/im";
import { FaDrawPolygon } from "react-icons/fa";
import { BiRectangle } from "react-icons/bi";
import { AiFillEdit } from "react-icons/ai";
import { useAtom } from "jotai";
import { clearLayersAtom } from "../../atoms/index";
import { orderDetailsAtom } from "../../atoms/index";
import { showToolBarAtom } from "../../atoms/index";

const mapEvent = (e, showEdit) => {
  var e = document.createEvent("Event");
  e.initEvent("click", true, true);
  var cb = document.getElementsByClassName("leaflet-draw-draw-polygon");
  showEdit(true);
  return !cb[0].dispatchEvent(e);
};
const mapEventRectangle = (e, showEdit) => {
  var e = document.createEvent("Event");
  e.initEvent("click", true, true);
  var cb = document.getElementsByClassName("leaflet-draw-draw-rectangle");
  showEdit(true);
  return !cb[0].dispatchEvent(e);
};
const editMapEvent = (e, showEdit) => {
  var e = document.createEvent("Event");
  e.initEvent("click", true, true);
  var cb = document.getElementsByClassName("leaflet-draw-edit-edit");
  showEdit(true);
  return !cb[0].dispatchEvent(e);
};

// const saveEditEvent = (e) => {
//   var e = document.createEvent("Event");
//   e.initEvent("click", true, true);
//   var cb = document.getElementsByClassName(
//     "leaflet-draw-actions li:first-child a"
//   );
//   return !cb[0].dispatchEvent(e);
// };

const ToolBar = () => {
  const [isTrue, setIsTrue] = useAtom(clearLayersAtom);
  const [details, setDetails] = useAtom(orderDetailsAtom);
  const [show, setShow] = useAtom(showToolBarAtom);
  const [edit, showEdit] = useState(false);

  console.log(details.aoi, "details aoi");

  return (
    <div
      className={`${
        show ? "-translate-x-0" : "translate-x-full"
      } absolute top-4/10 right-0 ease-in-out duration-500 bg-background w-0.9/10 flex flex-col z-20 rounded-tl rounded-bl justify-center items-center`}
    >
      {details.aoi ? null : (
        <div
          onClick={(e) => {
            mapEvent(e, showEdit);
          }}
          className="flex w-full justify-between items-center p-4 hover:bg-my_purple cursor-pointer text-black rounded-tl rounded-bl"
        >
          <FaDrawPolygon className="text-2xl cursor-pointer text-white" />
          <button className="text-white text-sm">Draw Polygon</button>
        </div>
      )}
      {details.aoi ? null : (
        <div
          onClick={(e) => {
            mapEventRectangle(e, showEdit);
          }}
          className="flex w-full justify-between items-center p-4 hover:bg-my_purple cursor-pointer text-black rounded-tl rounded-bl"
        >
          <BiRectangle className="text-2xl text-white" />
          <button className="text-white text-sm">Draw Rectangle</button>
        </div>
      )}
      {/* {details.aoi && details.aoi.length <= 4 ? (
        <div
          onClick={(e) => {
            editMapEvent(e, showEdit);
          }}
          className="flex w-full justify-between items-center p-4 hover:bg-my_purple cursor-pointer text-black rounded-tl rounded-bl"
        >
          <AiFillEdit className="text-2xl text-white" />

          <button className="text-white text-sm">Edit</button>
        </div>
      ) : null} */}
      {/* {details.aoi ? (
        <div
          onClick={(e) => {
            saveEditEvent(e, showEdit);
          }}
          className="flex w-full justify-between items-center p-4 hover:bg-my_purple cursor-pointer text-black rounded-tl rounded-bl"
        >
          <AiFillEdit className="text-2xl text-white" />

          <button className="text-white text-sm">Save</button>
        </div>
      ) : null} */}
      {details.aoi ? (
        <div
          onClick={() => setIsTrue(true)}
          className="flex w-full justify-between items-center p-4 hover:bg-my_purple cursor-pointer text-black rounded-tl rounded-bl"
        >
          <ImBin className="text-2xl text-white" />

          <button className="text-white text-sm">Clear</button>
        </div>
      ) : null}
    </div>
  );
};

export default ToolBar;

import { useEffect, useState } from "react";
import AddUserToOrgIcon from "../../components/addUserToOrgIcon";
import JoinedToOrgIcon from "../../components/addUserToOrgIcon/joinToOrgIcon";
import { useParams, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../atoms/index";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";
import toast from "react-hot-toast";
import { adminDataAtom } from "../../atoms/index";
// const Json = JSON.stringify(currentOrder.data);
// console.log(Json.replaceAll("\\", ""));

const joinOrg = async (id, setIsLoading, setJoined) => {
  setIsLoading(true);

  try {
    const req = await axios.get(
      `${process.env.REACT_APP_GENERAL_SERVER}/api/v1/join-organization/${id}`,
      {
        withCredentials: true,
      }
    );
    console.log(req);
    if (req.data.status === "User added Successfully") {
      setIsLoading(false);
      setJoined(true);
      toast.success("You can close this tab now", {
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
    setIsLoading(false);
  }
};

const UserJoinedOrg = () => {
  const { id, organisationName } = useParams();
  const [isLoading, setIsLoading] = useState(null);
  const [joined, setJoined] = useState(false);

  return (
    <div className="w-full h-full bg-order_confirmed grid grid-cols-2 gap-10  bg-order_background justify-items-center items-center">
      <div>{joined ? <JoinedToOrgIcon /> : <AddUserToOrgIcon />}</div>
      <div className="flex flex-col gap-14 items-center">
        {joined ? (
          <div className="text-8xl lowercase text-black font-pipeline_name flex flex-col items-center text-center">
            <span>you have </span>
            <span> joined</span>
            <span className="text-my_purple capitalize">
              {organisationName}
            </span>
          </div>
        ) : (
          <div className="text-6xl lowercase text-black font-pipeline_name flex flex-col items-center text-center">
            <span>you have been</span>
            <span>intvited to join</span>
            <span className="text-my_purple capitalize">
              {organisationName}
            </span>
          </div>
        )}
        {!joined ? (
          <>
            {isLoading === true ? (
              <button
                className="text-2xl rounded-full bg-black px-2 flex gap-3 justify-items-center justify-around items-center py-4 text-order_background font-pipeline_name w-2/3  transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                onClick={() => joinOrg(id, setIsLoading, setJoined)}
              >
                <AiOutlineLoading3Quarters className="text-xl text-order_background animate-spin" />

                <span>Join</span>
              </button>
            ) : (
              <button
                className="text-2xl rounded-full bg-black px-2 py-4 text-order_background font-pipeline_name w-2/3  transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105 active:translate-y-3"
                onClick={() => joinOrg(id, setIsLoading, setJoined)}
              >
                Join
              </button>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default UserJoinedOrg;
